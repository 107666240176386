import {Modal, NavController, NavParams, Alert, Events, Platform} from 'ionic-angular';
import {Inject, NgZone} from '@angular/core';
import {Component, OnInit} from '@angular/core';
import {ROUTER_DIRECTIVES, Router} from "angular2/router";
import {CORE_DIRECTIVES, FORM_DIRECTIVES, FormBuilder, Validators, AbstractControl, ControlGroup, NgClass} from '@angular/common'
import {SafeResourceUrl, DomSanitizationService, SafeUrl} from '@angular/platform-browser';
import {Keyboard} from 'ionic-native';

import {PAYMENT_URL} from '../../config';
import {FACEBOOK_APP_ID} from '../../config';
import {APP_PLATFORM} from '../../config';

//import {PaymentService} from '../../services/payment-service';
import {UserService} from '../../services/user-service';
import {ValidationService} from '../../services/validation-service';
import {StoreService} from '../../services/store-service';
import {LocalService} from '../../services/local-service';
import {UtilService} from '../../services/util-service';
import {GroupService} from '../../services/group-service';

import {HomePage} from '../home/home';
import {LatestChatPage} from '../latestchat/latestchat';
import {GroupsPage} from '../groups/groups';

declare const facebookConnectPlugin: any;
declare const FB:any;

@Component({
  //selector: 'facebook-login',
  templateUrl: 'build/pages/account/account.html',
  directives: [FORM_DIRECTIVES]
})

export class AccountPage {
  loginForm: ControlGroup;
  accountForm: ControlGroup;
  passwordOldControl: AbstractControl;
  passwordControl: AbstractControl;
  passwordAgainControl: AbstractControl;
  usernameControl: AbstractControl;
  emailControl: AbstractControl;
  nameControl: AbstractControl;
  namePublicControl: AbstractControl;
  idControl: AbstractControl;
  currencyControl: AbstractControl;
  validationService: any;
  storeService: any;
  groupService: any;
  utilService: any;
  localService: any;
  loginError: any;
  platform: any;
  //saveError: any;
  statusMessage: any;
  passwordsMatch: any;
  step: string;
  show: string;
  nav: any;
  userService: any;
  user: any;
  openedChangePassword: boolean;
  openedChangePaymentDetails: boolean;
  changeCardText: string;
  paymentUrl: string;
  safePaymentUrl: SafeResourceUrl;
  pageHeading: string;
  saveButtonText: string;
  isSaving: boolean;
  helpScreen: any;
  events: any;
  builder: any;
  accountStatus: any;
  mode: string;
  groupsJoined: any;
  wowCode: string;
  isAndroid: boolean;
  isDashboard: boolean;
  zone: any;
  savedUser: any;
  connectionError: boolean;
  sanitizer: any;

  
 
 

  constructor(builder: FormBuilder, platform: Platform, zone: NgZone, nav: NavController, userService: UserService, validationService: ValidationService, storeService: StoreService, utilService: UtilService, localService: LocalService,  events: Events, groupService: GroupService, sanitizer: DomSanitizationService) {
    
    this.validationService = validationService;
    this.storeService = storeService;
    this.utilService = utilService;
    this.localService = localService;
    this.groupService = groupService;
    this.builder = builder;
    this.events = events;
    this.nav = nav;
    this.platform = platform;
    this.zone = zone;
    this.isAndroid = platform.is('android');
    this.isDashboard = APP_PLATFORM == 'web';
    this.savedUser = null;
    this.connectionError = false;
    this.sanitizer = sanitizer;

    this.user = this.storeService.getUser();
    this.storeService.getWowCode().then((wowCode) => {
      this.wowCode = wowCode;
    });
    //this.wowCode = this.storeService.getWowCode();
    //if (!this.user) this.user = { id: '', username: '', password: '', passwordAgain: '', passwordOld: '', namePublic: false };

    //this.validationService.passwordMatch = this.validationService.passwordMatch.bind(this); 

    this.passwordsMatch = false;
    this.pageHeading = '';
    this.mode = 'view';
    //this.passwordsMatch = this.passwordsMatch.bind(this);

    
    this.storeService.setPage('account');
    this.resetForm();


    var that = this;

    //validationService.emailValidator('dsa');

    this.step = 'details';
    this.userService = userService;
   
    this.saveButtonText = 'Save';
    this.isSaving = false;
    this.statusMessage = {
      visible: false,
      text: '',
      type: 'err'
    };

    this.show = 'neworold';
    //console.log('subscribing');
    this.events.subscribe('user:got', (user) => {
      //console.log('subs', user);
      //if (user.id)
      //this.showAccount();
    });


    this.accountStatus = 'new';

    if (this.user && this.user.id) {
      this.accountStatus = 'existing';
      this.showAccount();
    }

    

    this.helpScreen = { visible: false, iconName: 'information-circle' };


    this.platform.ready().then(() => {
      //console.log('ready....');
      
      if(this.isAndroid) {
        that.platform.backButton.subscribe(function () {
          if(that.mode == 'edit') {
            //console.log('edit');
            //console.log(that.storeService);
            that.zone.run(() => {  //   needed or doesn't update until next cheange detection
              that.mode = 'view';
              var that = this;
              setTimeout( function() {
                //that.mode = 'view';
              },100 );
              // doesn't work - that.user = that.storeService.user;
              that.storeService.popups = []; 
              if (that.savedUser) {
                that.user = JSON.parse(that.savedUser);
              }
            });
          }
        });
      }

      if(this.isDashboard) {
        FB.init({
            appId      : FACEBOOK_APP_ID,
            cookie     : true,  // enable cookies to allow the server to access
                                // the session
            xfbml      : true,  // parse social plugins on this page
            version    : 'v2.5' // use graph api version 2.5
        });
      }
      




/*
facebookConnectPlugin.api(that.user.facebookUserId +'/?fields=id,name,email,picture,gender', [],
      function (result) {
        console.log('result', result);
        console.log(JSON.stringify(result));
        //alert("Result: " + JSON.stringify(result));
        console.log(result.picture);
        console.log(result.picture.data.url);
        console.log(result.email);
   
    },
    function (error) {
      console.log(error);
        alert("Failed: " + error);
    });
*/

/*
Social.facebookLogin(fbGotMeSuccess);

function fbGotMeSuccess(e) {
  G.info('fbGotMeSuccess');
  G.user.fb = Social.facebook.profile;
  G.user.photo = 'https://graph.facebook.com/' + G.user.fb.id + '/picture?width=300&height=300';
  $.imgProfilePhoto.image = 'https://graph.facebook.com/' + G.user.fb.id + '/picture?width=300&height=300';
  G.info('Social.callbackSuccess');
  DB.saveLocalUser(G.user);
}
*/





    });  //  ready






    function closeIframe(data) {
      //console.log('close);');
      that.openedChangePaymentDetails = false;
      that.user.cardBrand = data.cardBrand;
      that.user.cardType = data.cardType;
      that.user.cardLast4 = data.cardLast4;
    }

    function addAnEventListener(obj, evt, func) {
      if ('addEventListener' in obj) {
        obj.addEventListener(evt, func, false);
      } else if ('attachEvent' in obj) {//IE
        obj.attachEvent('on' + evt, func);
      }
    }

    function iFrameListener(event, a, b) {
      //console.log(event.data);
      that.closeKeyboard();
      if (event.data.message == 'paymentDetailsSaved') {
        var data = event.data;
        //closeIframe(event.data);
        //console.log('close);');
        //console.log('that.user', that.user);
        // this doesn't work on android device??
        // iframelistener works - but panel doesn't close, and details don't work
        that.zone.run(() => {  //   needed or doesn't update untill next change detection
          that.openedChangePaymentDetails = false;
          that.changeCardText = 'Change Card';
          that.user.cardBrand = data.cardBrand;
          that.user.cardType = data.cardType;
          that.user.cardLast4 = data.cardLast4;
          that.storeService.setUser(that.user);
          //console.log('thatuser', that.user);
          //console.log('last 4 iframe listener ', that.user.cardLast4);
          that.utilService.setStatusMessage(that.statusMessage, 'status', "Payment details saved - let's WowGive some money!", 10000);
        });
        setTimeout( function() {
          that.events.publish('reloadGroupPageUser');
        }, 1000);
      } else if (event.data.message == 'paymentDetailsCancelled') {
        //console.log('cancelled from iframe');
        that.zone.run(() => {  //   needed or doesn't update untill next change detection
          that.openedChangePaymentDetails = false;
          that.changeCardText = 'Change Card';
        });
      }
    }

    addAnEventListener(window, 'message', iFrameListener);

    //console.log(this.accountStatus, this.mode);
  }// constructor



  focusInput(input) {
    input.setFocus();
  }

closeKeyboard() {
  Keyboard.close();
  }


 resetForm() {
   this.loginForm = this.builder.group({
     email: ['', Validators.required],
     password: ['', Validators.required]
   });

   this.accountForm = this.builder.group({
     id: [''],
     name: [''],
     namePublic: [''],
     username: ['', Validators.compose([Validators.required, this.validationService.usernameValidator])],
     email: ['', Validators.compose([Validators.required, this.validationService.emailValidator])],
     passwordOld: [''],
     password: ['', Validators.compose([Validators.required, this.validationService.passwordValidator])],
     passwordAgain: ['', Validators.required],
     currency: ['']
     //pin: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
     //pin: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
   }, { validator: this.matchingPasswords('password', 'passwordAgain') });
   this.passwordOldControl = this.accountForm.controls['passwordOld'];
   this.passwordControl = this.accountForm.controls['password'];
   this.passwordAgainControl = this.accountForm.controls['passwordAgain'];
   this.usernameControl = this.accountForm.controls['username'];
   this.emailControl = this.accountForm.controls['email'];
   this.nameControl = this.accountForm.controls['name'];
   this.namePublicControl = this.accountForm.controls['namePublic'];
   this.idControl = this.accountForm.controls['id'];
   this.currencyControl = this.accountForm.controls['currency'];

   this.openedChangePassword = false;
   this.openedChangePaymentDetails = false;
   this.changeCardText = 'Change Card';
 }





 matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  return (group: ControlGroup) => {
    let passwordInput = group.controls['password'];
    let passwordConfirmationInput = group.controls['passwordAgain'];
    ////console.log(passwordInput.value, passwordConfirmationInput.value);
    passwordConfirmationInput.setErrors(null);
    if (passwordInput.value !== passwordConfirmationInput.value) {
      return passwordConfirmationInput.setErrors({isNotEqual: true})
    }
  }
}
/*
  checkPasswordsMatch(control) {
    ////console.log(this.passwordsMatch);
    if(control._parent){
      //console.log(control._parent.controls.passwordAgain.value, control._parent.controls.password.value);
      if(control._parent.controls.passwordAgain.value === control._parent.controls.password.value) {
        return null;
      } else {

        return { 'isNotEqual': true };
      }
    }
  }
*/

 resizeIframe(obj) {
  obj.style.height = 0;
  obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
}

  showLogin() {
    this.show = 'login';
    //console.log(this.user);
    //console.log(this.passwordControl);
  }
  showNewOrOld() {
    this.show = 'neworold';
  }
  showAccount() {
    //console.log(this);
    //console.log(this.show);
    this.show = 'account';
    //console.log('should be showing account', this.user);
    if (this.user.id) {
      this.pageHeading = 'Profile';
    } else {
      this.pageHeading = 'Enter details';
      //console.log(this.accountForm.controls['id']);
      this.groupsJoined = this.storeService.getGroupsJoined();
      //this.accountForm.controls['id'].value = null; // clear ths id from formdata so not processed when save
    }
  }
  showChangePassword() {
    this.openedChangePassword = !this.openedChangePassword;
  }

  showChangePaymentDetails(customerStatus) {
    if (this.user.id) {
      this.openedChangePaymentDetails = !this.openedChangePaymentDetails;
      if (this.openedChangePaymentDetails) {
        var r = Math.random() * 100;
        this.paymentUrl = PAYMENT_URL + '?id=' + this.user.id + '&token=' + this.user.access_token + '&r=' + r;
        //console.log(this.paymentUrl);
        if (this.user.cardLast4) {
          this.paymentUrl = this.paymentUrl + '&stat=update'
        }
        //console.log(this.paymentUrl);
        this.safePaymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.paymentUrl);
        //console.log(this.safePaymentUrl);

        this.changeCardText = 'Close';
      } else {
        this.changeCardText = 'Change Card';
      }
    } else {

    }
  }


  cancelEdit() {
    var that = this;
    this.storeService.popups = []; 
    if (this.savedUser) {
      setTimeout( function() {
        that.mode = 'view';
      },100 );
      this.user = JSON.parse(this.savedUser);
    } else {
      this.createNewAccount();
      this.show = 'neworold';
      this.pageHeading = '';
    }
  }

  editAccount() {
    var that = this;
    this.checkIfNeedToEnterPin(this.okToEditAccount, function() {});
  }

  okToEditAccount(that) {
    that.savedUser = JSON.stringify(that.user);
    that.accountStatus = 'existing';
    that.mode = 'edit';
    that.storeService.popups.push('edit');
    //console.log(that.accountStatus, that.mode);
  }



  checkIfNeedToEnterPin(callbackSuccess, callbackFailure) {
    var that = this;

    if(that.user && that.user.pinLastEntered) {
      //console.log(new Date(that.user.pinLastEntered), that.user.pinLastEntered.valueOf());

      var oldDate = new Date(that.user.pinLastEntered).valueOf();
      if (that.user.pinLastEntered) {
        let timeNow = new Date();
        //console.log(timeNow.valueOf());
        var diff = timeNow.valueOf() - oldDate;
        var diffInHours = diff/1000/60/60;
        var diffInMinutes = diff/1000/60;
        //console.log('diff', diff, diffInHours, diffInMinutes);
        if(diffInHours < 1) {
          callbackSuccess(that);
          return;
        }
      }
    }

    let enterPin = Alert.create({
      title: 'Authentication',
      message: 'Please enter the second and fifth digits of your password',
      inputs: [
        {
          name: 'pin',
          placeholder: '??',
          type: 'password'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
          }
        },
        {
          text: 'Submit',
          handler: data => {
            var params = { pin: data.pin, id: that.user.id };
            //console.log(params);
            that.userService.checkPin(params).subscribe(
              function(data) {
                //console.log(data);
                var body = JSON.parse(data._body);
                if(body.status == 'success') {
                  //console.log('body',body);
                  that.user.pinLastEntered = new Date;
                  that.localService.saveUserLocal(that.user);
                  callbackSuccess(that);
                  return;
                } else {
                   that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.message);
                   that.connectionError = true;
                   callbackFailure(that);
                } //  if
              },
              function(err) {
                 that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
                 that.connectionError = true;
                 callbackFailure(that);
               }, function() {
                ////console.log('complete');
               }
            );  //  service subscribe
         }// handler
       }// button
      ]// buttons
    });  // let
    that.nav.present(enterPin);
  }












// for web

fbWebLoginClick() {
  FB.getLoginStatus(resp => {
    //console.log(resp);
    this.fbGotLoginStatus(resp);
  });
}


fbGotLoginStatus(resp) {
    var that = this;
    if (resp.status === 'connected') {
      // connect here with your server for facebook login by passing access token given by facebook   
      that.user.facebookUserId = resp.authResponse.userID;
      that.user.facebookAccessToken = resp.authResponse.accessToken;
      that.fbGetUserData();
    }else if (resp.status === 'not_authorized') {
        //console.log('not_authorized');
    }else {
        that.fbLogin();
        
    }
};


fbLogin() {
  //console.log('login');
  var that = this;
  FB.login(function(resp){
    //console.log('logged in', resp);
    that.user.facebookUserId = resp.authResponse.userID;
    that.fbGetUserData();
  }, {scope: 'publish_actions'});
}


fbGetUserData() {
  var that = this;
    FB.api(that.user.facebookUserId +'/?fields=id,name,picture,email,gender',
      function (result2) {
        //doesn't get called
        //console.log('result2', result2);
    },
    function (result) {
      if(result.error) {
        console.log('error', result);
    } else {
        //console.log('result', result);
        //console.log(result.picture);
        //console.log(result.picture.data.url);
        //console.log(result.email);
        that.user.email = result.email;
        that.user.avatar = result.picture.data.url;
        that.user.name = result.name;
        that.user.username = result.name.replace(/ /g,'') + result.id.substr(0, 4);
        //console.log(that.user);
        var existingUser = {};
        that.loginFacebook(that.user, existingUser);
      }
    });
}





    /*
    ngOnInit() {
      var that = this;
        FB.getLoginStatus(userData => {
            this.statusChangeCallback(userData);
           FB.getAccessToken(
              function(token) {
                console.log('token', token);
                console.log(userData.authResponse.userID);
                that.user.facebookUserId = userData.authResponse.userID;
                that.user.facebookAccessToken = token;
                that.facebookLoggedInSuccess();
              },
              function (error) {
                console.log(error);
                alert("Failed: " + error);
              })
        });
    }

*/










// for phones

fbLoginSuccess = function (userData) {
  var that = this;
    //console.log('fbLoginSuccess');
    //console.log('userData', userData);
            facebookConnectPlugin.getAccessToken(
              function(token) {
                //console.log('token', token);
                //console.log(userData.authResponse.userID);
                that.user.facebookUserId = userData.authResponse.userID;
                that.user.facebookAccessToken = token;
                that.facebookLoggedInSuccess();
              },
              function (error) {
                //console.log(error);
                alert("Failed: " + error);
              }
            );
  };//var




facebookWebLogin() {
  var that = this;
  facebookConnectPlugin.login(["public_profile", "email"], 
    that.fbLoginSuccess,
    function (error) { 
      alert("" + error);
    }
  );
}

facebookLogout() {
facebookConnectPlugin.logout(function(success) {
  //console.log(success);
}, function(err) {
  console.log(err);
})
}

facebookLoggedInSuccess() {
  //console.log('facebookLoggedInSuccess');
  var existingUser = {};

  this.facebookGetProfile();

}
/*
authenticateViaFacebook - get user based on fbId - if ok then logged in
if not found, then get 


*/


facebookGetProfile() {
  var that = this;
  facebookConnectPlugin.api(that.user.facebookUserId +'/?fields=id,name,picture,email,gender', [],
      function (result) {
        //console.log('result', result);
        //console.log(JSON.stringify(result));
        //console.log(result.picture);
        //console.log(result.picture.data.url);
        //console.log(result.email);
        that.user.email = result.email;
        that.user.avatar = result.picture.data.url;
        that.user.name = result.name;
        that.user.username = result.name.replace(/ /g,'') + result.id.substr(0, 4);
        //console.log(that.user);
        var existingUser = {};
        that.loginFacebook(that.user, existingUser);
        //that.storeService.setUser(that.user);

        /* should now login or save user
          check users for  user with facebookUserId
          if got it, then login
          if not, then save
        */
    },
    function (error) {
      console.log(error);
        alert("Failed: " + error);
    });
}




  createNewAccount() {
    this.accountStatus = 'new';
    this.mode = 'edit';
    this.user = { id: '', username: '', password: '', passwordAgain: '', passwordOld: '', namePublic: false };
    this.showAccount();
  }



  openHelpPublic() {
    let alert = Alert.create({
      title: 'Public Information',
      subTitle: 'This information can be seen by all WowGive users',
      buttons: ['Ok']
    });
    this.nav.present(alert);
  }
  openHelpPrivate() {
    let alert = Alert.create({
      title: 'Private Information',
      subTitle: 'This information is secret.<br>No-one except you can see it.<br>Your email and password allow you to login from another device and keep your details secret.',
      buttons: ['Ok']
    });
    this.nav.present(alert);
  }
  openHelpUsername() {
    let alert = Alert.create({
      title: 'Username',
      subTitle: 'Choose a unique public username.<br>This is how other WowGive users and charities will see you.<br>You could use your real name - or if you wish more privacy - choose a username.',
      buttons: ['Ok']
    });
    this.nav.present(alert);
  }
  openHelpName() {
    let alert = Alert.create({
      title: 'Name',
      subTitle: 'By default charities cannot see your name when you make a donation - they will only see your username and a reference number.<br>If you tick this box, charities will also see your name.',
      buttons: ['Ok']
    });
    this.nav.present(alert);
  }
  openHelpEmail() {
    let alert = Alert.create({
      title: 'Email & Password',
      subTitle: 'Adding your email and a password allows you to secure your account, and also login from another device.',
      buttons: ['Ok']
    });
    this.nav.present(alert);
  }
  openHelpPin() {
    let alert = Alert.create({
      title: '4-digit pin',
      subTitle: 'For added security, any time you make a donation or change your regular donations, you will be prompted for your 4-digit pin.',
      buttons: ['Ok']
    });
    this.nav.present(alert);
  }
  openHelpScreen() {
    this.helpScreen.visible = !this.helpScreen.visible;
    if (this.helpScreen.visible) {
      this.helpScreen.iconName = 'close';
    } else {
      this.helpScreen.iconName = 'information-circle';
    }
  }
  openLogoutScreen() {
    var that = this;
    let confirm = Alert.create({
      title: 'Logout',
      message: '',
      buttons: [
        {
          text: 'No',
          handler: () => {
          }
        },
        {
          text: 'Yes',
          handler: () => {
            that.user = { id: '', username: '', password: '', passwordAgain: '', passwordOld: '', namePublic: false };
            that.resetForm();
            that.userService.logout();
            that.show = 'neworold';
            that.pageHeading = '';
            //that.focusInput(input);
          }
        }
      ]
    });
    this.nav.present(confirm);
  }






  doLogin(formData) {
    ////console.log(formData);
    this.loginError = null;
    //console.log(this.loginForm.valid);

    if(this.loginForm.valid) {
      var existingUser = this.user;
      this.login(formData, existingUser);
    } else {
      //console.log('login form not valid');
      this.loginError = 'Error - please enter a valid email and password';
    }
  }





login(formData, existingUser) {
      var that = this;
      this.userService.authenticate(formData).subscribe(
        function(data) {
          that.connectionError = false;
          var body = JSON.parse(data._body);
          if(body.data && body.data.user) {
            ////console.log(body.data.user);
            var user = body.data.user;
            var groupsJoined = body.data.groupsJoined;
            that.user = user;
            /*
            that.user.username = user.username;
            that.user.name = user.name;
            that.user.namePublic = user.namePublic;
            that.user.email = user.email;
            that.user.id = user.id;
            that.user.cardBrand = user.cardBrand;
            that.user.cardLast4 = user.cardLast4;
            that.user.cardType = user.cardType;
            that.user.cardCountry = user.cardCountry;
            that.user.cardExpires = user.cardExpires;
            that.user.thumbnail
*/
            //delete that.user.access_token;
            delete that.user.token_type;
            delete that.user.expires_in;

            ////console.log(groupsJoined);

            //that.user.pin = user.pin;

            ////console.log( JSON.stringify(that.user), that.user );
            that.storeService.setUser(that.user);
            that.storeService.setHeaders(user.access_token);
            if(groupsJoined && groupsJoined.length>0) that.storeService.setGroupsJoined(groupsJoined);
            that.groupsJoined = that.storeService.getGroupsJoined();  // publishes groupsJoined so permission may change and user menu changes

//            that.paymentUrl = 'http://192.168.1.9:65000/public/enterccdetails.html' + '?id=' + that.user.id + '&token=' + that.user.access_token;
  //          //console.log(that.paymentUrl);

            ////console.log('that.user', that.user, '-b-', JSON.stringify(that.user));
            ////console.log('that.storeService.user', that.storeService.user);
            that.storeService.setPage('groups');
            if (!that.nav || that.nav._views.length == 1) {
              ////console.log('set root');
              if(that.user && groupsJoined && groupsJoined.length > 0) {
                //that.storeService.setPage('groupsJoined');
                that.storeService.setPage('home');
                that.nav.setRoot(HomePage);
              } else {
                that.storeService.setPage('latestChat');
                that.nav.setRoot(LatestChatPage);
              }
            } else {
              ////console.log('-a-', JSON.stringify(that.user));
              setTimeout( function() {
                that.events.publish('reloadGroupPage');
                that.nav.pop();
              }, 1000);

            }
          } else if(body.error) {
            ////console.log(body.error);
            that.loginError = body.error.code;
            ////console.log('error returned');
          } else {
            ////console.log(body);
            that.loginError = 'Sorry, there is a problem logging in at the moment. Please try later.';
          }
        },
        function(err) {
          that.connectionError = true;
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          //console.log('error', err);
          //if (err) //console.log(err._body);
          //var body = JSON.parse(err._body);
          ////console.log(body);
          //that.loginError = 'Error ' + err.status;
        },
        function() {
          //console.log('complete');
        }
      );
}



loginFacebook(formData, existingUser) {
  // Have checked with Facebook, got token, and retreived FB details
  // now see if we have this FB ID already
  // if so, then get access token and log in
  // if not, then add new user for this FB ID
  //console.log('loginFacebook');
      var that = this;
      //console.log(this.userService);
      this.userService.authenticateViaFacebook(formData).subscribe(
        function(data) {
          that.connectionError = false;
          var body = JSON.parse(data._body);
          if(body.data && body.data.user) {
            ////console.log(body.data.user);
            var user = body.data.user;
            var groupsJoined = body.data.groupsJoined;
            that.user = user[0];

            delete that.user.token_type;
            delete that.user.expires_in;

            //that.user.pin = user.pin;

            ////console.log( JSON.stringify(that.user), that.user );
            that.storeService.setUser(that.user);
            that.storeService.setHeaders(user.access_token);
            if(groupsJoined && groupsJoined.length>0) that.storeService.setGroupsJoined(groupsJoined);
            that.groupsJoined = that.storeService.getGroupsJoined();  // publishes groupsJoined so permission may change and user menu changes

            that.storeService.setPage('groups');
            if (!that.nav || that.nav._views.length == 1) {
              ////console.log('set root');
              if(that.user && groupsJoined && groupsJoined.length > 0) {
                //that.storeService.setPage('groupsJoined');
                that.storeService.setPage('home');
                that.nav.setRoot(HomePage);
              } else {
                that.storeService.setPage('latestChat');
                that.nav.setRoot(LatestChatPage);
              }
              
            } else {
              ////console.log('-a-', JSON.stringify(that.user));
              setTimeout( function() {
                that.events.publish('reloadGroupPage');
                that.nav.pop();
              }, 1000);

            }
          } else if(body.error) {
            console.log(body.error);
            // no existing user with this FB ID, so create a new one
            that.loginError = body.error.code;
            //console.log('error returned loginFacebook');
            //that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
            that.accountStatus = 'new';
            that.save( formData, existingUser); //   create new user with facebook credentials


          } else {
            ////console.log(body);
            that.loginError = 'Sorry, there is a problem logging in at the moment. Please try later.';
          }
        },
        function(err) {
          that.connectionError = true;
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          //console.log('error', err);
          //if (err) //console.log(err._body);
          //var body = JSON.parse(err._body);
          ////console.log(body);
          //that.loginError = 'Error ' + err.status;
        },
        function() {
          //console.log('complete');
        }
      );
}





  saveAccount(formData) {
    //console.log(this.groupsJoined);

    if (formData.id == '') {
      delete formData.id;
    }
    //console.log('formData', formData.id, formData);//  same as this.accountForm.value

    var existingUser = this.user;
    //console.log('existing1', existingUser);
    if(this.user.then) {
      existingUser = null;
    }
    ////console.log(this.passwordControl);
    ////console.log(this);
    
    //this.saveError = null;
    if(this.user.id && !(this.user.password && this.user.PasswordAgain)) {
      // there is a user id already (so logged in)
      // and user has left passwords blank
      var notChangingPassword = true;
    }
    //console.log(this.accountForm.valid, this.isSaving, notChangingPassword, this.usernameControl.hasError('invalid'), this.emailControl.hasError('invalid'));
    if((this.accountForm.valid || notChangingPassword && !this.usernameControl.hasError('invalid') && !this.emailControl.hasError('invalidEmailAddress')) && !this.isSaving) {
      
      this.saveButtonText = 'Saving...';
      this.isSaving = true;
      formData.wowCodeEntered = this.wowCode;
      this.save(formData, existingUser);
    } else {
      //this.saveError = 'You must complete the information before you can save it.'
      this.utilService.setStatusMessage(this.statusMessage, 'error', 'You must complete the information before you can save it.');
    }
}//saveAccount



save(formData, existingUser) {
  var that = this;
      this.userService.save(formData).subscribe(
        function(data) {
          that.connectionError = false;
          //console.log('ok response from server', data);

          that.saveButtonText = 'Save';
          that.isSaving = false;
          //console.log(data.status); //  200
          var body = JSON.parse(data._body);
          //console.log(body);
          //console.log(body.data);
          if(body.data && body.data.user) {
            //console.log('body.data.user', body.data.user);
            //console.log('existing', existingUser);  //  this is a promise??
            var newUser;
            that.pageHeading = 'Profile';
            //console.log(that.accountStatus);

            if (that.accountStatus == 'existing') {
              newUser = existingUser;
              //console.log('saved editingAccount');
              that.user = newUser;
              //console.log('saveee');
              that.utilService.setStatusMessage(that.statusMessage, 'status', 'Saved!');
              that.mode = 'view';
            } else {
              // new account
              newUser = formData;
              newUser.id = body.data.user.id;
              that.user = newUser;
              
              //should save the acc-token to header and to user or secure place
              //console.log('fantastic', that.accountStatus, that.mode);
              //console.log(that.user);
             // 
                
                  that.user.passwordOld = '';
                  that.user.password = '';
                  that.user.passwordAgain = '';
                //that.openedChangePaymentDetails = true;
                if(that.user.facebookUserId) {
                   
                   that.utilService.setStatusMessage(that.statusMessage, 'status', 'WowGive account created. Please now choose a new password to secure your account', 6000);
                  that.zone.run(() => {
                    that.showAccount();
                    that.mode = 'edit';
                    that.openedChangePassword  = true;
                  });
                } else {
                  that.utilService.setStatusMessage(that.statusMessage, 'status', 'Fantastic! You are now a member of the WowGive community. Please enter your card details below so you are able to WowGive to the causes you choose.', 6000);
                  that.mode = 'view';
                  that.showChangePaymentDetails('new');
                  that.openedChangePassword = false;
                }
                /*
                setTimeout( function() {
                  //that.showChangePaymentDetails('new');
                }, 500);
                */
             // }); //   zone

              saveJoinedGroup();
              
            } //  if account status existing

            that.storeService.setUser(newUser);
           
            //that.paymentUrl = 'http://192.168.1.9:65000/public/enterccdetails.html' + '?id=' + that.user.id + '&token=' + that.user.access_token;
            ////console.log(that.paymentUrl);

            setTimeout( function() {
                that.events.publish('reloadGroupPage');
                //that.nav.pop();
              }, 500);
            

          } else if(body.error) {
            that.connectionError = true;
            //console.log('if(body.error) {', body.error);
            //that.saveError = body.error.code;
            if (body.error.errno == 100) {
              // no facebookUserId found
              // so try to create new user using facebook email
              // but email already registered, so already has an account
              // could do with a way to associate facebook account with existing account - maybe
              body.error.code = 'Sorry, there is already an account registered with this email address. Please login using your email address.'
            }
            that.zone.run(() => {
              that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code, 6000);
            });
          } else {
            //console.log(body);
          }
        },
        function(err) {
          console.log('error from save)', err);
          that.connectionError = true;
          //that.saveError = err.status;
          //that.utilService.setStatusMessage(that.statusMessage, 'error', err.status)

                that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
              
          that.isSaving = false;
        },
        function() {
          //console.log('complete');
        }
      );



   function saveJoinedGroup() {
     //console.log('saveJoinedGroup', that.groupsJoined);
     //console.log(that.user, that.user.id);
      if (that.user && that.user.id && that.groupsJoined) {
        var group = that.groupsJoined[0];
        if(group) {
          //console.log(group);
          var groupUser = {
            groupId: group.id,
            userId: that.user.id,
            avatar: that.user.avatar,
            permission: 1,
            action: 'join'
          };
          //console.log(group);
          //console.log(groupUser);
         // var that = that;
          // var that = this;

          that.groupService.joinGroup(groupUser).subscribe(
            function(data) {
              //console.log('data', data);
              //console.log(data.status);
              //console.log(data._body);
              var body = JSON.parse(data._body);
              //console.log(body);
              //console.log(body.data);
              var status = body.status;
              if (status == 'success') {
                //that.utilService.setStatusMessage(that.statusMessage, 'status', 'Added to your faves!');
                //that.getTotalUsersForGroup();
                //delete groupUser.action;
                //that.groupsJoined.push(groupUser);
                //that.storeService.setGroupsJoined(that.groupsJoined);
                //that.imInThisGroup = true;
                that.userService.getGroupsJoined({ userId: that.user.id }).subscribe(
                  function(data) {
                    //console.log(data);
                    var body = JSON.parse(data._body);
                    that.storeService.setGroupsJoined(body.data.groups);
                    that.storeService.getGroupsJoined();
                  }, function(err) {
                    console.log(err);
                  });
              } else {
                that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code);//  eg   //  eg ER_NO_SUCH_TABLE
              } // if success
            },
            function(err) {
              //console.log('error)', err);
            },
            function(complete) {
            }
          );  //  this joinGroup
        }  //   if group
      }  //  if user & group
    }  // function

} //  save


    





   requestPasswordReset() {
     var that = this;
     var email = this.loginForm.value.email;
     //console.log(email);
     if (email) {
       if (email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
          this.userService.requestPasswordReset(email).subscribe(
            function(data) {
              that.connectionError = false;
              //console.log('ok response from server', data);
              if(data.status == 200 && data._body) {
                var body = JSON.parse(data._body);
                //console.log(body);
                if (body.status == 'success') {
                  that.utilService.setStatusMessage(that.statusMessage, 'status', body.data.message);
                  return;
                } else {
                  that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.status);
                  return;
                }
              }
              that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem');
            },
            function(err) {
              that.connectionError = true;
              //console.log('error)', err);
              //that.saveError = err.status;
              that.utilService.setStatusMessage(that.statusMessage, 'error', err.status)
              that.saveButtonText = 'Save';
              that.isSaving = false;
            },
            function() {
              //console.log('complete');
            }
          );
        } else {  // not valid email
            that.utilService.setStatusMessage(that.statusMessage, 'error', 'Please enter a valid email address');
        }
     } else {  // no email entered
       that.utilService.setStatusMessage(that.statusMessage, 'error', 'Please enter a valid email address');
     }
     

   }

/*
  constructor(nav, navParams, paymentService, userService, formBuilder, validators) {
    this.nav = nav;
    this.paymentService = paymentService;
    this.userService = userService;
    this.formBuilder = formBuilder;
    this.validators = validators;
    this.customer = {};

    this.accountForm = this.formBuilder.group({
      email: ["", validators.required],
      password: ["", validators.required]
    });

  // This identifies your website in the createToken call below
    //Stripe.setPublishableKey('pk_test_dj6Dmm8BDz7tuPiRMfq89ErX');
  // ...

  }




getToken(e) {
    //console.log(e);

    var stripeResponseHandler = function(status, response) {
      //console.log(stripeResponseHandler);
      var form = $('#payment-form');
      //console.log(response);
      //console.log(Stripe.card.validateCVC('1232dd'));

      if (response.error) {
        // Show the errors on the form
        form.find('.payment-errors').text(response.error.message);
        form.find('button').prop('disabled', false);
      } else {
        // response contains id and card, which contains additional card details
        var token = response.id;

        // Insert the token into the form so it gets submitted to the server
        form.append($('<input type="hidden" name="stripeToken" />').val(token));
        form.append($('<input type="text" name="stripeTokenCoy" />').val(token));
        // and submit
        //form.get(0).submit();
      }
    };

    //console.log(stripeResponseHandler);

    Stripe.card.createToken('', stripeResponseHandler);


}












  createCustomer() {
    //console.log('createCustomer');
    //console.log(this.userService);
    //console.log(this.paymentService);
    var userService = this.userService;
    var token = null;
    //let subscription = x.subscribe(valueFn, errorFn, completeFn);
    let customerService = this.paymentService.createCustomer(token).subscribe(
      function(data) {
        //console.log('data', data);
     
        ////console.log(data.);
        //console.log(data.status);
        ////console.log(JSON.parse(data));
        ////console.log(data.customer);
        //console.log(data.id);
        //console.log(data.response);
        //console.log(data.data);
        //console.log(data._body);
        var body = JSON.parse(data._body);
        //console.log(body);
        //console.log(body.data);
        if(body.data) {
          //console.log(body.data.customer);
          //console.log(body.data.customer.id);
          ////console.log(customer);
          ////console.log(customer.id);
          this.customer = body.data.customer;
          userService.saveUserLocalCustomerId(body.data.customer.id);
        }
        //userService.saveUserLocalCustomerId('324243');

        //console.log(this.test);
        this.test = 'change';
        //console.log(this.test);
        //console.log('--', this.customer);
      },
      function(err) {
        //console.log('error)', err);
        //console.log(this.userService);

      },
      function(complete) {
        //console.log('complete)', complete);
      }
    );







       // this.observer().next({
//       value: todo,
//       created_at: new Date()
//     });
   

  }

  chargeCard() {
   //console.log('chargeCard');
   let user = this.userService.getLocal().then((user) => {
        //console.log('app user', user);
        user = JSON.parse(user);
        //console.log('app user', user);
        if(user.stripeCustomerId) {
          //goto main screen


          //let subscription = x.subscribe(valueFn, errorFn, completeFn);
          let res = this.paymentService.chargeCard(user.stripeCustomerId).subscribe(
            function(data) {
              //console.log('data', data);
              //console.log('data._body', data._body);
              var body = JSON.parse(data._body);
              //console.log(body);
              //console.log('body.status', body.status);
              //console.log('body.data.customer.status', body.data.customer.status);
            },
            function(err) {
              //console.log('error', err);
            },
            function(complete) {
              //console.log('complete', complete);
            }
          );

          //console.log(res);
            } else {
          // try to save user remotely and return remote ID which is saved locally
            //    if can't then flag as problem, and keep trying (suggests no connection)
            // goto welcome screen
        }
      });


  }

*/





















}// export



