import {NavController, NavParams} from 'ionic-angular';
import {Inject} from '@angular/core';
import {Component} from '@angular/core';

import {PaymentService} from '../../services/payment-service';
import {StoreService} from '../../services/store-service';
import {UtilService} from '../../services/util-service';

import {AccountPage} from '../account/account';
import {InvitePage} from '../invite/invite';

@Component({
  templateUrl: 'build/pages/payments/grouppayments.html'
})
export class GroupPaymentsPage {
  utilService: any;
  paymentService: any;
  storeService: any;
  nav: any;
  navParams: any;
  icons: any;
  payments: any;
  pageTitle: string;
  user: any;
  oldDate: any;
  oldPaymentMonth: any;
  different: any;
  group: any;
  groupsJoined: any;
  isGetting: boolean;
  statusMessage: any;

  constructor(nav: NavController, navParams: NavParams, paymentService: PaymentService, storeService: StoreService, utilService: UtilService) {
    this.nav = nav;
    this.navParams = navParams;
    this.paymentService = paymentService;
    this.storeService = storeService;
    this.utilService = utilService;
    //this.pageTitle = 'Donations this month';
    this.pageTitle = 'Donations';
    this.oldDate = null;
    this.oldPaymentMonth = null
    this.different = false;
    this.isGetting = true;
    this.storeService.setPage('paymentsreceived');

    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };

    this.group = navParams.get('group');
    //console.log('nav params----', this.group);

    this.payments = null;
    this.user = this.storeService.getUser();
    this.groupsJoined = this.storeService.getGroupsJoined();
    //console.log('groupsJoined', this.groupsJoined);

    this.displayPaymentsReceived();

    if(this.groupsJoined.then) {
      //console.log('yes this is a promise');
      this.groupsJoined.then((groupsJoined) => {
        this.groupsJoined = groupsJoined;
      });
    } else {

    }
  }





  displayPaymentsReceived() {
    
    //console.log('group', this.group);

    var that = this;
    let query = { groupId: that.group.id, order: 'desc', currentMonth: true };
    that.paymentService.get(query).subscribe(
      function(data) {
        //console.log(data);
        var body = JSON.parse(data._body);
        if (body.data && body.data.payments) {
          console.log(body.data.payments);
          //console.log(body.data.quantity);
          var payments = body.data.payments;
          that.payments = [];
          for (let i = 0; i < body.data.quantity; i++) {
            that.payments.push({
              id: body.data.payments[i].id,
              amount: body.data.payments[i].amount,
              //currencySymbol: that.storeService.getCurrencySymbol(body.data.payments[i].currency),
              currency: body.data.payments[i].currency,
              //username: body.data.payments[i].username,
              name: body.data.payments[i].name,
              avatar: body.data.payments[i].avatar,
              dateTime: new Date(body.data.payments[i].dateTime),
            });
            console.log(that.payments);
            //storeService.setpayments(that.payments);
          }
        } else if (body.error) {
          //console.log(body.error);
          //that.loginError = body.error.code;
          //console.log('error returned');
        } else {
          //console.log(body);
        }
        if(body.data.quantity == 0) {
          that.payments = null;
        }
      },
      function(err) {
        //console.log('error', err);
        //console.log(err._body);
        //var body = JSON.parse(err._body);
        ////console.log(body);
        //that.loginError = 'Error ' + err.status;
        ////console.log(this.userService);
        //that.passwordControl.

      },
      function() {
        //console.log('complete');
        that.isGetting = false;
      }
    );
  }

  inviteFriends() {
    this.nav.setRoot(InvitePage);
  }

  createAccount() {
    this.nav.setRoot(AccountPage);
  }

  monthName(dateTime) {
    return this.utilService.getMonthName(dateTime.getMonth());
  }


  groupTapped(event, group) {
    /*
    this.storeService.setGroup(group);
    this.nav.push(GroupPage, {
      group: group
    });
    */
  }

}
