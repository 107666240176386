import {NavController, NavParams, MenuController, Alert, ActionSheet, Events, Platform, Loading} from 'ionic-angular';
import {CORE_DIRECTIVES, FORM_DIRECTIVES, FormBuilder, Validators, AbstractControl, NgFormModel, ControlGroup, NgClass} from '@angular/common'
import {NgZone} from '@angular/core';
import {Component} from '@angular/core';
import {DatePicker, Contacts, SMS, Keyboard, SocialSharing, Clipboard} from 'ionic-native';

import {APP_PLATFORM} from '../../config';

import {StoreService} from '../../services/store-service';
import {GroupService} from '../../services/group-service';
import {UserService} from '../../services/user-service';
import {UtilService} from '../../services/util-service';
import {LocalService} from '../../services/local-service';
import {ValidationService} from '../../services/validation-service';


import {AccountPage} from '../account/account';
import {GroupsPage} from '../groups/groups';
import {GroupPage} from '../group/group';

declare const facebookConnectPlugin: any;

@Component({
  templateUrl: 'build/pages/group/groupedit.html'
})
export class GroupEditPage {

  builder: any;
  groupForm: ControlGroup;
  idControl: AbstractControl;
  nameControl: AbstractControl;
  descriptionControl: AbstractControl;
  taglineControl: AbstractControl;
  emailControl: AbstractControl;
  targetDateControl: AbstractControl;
  targetDateDisplayControl: AbstractControl;
  targetAmountControl: AbstractControl;
  typeControl: AbstractControl;
  websiteControl: AbstractControl;
  addressControl: AbstractControl;
  postcodeControl: AbstractControl;
  twitterUsername: AbstractControl;
  charityNumberControl: AbstractControl;
  telephoneControl: AbstractControl;
  bankNameControl: AbstractControl;
  bankAccountNumberControl: AbstractControl;
  bankAccountName: AbstractControl;
  bankSortCodeControl: AbstractControl;
  nav: any;
  navParams: any;
  menuController: any;
  group: any;
  groups: any;
  parentGroup: any;
  user: any;
  storeService: any;
  groupService: any;
  userService: any;
  utilService: any;
  validationService: any;
  localService: any;
  events: any;
  statusMessage: any;
  isIOS: boolean;
  isAndroid: boolean;
  isDashboard: boolean;
  isSaving: boolean;
  imInThisGroup: boolean;
  groupsJoined: any;
  loading: any;
  isSent: boolean;
  platform: any;
  zone: any;
  dateNow: any;
  targetDate: any;
  targetDateDisplay: any;
  pageTitle: string;
  minDate: any;
  maxDate: any;
  


  constructor(builder: FormBuilder, platform: Platform, zone: NgZone, nav: NavController, navParams: NavParams, userService: UserService, validationService: ValidationService, storeService: StoreService, utilService: UtilService, localService: LocalService,  events: Events, groupService: GroupService) {
    
    this.validationService = validationService;
    this.builder = builder;
    this.isSaving = false;

    this.storeService = storeService;
    this.utilService = utilService;
    this.localService = localService;
    this.groupService = groupService;

    this.nav = nav;
    this.navParams = navParams;
    this.platform = platform;
    this.zone = zone;

   // this.group = { id: '1', name: 'nam', description: 'desc' , targetAmount: '100', targetDate: '2017 ', logoUrl: '', imageUrl: ''};





/*
add user account

bank details


*/


console.log(this.group);

 




    this.isIOS = this.platform.is('ios');
    this.isAndroid = this.platform.is('android');
    this.isDashboard = APP_PLATFORM == 'web';
    console.log('IOS--------', this.isIOS);

    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };


    this.user = this.storeService.getUser();
    

    var that = this;


    // set date pickers min and max dates

    let d = new Date();
    //d.setMonth(d.getMonth()+3);
    //let dmin = d.setHours(0,0,0,0);
    //console.log(d, dmin);
    let m = ('0' + (d.getMonth() + 1)).slice(-2);
    let w = ('0' + d.getDate() ).slice(-2);
    this.minDate = d.getFullYear() + '-' + m + '-' + w;
    d.setFullYear(d.getFullYear()+5);
    let dmax = d.setHours(0,0,0,0);
    m = ('0' + (d.getMonth() + 1)).slice(-2);
    w = ('0' + d.getDate() ).slice(-2);
    this.maxDate = d.getFullYear() + '-' + m + '-' + w;

    console.log(this.minDate, this.maxDate);


    
    this.parentGroup = navParams.get('parentGroup');
    console.log(this.parentGroup);
    this.user = navParams.get('user');
    if(navParams.get('group')) {
      this.group = navParams.get('group');
      this.pageTitle = 'Edit details';
      if(this.group.parentGroup) {
        this.pageTitle = 'My Fundraiser';
      }
      if(this.group.description) this.group.description = this.group.description.replace(/<br>/g,'\n');
      //console.log(this.group.status, (this.group.status == 2));
      this.group.published = (this.group.status == 2);
      //console.log(this.group);
      
      this.group.targetDateRequired = false;
      if(this.group.targetDate) {
        this.group.targetDateRequired = true;
      }
      this.group.targetAmountRequired = false;
      if(this.group.targetAmount) {
        this.group.targetAmountRequired = true;
      }
      if (this.group.targetDate) {
        //console.log(this.group.targetDate.toISOString());
        let d = new Date(this.group.targetDate);
        let m = ('0' + (d.getMonth() + 1)).slice(-2);
        let w = ('0' + d.getDate() ).slice(-2);
        this.group.targetDateDisplay = d.getFullYear() + '-' + m + '-' + w;
      } else {
        let d = new Date();
        d.setMonth(d.getMonth()+3);
        d.setHours(0,0,0,0);
        let m = ('0' + (d.getMonth() + 1)).slice(-2);
        let w = ('0' + d.getDate() ).slice(-2);
        this.group.targetDateDisplay = d.getFullYear() + '-' + m + '-' + w;
      }
      console.log(this.group.targetDateDisplay);

    } else {

      this.pageTitle = 'Start fundraiser';
      let d = new Date();
      //this.dateNow = d.toISOString().substr(0,10);
      d.setMonth(d.getMonth()+3);
      this.targetDate = d.setHours(0,0,0,0);
     // this.targetDateDisplay = d.toISOString().substr(0,10);
      let m = ('0' + (d.getMonth() + 1)).slice(-2);
      let w = ('0' + d.getDate() ).slice(-2);
      this.targetDateDisplay = d.getFullYear() + '-' + m + '-' + w;
      console.log(this.targetDate, this.targetDateDisplay);
      console.log(this.user, this.user.name);
      let firstname = this.user.name.split(' ')[0];
      this.group = { targetAmount: 150, targetDate: this.targetDate, targetDateDisplay: this.targetDateDisplay, published: true, targetDateRequired: true, targetAmountRequired: true, type: 1,
        totalPaymentThisMonth: 0,
        name: firstname + "'s " + 'fundraiser',
        description: "Welcome to my WowGive fundraiser. I'm raising money for " + this.parentGroup.name + " and I'd love your support. Donating through the WowGive app is easy and secure and they don't charge any commission so more of your money goes directly to the charity. If you'd like to keep track of my progress, please tap the 'support' button.",
        tagline: ''
      };
      console.log(this.group);
    }
    console.log('nav params----', this.group);
    if(this.group) {
      // that.gotGroupSoContinue();
    } else {
      console.log('no group so need to get');
      return;
    }
    /*
    Thanks for taking the time to visit my JustGiving page.

Donating through JustGiving is simple, fast and totally secure. Your details are safe with JustGiving - they'll never sell them on or send unwanted emails. Once you donate, they'll send your money directly to the charity. So it's the most efficient way to donate - saving time and cutting costs for the charity.
*/


  this.resetForm();




    
    console.log('groupeditpage');





  }  // constructor



closeKeyboard() {
  Keyboard.close();
}
stopPropagation($event) {
  $event.stopPropagation();
}

ionViewWillLeave() {
  console.log('ionViewWillLeave2');
  //
  //console.log(this.group.description);
  if(this.group.description) this.group.description = this.group.description.replace(/\n/g,'<br>');
 // this.group.description = 'hullo';
  //console.log(this.group.description);
  if(this.group && this.group.targetDate) {
    let today = new Date();
    this.group.daysLeft =  ((this.group.targetDate.setHours(0,0,0,0) - today.setHours(0,0,0,0)) / 86400000).toString();
  }
  if(this.group && this.group.targetAmount && this.group.totalPaymentThisMonth) {
   this.group.targetPercentage = (this.group.totalPaymentThisMonth / this.group.targetAmount) * 100;
 }

  console.log(this.group);
  
}


ionViewDidLeave() {
   console.log(this.parentGroup);
  //this.groups.push(this.group);
  //console.log(this.groups);
  //console.log(this.parentGroup);
  if(this.parentGroup || (this.group == null)) {
    // if added a new group - or deleted a fundraiser
    // reset home page to include this new group
    console.log('setroot');
    this.storeService.setPage('groupsJoined');
    this.nav.setRoot(GroupsPage, { page: 'groupsJoined' });
    var that = this;
    /*
    setTimeout( function() {
      that.storeService.setGroup(that.group);
      that.storeService.setPage('group');
      console.log(that.group);
      that.nav.push(GroupPage, {
        group: that.group
      });
    }, 1000);
    */
  } else {
    console.log('nope);')
  }
}

cancelEdit() {
   this.nav.pop();
}


setTargetAmount() {

    this.group.targetAmountRequired = ! this.group.targetAmountRequired;
    if (!this.group.targetAmountRequired) {
     //  this.group.targetAmount = null;
    }  else {
      if(this.group.targetAmount == null) {
        this.group.targetAmount = 150;
      }

    }
  console.log('setTargetAmount', this.group.targetAmount);
}
setTargetDate() {
  console.log('setTargetDate');
  this.group.targetDateRequired = !this.group.targetDateRequired;
  if (!this.group.targetDateRequired) {
       //this.group.targetDate = null;
       //this.group.targetDisplay = null;
    }
}


 resetForm() {

  this.groupForm = this.builder.group({
     id: ['', Validators.required],
     name: ['', Validators.required],
     description: [''],
     tagline: [''],
     email: [''],
     targetDate: [''],
    targetDateDisplay: [''],
     targetAmount: [''],
     type: [''],
     website: [''],
     address: [''],
     postcode: [''],
     twitterUsername: [''],
     charityNumber: [''],
     telephone: [''],
     bankName: [''],
     bankAccountNumber: [''],
     bankAccountName: [''],
     bankSortCode: ['']
  });

/*
  this.idControl = this.groupForm.controls['id'];

  this.nameControl = this.groupForm.controls['name'];

  this.descriptionControl = this.groupForm.controls['description'];
  this.taglineControl = this.groupForm.controls['tagline'];

  this.emailControl = this.groupForm.controls['email'];

  this.targetDateControl = this.groupForm.controls['targetDate'];
  this.targetDateDisplayControl = this.groupForm.controls['targetDateDisplay'];
  this.targetAmountControl = this.groupForm.controls['targetAmount'];
  this.typeControl = this.groupForm.controls['type'];
 
  this.websiteControl = this.groupForm.controls['website'];
  this.addressControl = this.groupForm.controls['address'];
  this.postcodeControl = this.groupForm.controls['postcode'];
  this.charityNumber = this.groupForm.controls['charityNumber'];
  this.telephoneControl = this.groupForm.controls['telephone'];
  this.bankNameControl = this.groupForm.controls['bankName'];
  this.bankAccountNumberControl = this.groupForm.controls['bankAccountNumber'];
  this.bankSortCodeControl = this.groupForm.controls['bankSortCode'];

*/

 }


changePublished(published) {
  console.log(published);
}

clickDeleteGroup() {
  console.log(this.group);
  var that = this;
  var deleteAlert = Alert.create({
    title: 'Delete?',
    message: 'Are you sure you want to delete this fundraiser?',
    buttons: [
      {
        text: 'No',
        handler: () => {
        }
      },
      {
        text: 'Yes',
        handler: () => {

          let navTransition = deleteAlert.dismiss();

          navTransition.then(() => {
            that.deleteGroup();
          });
          return false;
          
        }
      }]
  });
  this.nav.present(deleteAlert);
}

saveGroup(group) {
  console.log('save group', group);
  var that = this;
  
  if(this.parentGroup && this.parentGroup.id) {
    let mainImage = this.parentGroup.mainImageUrl.split('/')
    let logo = this.parentGroup.logoUrl.split('/')
    group.entityId = this.parentGroup.entityId;
    group.parentId = this.parentGroup.id;
    group.mainImageUrl = mainImage[mainImage.length-1];
    group.logoUrl = logo[logo.length-1];
    group.ownedByUserId = this.user.id;
    group.new = true;
  }
  //group.type = 1;
  group.code = this.group.code;
  group.status = 1;
  if(this.group.published) {
    group.status = 2;
  }
  this.group.status = group.status;
  console.log('pub', this.group.published, group.status);

  if(this.group.targetAmountRequired) {
    group.targetAmount = parseInt(group.targetAmount);
    console.log('new amoun');
  } else {
    group.targetAmount = null;
  }
  that.group.targetAmount = group.targetAmount; //  needed for currency pipe previous page

  console.log(this.group.targetAmountRequired, this.group.targetDateRequired);
  if(this.group.targetDateRequired) {
    console.log('new date');
    group.targetDate = new Date(group.targetDateDisplay);
  } else {
    group.targetDate = null;
  }
  that.group.targetDate = group.targetDate;
  console.log(group.targetDate, group.targetDateDisplay);

  if(group.description) group.description = group.description.replace(/\n/g,'<br>');
  console.log(group);
  
  //return;

  that.isSaving = true;

   this.groupService.save(group).subscribe(
        function(data) {
          console.log(data);
          that.isSaving = false;
          var body = JSON.parse(data._body);
          console.log(body);
          if(body.data && body.data.group) {
           

            if(!that.group.id) {
               that.utilService.setStatusMessage(that.statusMessage, 'status', "Congratulations - your fundraiser is ready to launch! Now spread the word and start getting donations using the 'share' button on your new fundraiser page", 10000);
               that.promptShare();
             } else {
               that.utilService.setStatusMessage(that.statusMessage, 'status', 'Details saved.', 3000);
             }
            // update local group with reponse from server
            //that.group.description = body.data.group.description;
            that.group.description  = '';
            if(body.data.group.description) that.group.description = body.data.group.description.replace(/<br>/g,'\n');
            if(that.group.targetAmount) {
              that.group.targetAmount = parseInt(that.group.targetAmount);
            }
            
            that.group.id = body.data.group.id;
            that.group.code = body.data.group.code;

            // keep textarea text with line breaks
            //body.data.group.description = body.data.group.description.replace(/<br>/g,'\n');


            //console.log(group.targetAmount, that.group.targetAmount);

           // that.group = body.data.group;
            
            //that.group.name = 'yo';
            //console.log(that.group);





          } else if(body.error) {
           // that.connectionError = true;
            //console.log(body.error);
            //that.saveError = body.error.code;
            that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code)
            //console.log('error returned');
          } else {
            //console.log(body);
          }
        },
        function(err) {
          that.isSaving = false;
          console.log('error)', err);
          //that.connectionError = true;
          //that.saveError = err.status;
          //that.utilService.setStatusMessage(that.statusMessage, 'error', err.status)
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          //that.saveButtonText = 'Save';
          that.isSaving = false;
        },
        function() {
          that.isSaving = false;
          console.log('complete');
        }
      );  //  service

} //   save





promptShare() {
  var that = this;
  var alertShare = Alert.create({
          title: 'Share with friends',
          message: "Tell your friends about your new fundraiser?",
          buttons: [
            {
              text: 'No',
              handler: () => {
                let navTransition = alertShare.dismiss();
                return false;
              }
            },
            {
              text: 'Yes',
              handler: () => {
                let navTransition = alertShare.dismiss();
                navTransition.then(() => {
                  that.clickShare();
                });
                return false;
              }
            }]
        });// alert
        that.nav.present(alertShare);
}






deleteGroup() {
  console.log('delete group', this.group);
  var group = {
    id: this.group.id,
    amount: this.group.amount,
    status: 0
  }
  var that = this;
  that.isSaving = true;
 
   this.groupService.save(group).subscribe(
        function(data) {
          console.log(data);
          that.isSaving = false;
          var body = JSON.parse(data._body);
          console.log(body);
          if(body.data && body.data.group) {
            that.utilService.setStatusMessage(that.statusMessage, 'status', 'Fundraiser deleted.', 3000);
            setTimeout( function() {
              that.group = null;
              that.nav.pop();
            }, 3000);
          } else if(body.error) {
           // that.connectionError = true;
            //console.log(body.error);
            //that.saveError = body.error.code;
            that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code)
            //console.log('error returned');
          } else {
            //console.log(body);
          }
        },
        function(err) {
          that.isSaving = false;
          console.log('error)', err);
          //that.connectionError = true;
          //that.saveError = err.status;
          //that.utilService.setStatusMessage(that.statusMessage, 'error', err.status)
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          //that.saveButtonText = 'Save';
          that.isSaving = false;
        },
        function() {
          that.isSaving = false;
          console.log('complete');
        }
      );  //  service

} //   delete






























clickShare() {
    var that = this;
    //var url = 'http://www.wowgive.com';
    var url = 'http://www.wowgive.com/fundraising/' + slugify(that.group.name)  + '-' + that.group.id + '.html';

    function slugify(name) {
      name = name.replace(/[-\s|\.]+/g, '-');
      return name.trim();
  }
  console.log(url);
    var img = this.group.mainImageUrl;
    //'http://wowgive.com/img/logowithslogan.png'
    /*
    var message = this.group.description;
    message = message.replace(/<p>/g,'');
    message = message.replace(/<\/p>/g,'\n');
    message = message.replace(/<h4>/g,'');
    message = message.replace(/<\/h4>/g,'\n');

    text & email ok on iphone

*/

  if (this.group.type == 1) {
    //  FUNDRAISER OWNER
    if(this.user && this.group.ownedByUserId == this.user.id) {
      var subject = this.group.name + ' - WowCode: ' + this.group.code;
      var message = "I'm raising money for " + this.group.parentName + " (one of my favourite charities), and I'd love your support for my fundraiser - " + this.group.name + ". Please follow me in the WowGive mobile app. \n\nI'll be posting updates and photos in WowGive about my fundraising progress, so I look forward to seeing you there!";
    } else {
      //  FUNDRAISER SUPPORTER
      var subject = this.group.name + ' - WowCode: ' + this.group.code;
      var message = "Please help me in supporting " + this.group.name + ". We're raising money for " + this.group.parentName + " (one of my favourite charities). Please follow me in the WowGive mobile app. \n\nI'll be posting updates and photos in WowGive about my fundraising progress, so I look forward to seeing you there!";
    }
  } else {
    // CHARITY
    if(this.user && this.group.ownedByUserId == this.user.id) {
      //  CHARITY ADMIN
      var subject = "Please support " + this.group.name + '!'; 
      var message = "I'd like to invite you to join us in supporting " + this.group.name + ". Please follow us in the WowGive mobile app. You'll be able to find out more about our work and keep track of our progress within the app. We look forward to seeing you there!";
    } else {
      //  CHARITY SUPPORTER
      var subject = "Please support " + this.group.name + '!'; 
      var message = "I'd like to invite you to join me in supporting " + this.group.name + ". Please follow us in the WowGive mobile app where you can find out more about the great work that " + this.group.name + " are doing. I look forward to seeing you there!";
    }
  }


/*
    var messagel = message.toLowerCase();
            if(messagel.indexOf('wowgive') == -1) {
              message += '\n\nPlease help support  via the WowGive app, available on the app store.';
            }
            if(messagel.indexOf('wowcode') == -1) {
              message += '\n\nEnter WowCode ' + this.group.code + ' to find us';
            }
*/

    console.log(message);
    console.log(subject);
    

    var to = null;
    var cc = null;
    var bcc = null;
    var files = [];
    var number = null;
    var isAndroid = this.isAndroid;

    var buttons = [
        {
          text: 'Send Email',
          handler: () => {
            message = 'Hi,\n\n' + message + '\n\n' + url; //   + '\n\n<img src="' + img + '" />';
            message += '\n\nThanks\n';
            if (this.user.name) message += '\n' + this.user.name;

            message = message.replace(/\n/g, '<br>');
            console.log(message);

            files.push(img); 
            SocialSharing.shareViaEmail(message, subject, to, cc, bcc, files);
          }
        }, {
          text: 'Send Text',
          handler: () => {
            message = 'Hi,\n\n' + message + '\n\n' + url;
            message += '\n\nThanks\n';
            if (this.user.name) message += '\n' + this.user.name;
            //message += '\n' + this.group.name;

            SocialSharing.shareViaSMS(message, number);
          }
        }, {
          text: 'Share via Facebook',
          handler: () => {
            // add url to end of message and copypaste
            message += '\n' + url + '\n';
            Clipboard.copy(message);
            var prompt = "Long press in the 'Say something about this' box to paste in a message";
            that.shareViaFacebook(message, img, null, prompt );
            /*
            this seems to work, but leaves menu on screen
            SocialSharing.canShareVia('facebook',message, img, url, null,
              function(success) {
                //console.log('suc', success);
                that.shareViaFacebook(message, img, url, prompt );
                
              }, function(err) {
                console.log(err, 'err');
                // if no FB app, then share via web!
                that.shareFacebook(subject, message, img, url, prompt );
                //that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need the Facebook app on your phone to do this.');
              }
            );
            */
          }
        }, {
          text: 'Share via Instagram',
          handler: () => {

            Clipboard.copy(message);

            var alertPaste = Alert.create({
                  title: 'Tip',
                  message: "Long press in the 'Write a caption' box to paste in a message",
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => {
                        let navTransition = alertPaste.dismiss();
                        navTransition.then(() => {
                          SocialSharing.shareViaInstagram(message, img);
                        });
                        return false;
                      }
                    }]
                });// alert
                that.nav.present(alertPaste);

                /*
            SocialSharing.canShareVia('instagram',message, img, url, null,
              function(success) {
                console.log('suc', success);
                var alertPaste = Alert.create({
                  title: 'Tip',
                  message: "Press in the 'Write a caption' box to paste in a message",
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => {
                        let navTransition = alertPaste.dismiss();
                        navTransition.then(() => {
                          SocialSharing.shareViaInstagram(message, img);
                        });
                        return false;
                      }
                    }]
                });// alert
                that.nav.present(alertPaste);
              }, function(err) {
                console.log(err, 'err');
                that.zone.run(() => { 
                  that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need the Instagram app on your phone to do this.');
                });
              }
            );  //  socialCan
            */
            
          }
        }, {
          text: 'Share via Twitter',
          handler: () => {
            //message = "I'm raising money for " + this.group.name + " with @WowGive ";
            message = "Please follow " + this.group.name + " in @WowGive ";
            //message = "I'm raising money for " + this.group.name + " with @WowGive and I'd love your support. #WowCode " + this.group.code +'.';
            SocialSharing.shareViaTwitter(message, img, url);
            /*

  // canshare said no twitter app on iphone 6 even when installed
              SocialSharing.canShareVia('twitter',message, img, url, null,
                function(success) {
                  console.log('suc', success);
                  SocialSharing.shareViaTwitter(message, null, url);
                }, function(err) {
                  console.log(err, 'err');
                  that.zone.run(() => { 
                    that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need the Twitter app on your phone to do this.');
                  });
                }
              );
              */
          }
        }, {
          text: 'Share via WhatsApp',
          handler: () => {
            message = 'Hi,\n\n' + message;
            message += '\n\n' + url + '\n\n';
           // if (this.user.name) message += '\n' + this.user.name + '\n\n';

            Clipboard.copy(message);

            var alertPaste = Alert.create({
                  title: 'Tip',
                  message: "Long press in the 'Tap to add a caption' box to paste in a message",
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => {
                        let navTransition = alertPaste.dismiss();
                        navTransition.then(() => {
                          SocialSharing.shareViaWhatsApp(message, img, null);
                        });
                        return false;
                      }
                    }]
                });// alert
                that.nav.present(alertPaste);
              }

            //SocialSharing.shareViaWhatsApp(message, null, url);
            /*
            doing the canShare said that whatsapp wasn't on iphone 6??

             SocialSharing.canShareVia('whatsapp',message, null, url, null,
                function(success) {
                  console.log('suc', success);
                  SocialSharing.shareViaWhatsApp(message, null, url);
                }, function(err) {
                  console.log(err, 'err');
                  that.zone.run(() => { 
                    that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need WhatsApp  on your phone to do this.');
                  });
                }
              );
*/

          
        }, {
          text: "Let's see the options",
          handler: () => {
            that.socialShare(subject, message, url);
          }
        }, {
          text: 'Cancel',
          style: 'cancel',
          handler: () => {
          }
        }
      ];
 
    let actionSheet = ActionSheet.create({
      title: 'SHARE OPTIONS',
      subTitle: 'You will be able to type or paste a message before anything is posted',
      buttons: buttons
    });
    
    this.nav.present(actionSheet);
}


socialShare(subject, message, url) {
  // this is the complete list of currently supported params you can pass to the plugin (all optional)
var options = {
  message: message, // not supported on some apps (Facebook, Instagram)
  subject: subject,  // fi. for email
  files: ['', ''], // an array of filenames either locally or remotely
  url: url
  //chooserTitle: 'Pick an app' // Android only, you can override the default share sheet title
}

var onSuccess = function(result) {
  console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
  console.log("Shared to app: " + result.app); // On Android result.app is currently empty. On iOS it's empty when sharing is cancelled (result.completed=false)
}

var onError = function(msg) {
  console.log("Sharing failed with message: " + msg);
}
console.log(options);
  SocialSharing.share(options.message, options.subject, options.files, options.url);
  //SocialSharing.shareWithOptions(options.message, options.subject, options.files, options.url);

}
/*
shareViaFacebook(message, img, url, prompt ) {
  var that = this;
    if(that.isAndroid) {
      console.log('isAndroid');
      SocialSharing.shareViaFacebookWithPasteMessageHint(message, img, url, prompt );
    } else {
      var alertPaste = Alert.create({
        title: 'Tip',
        message: prompt,
        buttons: [
          {
            text: 'Ok',
            handler: () => {
              let navTransition = alertPaste.dismiss();
              navTransition.then(() => {
                SocialSharing.shareViaFacebook(message, img, url);
              });
              return false;
            } //  handler
          }]  //   buttons
      }); //   alert create
      that.nav.present(alertPaste);
    } //   if android
}
*/
shareViaFacebook(message, img, url, prompt ) {
  var that = this;
    
    var alertPaste = Alert.create({
      title: 'Tip',
      message: prompt,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            let navTransition = alertPaste.dismiss();
            navTransition.then(() => {
              if(that.isAndroid) {
                SocialSharing.shareViaFacebookWithPasteMessageHint(message, img, url, prompt );
              } else {
                SocialSharing.shareViaFacebook(message, img, url);
              }
            });
            return false;
          } //  handler
        }]  //   buttons
    }); //   alert create
    that.nav.present(alertPaste);
}

  shareFacebook(subject, message, img, url, prompt ) {
    // when posting via FB app doesn't work with other plugin
    // then post without the app via web

    var options = {
      method: "share",
      href: url,
      caption: subject,
      description: message,
      picture: img,
    };  

    var alertPaste = Alert.create({
      title: 'Tip',
      message: prompt,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            let navTransition = alertPaste.dismiss();
            navTransition.then(() => {
              facebookConnectPlugin.showDialog(options,
                function (response) {
                  console.log('response', response)
                }, function (err) {
                  console.log('err', err)
                }
              );
            });
            return false;
          }
        }]
    });
    this.nav.present(alertPaste);


/*
    facebookConnectPlugin.showDialog(options,
      function (response) {
        console.log('response', response)
      }, function (err) {
        console.log('err', err)
      }
    );
    */


  }

















} //   export class
