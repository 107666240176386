import {NavController, NavParams, MenuController, App, Alert, ActionSheet} from 'ionic-angular';
import {Component} from '@angular/core';
import {NgClass} from '@angular/common';
import {Contacts, SMS, Keyboard, SocialSharing} from 'ionic-native';


import {StoreService} from '../../services/store-service';
import {GroupService} from '../../services/group-service';
import {UserService} from '../../services/user-service';
import {UtilService} from '../../services/util-service';
import {PaymentService} from '../../services/payment-service';
import {LocalService} from '../../services/local-service';


declare const facebookConnectPlugin: any;


@Component({
  templateUrl: 'build/pages/invite/invite.html'
})
export class InvitePage {
  nav: any;
  app: any;
  menuController: any;
  storeService: any;
  groupService: any;
  userService: any;
  utilService: any;
  paymentService: any;
  localService: any;
  statusMessage: any;
  isGettingContacts: boolean;
  isGettingContactEmail: boolean;
  isGettingContactText: boolean;
  isGettingFacebook: boolean;
  inviteMessage: string;
  inviteSubject: string;
  contact: any;
  mailto: string;
  mailtoMessage: string;
  wowCode: string
  groupsJoined: any;
  alertOptions: any;
  group: any;
  user: any;
  hasFave: boolean;
  groupIndex: number;


  constructor(nav: NavController, navParams: NavParams, menuController: MenuController, app: App, storeService: StoreService, groupService: GroupService, userService: UserService, utilService: UtilService, paymentService: PaymentService, localService: LocalService) {
    this.nav = nav;
    this.storeService = storeService;
    this.groupService = groupService;
    this.userService = userService;
    this.utilService = utilService;
    this.localService = localService;
    this.paymentService = paymentService;
    this.app = app;
    this.menuController = menuController;
    this.isGettingContacts = false;
    this.isGettingContactEmail = false;
    this.isGettingContactText = false;
    this.isGettingFacebook = false;
    this.contact = null;
    this.storeService.setPage('invite');
    this.mailtoMessage = 'mailto:(email)?subject=Invite&body=(message)';
    this.mailto = '';
    this.hasFave = false;
    this.groupIndex = 0;
    this.storeService.getWowCode().then((wowCode) => {
      this.wowCode = wowCode;
      if(wowCode && parseInt(wowCode) != 0) {
        this.hasFave = true;
      }
    });

    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };
    this.group = 0;



    var that = this;
    

    /*

    errors on IOS

    01   462442   error    EXCEPTION: Expression 'wowCode && wowCode != '0' in InvitePage@29:5' has changed after it was checked. Previous value: 'true'. Current value: 'undefined' in [wowCode && wowCode != '0' in InvitePage@29:5]
102   462445   error    ORIGINAL EXCEPTION: Expression 'wowCode && wowCode != '0' in InvitePage@29:5' has changed after it was checked. Previous value: 'true'. Current value: 'undefined'
103   462448   error    ORIGINAL STACKTRACE:
104   462451   error    BaseException@http://192.168.1.11:8100/build/js/app.bundle.js:34202:32


*/


this.alertOptions = {
  title: 'Support?',
  subTitle: 'Invite change to:',
  buttons: [
              { text: 'No',
                handler: () => {
                  
                }
              },
              { text: 'Yes',
                handler: () => {
                  //console.log('asdasd');
                }
              }
            ]
};

  var gj = that.storeService.getGroupsJoined();
        if (gj && !gj.then) {
          console.log(gj);
          //that.displayGroups(gj);
          //that.isGetting = false;
          that.groupsJoined = gj;
          that.createInviteMessage(that.groupsJoined[0]);
        } else {
          gj.then((groupsJoined) => {
            console.log(groupsJoined);
            //that.displayGroups(groupsJoined);
            //that.isGetting = false;
            that.groupsJoined = groupsJoined;
            that.createInviteMessage(that.groupsJoined[0]);

          });
        }  


        //console.log('group', this.group);
        //console.log('joined', this.groupsJoined);

/*
I'm raising money for Cancer Research UK - please support me on JustGiving
Hello
You can help me raise money for this great cause by donating directly to my fundraising page - https://www.justgiving.com/Dave-Smith31.
JustGiving sends your donation straight to Cancer Research UK and automatically reclaims Gift Aid if you are a UK taxpayer, so your donation is worth even more.
Thank you for your support!
Dave

Hello
Please join me in supporting xxxx on WowGive.
If you haven't already got the WowGive app, you can get it at www.wowgive.com and use WowCode G7686 when you start to find me.
WowGive charges no commission and send donations directly to xxx, so more of your money gets to the good cause.
Thank you for your support!
Dave

*/
/*
Hi\n\nPlease join me in supporting " + that.groupsJoined[that.group].name + ", by downloading WowGive from the appstore http://www.wowgive.com/download. \n\nIt's free, and a great new way to support your favourite charity.\n\nUse WowCode '" + that.wowCode + "' when you launch the app!\n\nBest\n";
*/

    var inviteMessage;// = this.storeService.getInviteMessage();
    //console.log('inviteMessage', inviteMessage);
    /*
    inviteMessage.then((message) => {
      //console.log(message);
      ////console.log(this.groupsJoined);
      if(message) {
        that.inviteMessage = message;
      } else {
        if(that.group) {
          that.inviteMessage = "\
          Hello\
          Please join me in supporting xxxx on WowGive.\
          If you haven't already got the WowGive app, you can get it at www.wowgive.com and use WowCode G7686 when you start to find me.\
          WowGive charges no commission and send donations directly to xxx, so more of your money gets to the good cause.\
          Thank you for your support!\
          Dave";
        }
      }
      */

      if(that.user) {
        //console.log('uuu', that.user);
        that.inviteMessage = that.inviteMessage + that.user.name;
      }
      //console.log(that.inviteMessage);
    //});
    ////console.log(this.groupsJoined);
    
/*
    //var amount = 100;
    var p1 = 0.05;
    var p2 = 0.014;
    var c1 = 5;
    var c2 = 20;

    for (var amount=415; amount<420; amount=amount+1) {
      var r1 = (amount * p1 + c1) / 100;
      var r2 = (amount * p2 + c2) / 100;
      //console.log(amount/100, r1, r2);
    }
*/
  var service = this.storeService.getUser();

  if(service.then) {
    service.then((user) => {
      that.user = user;
      if(user) {
        that.inviteMessage = that.inviteMessage + user.name;
      }
    });
  } else {
    that.user = service;
  }

}




/*
[Warning] Instead, use input.focus(), and ensure that you have the following setting in your config.xml:  (keyboard.js, line 21)
 <preference name="KeyboardDisplayRequiresUserAction" value="false"/>
*/

//window.addEventListener('native.keyboardshow', keyboardShowHandler);

//function keyboardShowHandler(e){
//    alert('Keyboard height is: ' + e.keyboardHeight);
//}



  



  closeKeyboard() {
    Keyboard.close();
  };





createInviteMessage(group) {
  //if(this.group) {
          var inviteMessage = "\
Hello\n\
Please join me in supporting {charityName} on WowGive.\n\
If you haven't already got the WowGive app, you can get it at www.wowgive.com and use WowCode {wowCode} to find me.\n\
WowGive charges no commission and send donations directly to {charityName}, so more of your money gets to the good cause.\n\
Thank you for your support!\n\
";
var inviteSubject = "I'm raising money for {charityName} - please support me on WowGive";
       // }
       if(this.user && this.user.username) {
        inviteMessage += this.user.name;
       }
       this.inviteMessage = inviteMessage.replace(/{charityName}/g, group.name).replace('{wowCode}',group.code);
       this.inviteSubject = inviteSubject.replace(/{charityName}/g, group.name);
        //console.log(group);
        //console.log(this.inviteMessage);
}

  changeGroup(groupIndex) {
    console.log('selected #', groupIndex);
   // console.log(this.groupsJoined);
    console.log(this.groupsJoined[groupIndex]);
    var group = this.groupsJoined[groupIndex];
    //var group = this.groupsJoined[group]
    var that = this;
    //this.wowCode = group.code;
    //console.log(this.wowCode);

    this.createInviteMessage(group);
    /*
    var msg = "Hi\n\nPlease join me in supporting " + group.name
    msg = msg + ", by downloading WowGive from the appstore http://www.wowgive.com/download. \n\nIt's free, and a great new way to support your favourite charity.\n\nUse WowCode '"
    msg = msg + '' + this.wowCode + "' when you launch the app!\n\nBest\n";
    if(this.user) {
        msg = msg + this.user.name;
      }
    setTimeout( function() {
      console.log('settimeout', msg);
      that.inviteMessage = msg;
    },1000);
    */
  }


  saveMessage() {
    ////console.log(this.inviteMessage);
    this.storeService.setInviteMessage(this.inviteMessage);
  }




socialShare() {
  // this is the complete list of currently supported params you can pass to the plugin (all optional)
var options = {
  message: this.inviteMessage, // not supported on some apps (Facebook, Instagram)
  subject: this.inviteSubject, // fi. for email
  files: ['', ''], // an array of filenames either locally or remotely
  url: 'www.wowgive.com',
  //chooserTitle: 'Pick an app' // Android only, you can override the default share sheet title
}

var onSuccess = function(result) {
  console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
  console.log("Shared to app: " + result.app); // On Android result.app is currently empty. On iOS it's empty when sharing is cancelled (result.completed=false)
}

var onError = function(msg) {
  console.log("Sharing failed with message: " + msg);
}
console.log(options);
  SocialSharing.share(options.message, options.subject, options.files, options.url);

}




  inviteViaFacebook() {
    //  var url = 'https://play.google.com/store/apps/details?id=com.wowgive.wowgive'
    var url = 'http://www.wowgive.com';  // app id check https://developers.facebook.com/docs/applinks/add-to-content
    var options = { url: url, picture: 'http://wowgive.com/img/logowithslogan.png' };
    this.isGettingFacebook = true;
    //console.log(this.inviteMessage);
    var that = this;
    
    facebookConnectPlugin.appInvite(options, 
      function(response) {
        that.isGettingFacebook = false;
        //console.log(response);
        if (response) {
          if (response.completionGesture == "cancel") {
            // user canceled, bad guy
            //console.log('cancelled');
          } else {
            // user really invited someone :)
            //console.log('invited');
          }
        } else {
          // user just pressed done, bad guy
          //console.log('pressed done');
        }
      }, function(err) {
        //console.log(err);
        that.isGettingFacebook = false;
      }
    )
    
  }



  shareOnFacebook(group) {
    var options = {
      method: "share",
      href: 'http://www.wowgive.com',
      caption: 'WowGive Donate on your Mobile',
      description: "Download WowGive - the new mobile app for supporting any UK charity!",
      picture: 'http://wowgive.com/img/logowithslogan.png'
    };  
    facebookConnectPlugin.showDialog(options,
      function (response) {
        //console.log(response)
      }, function (response) {
        //console.log(response)
      }
    );
  }


  inviteContacts() {
    //console.log('all)');
    this.isGettingContacts = true;
    var filter = ["displayName", "contactName"];
    Contacts.find(filter).then(function(result) {
      this.isGettingContacts = false;
      var contacts = result;
      //console.log(result);
      var l = contacts.length;
      //console.log(l);
      if(l>100) {
        l = 100;
      }
      for (var i = 0; i < contacts.length; i++) {
        var s = '';
        if (contacts[i].displayName) {
          s = s + contacts[i].displayName + ': ';
        } else {
          s = s + '-: ';
        }
        if (contacts[i].emails) {
          for (var j = 0; j < contacts[i].emails.length; j++) {
            s = s + contacts[i].emails[j].value + ',';
          }
        }
        if (contacts[i].phoneNumbers) {
          for (var j = 0; j < contacts[i].phoneNumbers.length; j++) {
            s = s + contacts[i].phoneNumbers[j].value + ',';
          }
        }
        //console.log(s);
      }
    }, function(error) {
      this.isGettingContacts = false;
        //console.log("ERROR: " + error);
    });
  }//  inviteContacts





/*

  inviteContact() {
    //console.log('single');
    this.isGettingContact = true;
    var filter = ["displayName: d*"];
    Contacts.find(filter).then(function(result) {
      this.isGettingContact = false;
      var contacts = result;
      //console.log(result);
    }, function(error) {
      this.isGettingContact = false;
        //console.log("ERROR: " + error);
    });
  }//  inviteContact
*/

  pickContactAndEmail() {
    var that = this;
    //console.log('pick');
    this.isGettingContactEmail = true;
    Contacts.pickContact().then(function(result) {
      this.isGettingContact = false;
      that.contact = result;
      //that.contact.email = that.contact.emails[0].value
      //that.contact.phone = that.contact.phoneNumbers[0].value
      //console.log(result);
      //that.message.visible = true;
      that.sendEmail();
    }, function(error) {
      this.isGettingContact = false;
        //console.log("ERROR: " + error);
        this.isGettingContactEmail = false;
    });
  }//  pickContactAndEmail

  sendEmail() {
    if (this.contact.emails && this.contact.emails[0]) {
      //document.getElementById('clickEmail')href="mailto:idealconceptz@gmail.com?subject=Invite&body={{message.text}}
      //console.log('email', this.contact.emails[0].value, this.inviteMessage);
      this.mailto = (this.mailtoMessage.replace('(email)', this.contact.emails[0].value)).replace('(message)', this.inviteMessage);
      //console.log(this.mailto);
      setTimeout(() => {
        //console.log(document.getElementById('clickEmail'));
        document.getElementById('clickEmail').click();
      }, 100);
    } else {
      this.utilService.setStatusMessage(this.statusMessage, 'error', "Sorry, this contact doesn't appear to have an email address");
    }
    this.isGettingContactEmail = false;
  }

  pickContactAndText() {
    var that = this;
    //console.log('pick');
    this.isGettingContactText = true;
    Contacts.pickContact().then(function(result) {
      this.isGettingContact = false;
      that.contact = result;
      //that.contact.email = that.contact.emails[0].value
      //that.contact.phone = that.contact.phoneNumbers[0].value
      //console.log(result);
      //that.message.visible = true;
      that.sendSMS(that.contact);
    }, function(error) {
      this.isGettingContact = false;
        //console.log("ERROR: " + error);
        this.isGettingContactText = false;
    });
  }//  pickContactAndText

  sendSMS(contact) {
    if (contact.phoneNumbers && contact.phoneNumbers[0]) {
      //console.log('sms', contact.phoneNumbers[0].value);
      SMS.send(contact.phoneNumbers[0].value, this.inviteMessage);
      this.utilService.setStatusMessage(this.statusMessage, 'status', "Text sent to " + contact.phoneNumbers[0].value);
    } else {
      this.utilService.setStatusMessage(this.statusMessage, 'error', "Sorry, this contact doesn't appear to have a telephone number");
    }
    this.isGettingContactText = false;
  }






}



