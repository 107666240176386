import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {SERVER_URL} from '../config';
import {Http, Headers, RequestOptions} from '@angular/http';

import {StoreService} from './store-service';
import {FileService}  from './file-service';

import {APP_ID} from '../config';
import {APP_PLATFORM} from '../config';


@Injectable()
export class GroupService {

	groups: any;
	http: any;
	storeService: any;
    fileService: any;
	access_token: string;
    isDashboard: boolean;

	constructor(http: Http, storeService: StoreService, fileService: FileService) {
		this.http = http;
		this.groups = {};
		this.storeService = storeService;
                        this.fileService = fileService;
                        this.isDashboard = APP_PLATFORM == 'web';


	}


	get() {
		let url = SERVER_URL + 'groups';
		let body = JSON.stringify({ });
		return this.http.get(url, { headers: this.storeService.getHeaders() });
	}
    getById(id) {
        let url = SERVER_URL + 'groups/' + id;
        let body = JSON.stringify({ });
        //console.log(url);
        //console.log('getById');
        return this.http.get(url, { headers: this.storeService.getHeaders() });
    }
    getByCode(code) {
        let url = SERVER_URL + 'groups/?code=' + code;
        let body = JSON.stringify({ });
        //console.log(url);
        return this.http.get(url, { headers: this.storeService.getHeaders() });
    }
    getFeatured() {
        let url = SERVER_URL + 'groups?query=featured';
        //console.log(url);
        let body = JSON.stringify({ });
        return this.http.get(url, { headers: this.storeService.getHeaders() });
    }
    getTrending() {
        let url = SERVER_URL + 'groups?query=trending';
        let body = JSON.stringify({ });
        return this.http.get(url, { headers: this.storeService.getHeaders() });
    }
    getFundraisers() {
        let url = SERVER_URL + 'groups?query=fundraisers';
        let body = JSON.stringify({ });
        return this.http.get(url, { headers: this.storeService.getHeaders() });
    }
    search(group) {
        let url = SERVER_URL + 'groups?query=search';//    ?name=' + 'sens';
        if(group.code) {
            url += '&code=' + group.code;
        } else {
            if(group.name) {
                url += '&name=' + group.name;
            }
            if (group.areaCode) {
                url += '&areacode=' + group.areaCode;
            }
            if (group.postCode) {
                url += '&postcode=' + group.postCode;
            }
            if (group.address) {
                url += '&address=' + group.address;
            }
        }
        let body = JSON.stringify({ group: group });
        //console.log(url);
        return this.http.get(url, { headers: this.storeService.getHeaders() });
    }
    getUsersTotal(data) {
        let url = SERVER_URL + 'groups/' + data.groupId + '/users?action=total';
        //console.log(url);
        //let body = JSON.stringify({});
        return this.http.get(url, { headers: this.storeService.getHeaders() });
    }
    getUsers(data) {
        let url = SERVER_URL + 'groups/' + data.groupId + '/users';
        if(data.order) url += '?order=' + data.order;
        //console.log(url);
        //let body = JSON.stringify({});
        return this.http.get(url, { headers: this.storeService.getHeaders() });
    }
    getUser(data) {
        let url = SERVER_URL + 'groups/' + data.groupId + '/users/' + data.userId;
        //console.log(url);
        let body = JSON.stringify({});
        return this.http.get(url, { headers: this.storeService.getHeaders() });
    }

    getSocialTwitter(data) {
        let url = SERVER_URL + 'groups/' + data.groupId + '?query=socialtwitter';
        let body = JSON.stringify({ });
        return this.http.get(url, { headers: this.storeService.getHeaders() });
    }


    joinGroup(groupUser) {
        let url = SERVER_URL + 'groupsusers';
        let body = JSON.stringify({ groupUser: groupUser });
        //let headers = new Headers({ 'Content-Type': 'application/json' });
        //let options = new RequestOptions({ headers: headers });
        let options = new RequestOptions({ headers: this.storeService.getHeaders() });
        return this.http.post(url, body, options);
    }
    leaveGroup(groupUser) {
        let url = SERVER_URL + 'groupsusers';
        let body = JSON.stringify({ groupUser: groupUser });
        let options = new RequestOptions({ headers: this.storeService.getHeaders() });
        return this.http.post(url, body, options);
    }
    claimGroup(groupUser) {
        let url = SERVER_URL + 'groupsusers';
        let body = JSON.stringify({ groupUser: groupUser });
        let options = new RequestOptions({ headers: this.storeService.getHeaders() });
        return this.http.post(url, body, options);
    }

    setNotifications(groupUser) {
        let url = SERVER_URL + 'groupsusers';
        let body = JSON.stringify({ groupUser: groupUser });
        let options = new RequestOptions({ headers: this.storeService.getHeaders() });
        return this.http.post(url, body, options);
    }

   save(group) {
        let url = SERVER_URL + 'groups';
        if(group.id) url += '/' + group.id;
        let body = JSON.stringify({ group: group });
        let options = new RequestOptions({ headers: this.storeService.getHeaders() });
        return this.http.post(url, body, options);
    }
    sendTweet(data) {
        let url = SERVER_URL + 'twitter/';
        let body = JSON.stringify(data);
        let options = new RequestOptions({ headers: this.storeService.getHeaders() });
        return this.http.post(url, body, options);
    }
 sendTweetWithImage(data: any, callbackSuccess: any, callbackFailure: any, progressHandler: any, that: any) {
      //console.log(progressHandler);
      //let url = SERVER_URL + 'chat';

      let options = new RequestOptions({ headers: this.storeService.getHeaders() });
      let body = JSON.stringify(data);
     // return this.http.post(url, body, options);

    if(this.isDashboard) {
     // console.log('upload it');
      //this.uploader.uploadAll();
      let url = SERVER_URL + 'postfile';

    var formData = new FormData();
    formData.append("message", data.message);
    formData.append("file",  data.file, data.imageUrl);
    formData.append("groupId",  data.groupId);
    formData.append("userId",  data.userId);
    formData.append("toGroupName",  data.toGroupName);
    formData.append("action",  'sendtweetfgs');
    //formData.append("imageUrl",  data.imageUrl);

//console.log(formData, url);
//console.log(data.file);

  var promise = new Promise((resolve, reject) => {
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.response))
                } else {
                    reject(xhr.response)
                }
            }
        }
    xhr.open('POST', url, true);
    xhr.setRequestHeader('x-access-token', this.storeService.accessToken);
    xhr.setRequestHeader('appid', APP_ID.toString());
    xhr.send(formData);
    });
return promise; //  PromiseObservable.create(promise); // Observable.fromPromise(promise)
//return this.http.post(url, formData, options);
    } else {
      this.fileService.uploadImage(data, callbackSuccess, callbackFailure, progressHandler, that);
    }
  }

    toggleFeatured(group) {
        let url = SERVER_URL + 'groups/' + group.id;
        let body = JSON.stringify({ group: group, action: 'toggleFeatured' });
        let options = new RequestOptions({ headers: this.storeService.getHeaders() });
        return this.http.post(url, body, options);
    }


  uploadMainImage(data: any, callbackSuccess: any, callbackFailure: any, progressHandler: any, that: any) {
      //console.log(progressHandler);
      //let url = SERVER_URL + 'group';

      let options = new RequestOptions({ headers: this.storeService.getHeaders() });
      let body = JSON.stringify(data);
     // return this.http.post(url, body, options);

      //console.log('upload it');
      let url = SERVER_URL + 'postfile';

        var formData = new FormData();
        formData.append("file",  data.file, data.imageUrl);
        formData.append("groupId",  data.groupId);
        formData.append("action",  'groupImage');

        //console.log(formData, url);
        //console.log(data.file);

        var promise = new Promise((resolve, reject) => {
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            resolve(JSON.parse(xhr.response))
                        } else {
                            reject(xhr.response)
                        }
                    }
            }
            xhr.open('POST', url, true);
            xhr.setRequestHeader('x-access-token', this.storeService.accessToken);
            xhr.setRequestHeader('appid', APP_ID.toString());
            xhr.send(formData);
        });
        return promise;
    }





  uploadLogo(data: any, callbackSuccess: any, callbackFailure: any, progressHandler: any, that: any) {
      //console.log(progressHandler);
      //let url = SERVER_URL + 'group';

      let options = new RequestOptions({ headers: this.storeService.getHeaders() });
      let body = JSON.stringify(data);
     // return this.http.post(url, body, options);

      //console.log('upload it');
      let url = SERVER_URL + 'postfile';

        var formData = new FormData();
        formData.append("file",  data.file, data.imageUrl);
        formData.append("groupId",  data.groupId);
        formData.append("action",  'groupLogo');

        //console.log(formData, url);
        //console.log(data.file);

        var promise = new Promise((resolve, reject) => {
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            resolve(JSON.parse(xhr.response))
                        } else {
                            reject(xhr.response)
                        }
                    }
            }
            xhr.open('POST', url, true);
            xhr.setRequestHeader('x-access-token', this.storeService.accessToken);
            xhr.setRequestHeader('appid', APP_ID.toString());
            xhr.send(formData);
        });
        return promise;
    }





}// export