import {Storage, SqlStorage, LocalStorage, Events} from 'ionic-angular';
import {Injectable} from '@angular/core';
import {APP_PLATFORM} from '../config';


@Injectable()
export class LocalService {
  storage: any;
  local: any;
  data: any;
  events: any;

  constructor(events: Events) {
    if(APP_PLATFORM == 'web') {
      this.storage = new Storage(LocalStorage);
    } else {
      this.storage = new Storage(SqlStorage, {name:'notifybot'});
    }
    this.local = new Storage(LocalStorage);
    

    this.data = null;
    this.events = events;


    //this.storage.remove('user');
    //this.storage.remove('user');
  /*
  all SqlStorage functions are async
  set and get mimic localstorage but  keep updating the record num.
  */
  /*
    this.storage.get('user').then((user) => {
      //console.log('got local user');
      ////console.log(user);
      ////console.log(JSON.parse(user));
     }, (error) => {
      //console.log("ERROR -> " + JSON.stringify(error.err));
    });
    */
  }

/*
          //if(!user) {
            /*
            this.storage.set('user', JSON.stringify({ name: 'Mickeymoo'})).then((user) => {
              this.storage.get('user').then((user) => {
                //console.log(user);
              });
            });

          //}

        this.storage.query('CREATE TABLE IF NOT EXISTS user (id INTEGER PRIMARY KEY AUTOINCREMENT, name TEXT)').then((data) => {
          //console.log("TABLE CREATED -> " + JSON.stringify(data));
          //console.log(data);
          //this.storage.query('insert into user(name) values(\'howdy do\')').then((data) => {
          this.storage.set('name', 'Chum').then((data) => {
            //console.log(data);
            this.storage.get('name').then((data) => {
              //console.log(data);
            });
          });
      }, (error) => {
          //console.log("ERROR -> " + JSON.stringify(error.err));
      });
      */


    setPage(page) {
      this.local.set('page', page);
    }
    getPage(page) {
      return this.local.get('page');
    }

    setInviteMessage(message) {
      this.local.set('inviteMessage', message);
    }
    getInviteMessage() {
      return this.local.get('inviteMessage');
    }

    setWowCode(wowcode) {
      this.local.set('wowCode', wowcode);
    }
    getWowCode() {
      return this.local.get('wowCode');
    }

    getUser() {
      return this.storage.get('user').then((user) => {
        //console.log('getUser from localService', user);
        if (user) {
          //console.log('think have user', JSON.parse(user));
          return JSON.parse(user);
        } else {
          return null;
        }
      });
    }
      ////console.log(user);
      //var user2 = JSON.parse(user);
      ////console.log(user2);
      //return user2;
        //return JSON.parse(this.storage.get('user'));
        /*
      this.storage.get('user').then((user) => {
          this.user = JSON.parse(user);
          //console.log('user',this.user);
      }, (error) => {
          //console.log("ERROR -> " + JSON.stringify(error.err));
      });
      */
    

    saveUserLocal(user) {
      if (user) {
        var u = user;
        this.storage.set('user', '' + JSON.stringify(user) + '').then(() => {
          //this.storage.get('user').then((user) => {
          //console.log('saveUserLocal-', JSON.stringify(u), u);
          //return u;
          //});
        });
      } else {
        this.storage.remove('user').then(() => {
          //console.log('removed user');
          return null;
         });
      }
    }

    saveUserLocalCustomerId(stripeCustomerId) {
      this.storage.set('user', '' + JSON.stringify({ stripeCustomerId: stripeCustomerId}) + '').then((user) => {
        this.storage.get('user').then((user) => {
          //console.log('saveUserLocalCustomerId', user);
          return user;
        });
      });
    }






    saveGroup(group) {
      this.storage.set('group', '' + JSON.stringify(group) + '').then((group) => {
        //this.storage.get('group').then((group) => {
          ////console.log(group);
          //return user;
        //});
      });
    }




    getGroup() {
      return this.storage.get('group').then((group) => {
        //console.log('getGroup from localService', group);
        if (group) {
          //console.log('think have group');
          //console.log(JSON.parse(group));
          return JSON.parse(group);
        } else {
          return null;
        }
      });
    }

    getGroupsJoined() {
      return this.storage.get('groupsJoined').then((groupsJoined) => {
        //console.log('getGroupsJoined from localService', groupsJoined);
        if (groupsJoined) {
          //console.log('think have groupsJoined');
          //console.log(JSON.parse(groupsJoined));
          return JSON.parse(groupsJoined);
        } else {
          return null;
        }
      });
    }

    saveGroupsJoined(groupsJoined) {
      //console.log('saving', 'saveGroupsJoined', groupsJoined);
      var groupsJoinedScope = groupsJoined;
      if (groupsJoined) {
        this.storage.set('groupsJoined', '' + JSON.stringify(groupsJoined) + '').then(() => {
          //console.log('saved');
          //this.events.publish('groupsJoined:got', groupsJoinedScope);
          //this.storage.get('group').then((group) => {
          ////console.log(group);
          //return user;
          //});
        });
      } else {
        this.storage.remove('groupsJoined').then(() => {
          //console.log('removed');
          //this.events.publish('groupsJoined:got', groupsJoinedScope);
        });
      }
    }




      //return this.storage.get('user') || { name: 'who knows' };


    getData() {
       // return this.storage.get('user') || { name: 'who knows' };
        //JSON.parse(user) || { name: 'who knows' };
    }

    save(item) {

        if(!this.data){
            this.data = [item];
            let newData = JSON.stringify(item);
            this.storage.set('todos', newData);
        } else {
            this.data.push(item);
            let newData = JSON.stringify(this.data);
            this.storage.set('todos', newData);
        }
    }


}    //export


/*

//console.log(this.userService);
          this.userService.getLocal(this.storage).subscribe(
            user => this.users = data,
            err => //console.log(err),
            () => //console.log('Random Quote Complete')
          );
          //console.log(this.users);
     */

     