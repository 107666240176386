import {Events, Platform, NavParams} from 'ionic-angular';
import {Component} from '@angular/core';
import {SafeResourceUrl, DomSanitizationService, SafeUrl} from '@angular/platform-browser';

import {StoreService} from '../../services/store-service';




@Component({
  templateUrl: 'build/pages/remote/remote.html'
})

export class RemotePage {
  storeService: any;
  platform: any;
  page: any;
  pageHeading: string;
  remotePageUrl: string;
  safeRemotePageUrl: SafeResourceUrl;
  navParams: any;
  pageUrl: string;
  isIOS: boolean;
  isAndroid: boolean;


  constructor(storeService: StoreService, platform: Platform, navParams: NavParams, sanitizer: DomSanitizationService) {
    this.storeService = storeService;
    this.platform = platform;

    var that = this;
    //this.storeService.setPage('remote');
 
    this.storeService.getPage().then((page) => {
      this.page = page;
      //console.log('page', this.page);
      if(this.page == 'about') {
        this.pageHeading = 'About WowGive'
      } else if(this.page == 'contact') {
        this.pageHeading = 'Contact WowGive'
      } else {
        this.pageHeading = '??';
      }
    });

    this.navParams = navParams;
    //console.log(navParams);
    this.pageUrl = navParams.get("page");

    
    this.remotePageUrl = 'http://www.wowgive.com/app/' + this.pageUrl + '.html?rnd=' + Math.random();
    console.log(this.remotePageUrl);
   // this.safeRemotePageUrl= this.sanitizer.bypassSecurityTrustUrl(this.remotePageUrl);
    this.safeRemotePageUrl = sanitizer.bypassSecurityTrustResourceUrl(this.remotePageUrl);

    console.log(this.safeRemotePageUrl);

    //console.log('loading:', this.remotePageUrl);

    this.isIOS = this.platform.is('ios');
    this.isAndroid = this.platform.is('android');
    //console.log('is', isAndroid, isIOS);
    //platform.onHardwareBackButton = function() {  //   doesn't work
      //console.log('onHardwareBackButton');
    //}
   // //console.log('platform', platform.

    if(this.isIOS) { // doesnt seem to need it now ?? IOS opens page within app, and second page opens in browser, with backbutton to go back to app.
      //window.location = 'http://www.wowgive.com/app/' + this.page + '.html';
    }
    

    function addAnEventListener(obj, evt, func) {
      if ('addEventListener' in obj) {
        obj.addEventListener(evt, func, false);
      } else if ('attachEvent' in obj) {//IE
        obj.attachEvent('on' + evt, func);
      }
    }
    function iFrameListener(event, a, b) {
      //console.log(event.data);
      var data = event.data;
      that.pageHeading = event.data.message;
    }

    addAnEventListener(window, 'message', iFrameListener);
  }  //  constructor

blurActiveInput() {
    if (this.isAndroid) return;

    var activeElement = document.activeElement;
    console.log(activeElement);
    if(activeElement.tagName == "INPUT" || activeElement.tagName == "TEXTAREA") {
      //activeElement.blur();
    }
  }


}// export



