import {Injectable} from "@angular/core";

import {SERVER_URL} from '../config';

import {StoreService} from './store-service';

@Injectable()
export class FileService {
    storeService: any;
    
    constructor(storeService: StoreService) {
        this.storeService = storeService;
        var that = this;
    }

    uploadImage(params: any, callbackSuccess: any, callbackFailure: any, progressHandler: any, that: any) {
/*
        setTimeout( function() {
            callbackSuccess({ h: 'sadas'});
        }, 2000);
*/
        console.log('uploadImage', that);
        var ft = new FileTransfer();                       
        //var options = new FileUploadOptions();
        //var filename = _.uniqueId() + ".jpg";
        var filename = 'chat';// + ".jpg";
        //console.log(this);

        var options = {
            fileKey: "file",
            fileName: filename,    // not needed, so use as a category
            mimeType: "image/jpeg",
            chunkedMode: false,
            //return this.http.get(url, { headers: this.storeService.getHeaders() });
            headers: { 
                'Content-Type' : undefined,// was undefined
                appId: 1
            },
            
            //headers: this.storeService.getHeaders(),

            params: params
        }
        console.log('that---', that);
        var url = SERVER_URL + 'postfile';
        ft.onprogress =  (e: ProgressEvent) => progressHandler(e, that);
        //this.plugins.file.upload('http://192.168.1.8:65000/1.0/postfile' , image, this.success, this.failed, this.onProgress);
        console.log('sending....sss', params.imageUrl, url);
        ft.upload(params.imageUrl, url, 
                  function(data) {
                    console.log('1a success', data);
                    callbackSuccess(data);
                }, function(err) {
                    console.log('err', err);
                    callbackFailure(err);
                }
                , options);         

        //console.log('eee');
        
    }















}// export




