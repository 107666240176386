import {NavController, NavParams, Platform, Events, Alert, ActionSheet} from 'ionic-angular';
import {Inject, NgZone,Component, DynamicComponentLoader, Injector} from '@angular/core';
import {CORE_DIRECTIVES, FORM_DIRECTIVES} from '@angular/common';

import {Contacts, SMS, Keyboard, SocialSharing, Clipboard} from 'ionic-native';



import {SERVER_URL} from '../../config';
import {APP_PLATFORM} from '../../config';
import {progressIndicator} from '../../components/progressindicator';

import {PluginsService} from '../../services/plugins-service';
import {ChatService} from '../../services/chat-service';
import {StoreService} from '../../services/store-service';
import {LocalService} from '../../services/local-service';
import {UtilService} from '../../services/util-service';

import {GroupPage} from '../group/group';
import {GroupsPage} from '../groups/groups';
import {AccountPage} from '../account/account';

declare const facebookConnectPlugin: any;

@Component({
  templateUrl: 'build/pages/group/groupchat.html',
  directives: [progressIndicator]
})
export class GroupChatPage {
  utilService: any;
  chatService: any;
  storeService: any;
  localService: any;
  platform: any;
  nav: any;
  icons: any;
  posts: any;
  pageTitle: string;
  user: any;
  oldDate: any;
  oldPaymentMonth: any;
  different: any;
  isGetting: boolean;
  isIOS: boolean;
  isAndroid: boolean;
  isDashboard: boolean;
  group: any;
  groupUser: any;
  mode: string;
  newMessage: string;
  statusMessage: any;
  isSending: boolean;
  events: any;
  zone: any;
  newPost: any;
  subscription: any;
  progress: number;
  injector: any;
  dcl: any;
  images: Array<string> = [];
  imageToSend: any;
  imageClass: string;
  expandedImage: string;
  groupsJoined: any;
  iAmGroupAdmin: boolean;
  imInThisGroup: boolean;
  imageToUpload: string;


  constructor(private plugins: PluginsService,
                dcl: DynamicComponentLoader,
                injector: Injector,
                nav: NavController, zone: NgZone, navParams: NavParams, events: Events, chatService: ChatService, storeService: StoreService, localService: LocalService,  utilService: UtilService, platform: Platform) {
    this.nav = nav;
    this.chatService = chatService;
    this.storeService = storeService;
    this.localService = localService;
    this.utilService = utilService;
    this.platform = platform;
    this.pageTitle = 'News';
    this.oldDate = null;
    this.oldPaymentMonth = null
    this.different = false;
    this.isGetting = true;
    this.posts = null;
    this.mode = 'view'
    this.user = this.storeService.getUser();
    this.newMessage = '';
    this.isSending = false;
    this.events = events;
    this.zone = zone;
    this.iAmGroupAdmin = false;
    this.imInThisGroup = false;
    this.imageToUpload = '';
    this.imageToSend = null;

    this.storeService.notification = null;
    
    this.storeService.setPage('groupchat');
    //this.storeService.setPage('posts');
    //console.log(navParams);
    this.group = navParams.get('group');
    this.groupUser = navParams.get('groupUser');
    //console.log('group', this.group);
    this.isIOS = this.platform.is('ios');
    this.isAndroid = this.platform.is('android');
    this.isDashboard = APP_PLATFORM == 'web';


    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };
    this.injector = injector;
    this.dcl = dcl;
    //this.componentRef = componentRef;
    this.progress = 0;
    


    var that = this;

    that.posts = [];

    that.displayPosts();


/*
    this.storeService.getGroupsJoined().then((groupsJoined) => {
      that.groupsJoined = groupsJoined;
      if (groupsJoined && groupsJoined.length > 0) {
          console.log('arry', groupsJoined.length, groupsJoined);
          // am I the admin of any of these?
          
          for(var i = 0; i < groupsJoined.length; i ++) {
            //console.log(i, groupsJoined[i].permission, PERMISSIONS_ADMIN);
            if (groupJoined[i].id == that.group.id && groupsJoined[i].permission == PERMISSIONS_ADMIN) {
              that.iAmGroupAdmin = true;
            }
          }        
        }
      });
*/

        this.groupsJoined = this.storeService.getGroupsJoined();
    //console.log('groupsJoined', this.groupsJoined);
    if (this.groupsJoined.then) {
      //console.log('groupsJoined - promise');
      this.groupsJoined.then((groupsJoined) => {
        if (groupsJoined) {
          this.groupsJoined = groupsJoined;
          ////console.log('groupsJoined--', this.groupsJoined);
          this.setIveJoinedThisGroup();
        }
      });
    } else {
      this.setIveJoinedThisGroup();
    }



    
    that.subscription = that.events.subscribe('groupchat:add', (newPost) => {
      //console.log('received new message');
      if (newPost && Array.isArray(newPost)) {
        newPost = newPost[0];  // somehow it's an array now
        //console.log(newPost);
        //console.log(that.group);
        //console.log(newPost.additionalData);
        //console.log(newPost.iu);
        if(that.group.id == parseInt(newPost.additionalData.g)) {
          that.newPost = newPost;
          that.zone.run(() => {  //   needed or doesn't update untill next change detection
            //console.log('add', that.newPost.message);
            that.posts.unshift({ message: that.newPost.message, dateTime: new Date(that.newPost.additionalData.dt), id: that.newPost.additionalData.pid, username: that.newPost.additionalData.un, imageUrl: that.newPost.additionalData.iu, edit: false});
            //console.log(that.posts);
            that.storeService.notification = null;
          });
        }
      }
    });
  }

  ionViewWillLeave() {
    //console.log('ionViewWillLeave');
    this.events.unsubscribe('groupposts:chat', this.subscription);
    this.storeService.setPage('');
  }


  setIveJoinedThisGroup() {
    //console.log('setIveJoinedThisGroup', this.groupsJoined);
    for (var i = 0; i < this.groupsJoined.length; i++) {
      ////console.log(this.groupsJoined[i].groupId, this.group.id);
      //console.log(i, this.groupsJoined[i]);
      if (this.groupsJoined[i].id == this.group.id) {
        ////console.log('this is a fave', this.group.id)
        this.imInThisGroup = true;
        //console.log('setIveJoinedThisGroup imInThisGroup');
      }
    }
  }


  displayPosts() {
    //console.log('displayChat', this.group);
    var that = this;
    let query = { groupId: that.group.id, order: 'desc' };
    //console.log(that);  //ubdefined
    //var that = this;
    //console.log(that);
    that.isGetting = true;
    that.chatService.get(query).subscribe(
      function(data) {
        //console.log(data);
        that.isGetting = false;
        var body = JSON.parse(data._body);
        if (body.data && body.data.posts) {
          //console.log(body.data.posts);
          //console.log(body.data.quantity);
          //var posts = body.data.posts;
          //console.log(posts);
          //console.log('qty', body.data.quantity);
          var posts = [], userColors = [];
          let len = body.data.quantity;
          let colors = ['a','b','c','d','e','f','g','h','i'];

          var unique = {};
          var distinct = [];
          var c = 0;
          for( var i in body.data.posts ){
            if( typeof(unique[body.data.posts[i].userId]) == "undefined"){
              distinct.push(body.data.posts[i].userId);
              userColors.push(colors[c]);
              c++;
            }
            unique[body.data.posts[i].userId] = 0;
          }
          //console.log(distinct);
          //console.log(userColors);

          for (let i = 0; i < len; i++) {
            posts.push({
              id: body.data.posts[i].id,
              message: body.data.posts[i].message,
              dateTime: new Date(body.data.posts[i].dateTime),
              url: body.data.posts[i].url,
              username: body.data.posts[i].username,
              avatar: body.data.posts[i].avatar,
              imageUrl: body.data.posts[i].imageUrl,
              userColor:  userColors[distinct.indexOf(body.data.posts[i].userId)],
              edit: true
            });
          }

          that.posts = posts;
          posts = null;
          body = null;
          data = null;

          
        } else if (body.error) {
          console.log(body.error);
          //that.loginError = body.error.code;
          //console.log('error returned');
        } else {
          //console.log(body);
        }
      },
      function(err) {
        console.log('error', err);
        that.isGetting = false;
        //console.log(err._body);
      },
      function() {
        //console.log(that.isGetting);
        that.zone.run(() => {
          //console.log(that.isGetting);
          //console.log('complete');
          that.isGetting = false;
          //console.log(that.isGetting);

        });
      }
    );
  }



  cancelSend() {
    this.mode = 'view';
  }
  openCompose() {
    //console.log('compose');
    //console.log(this.mode);
    this.mode = 'compose';
    //console.log(this.mode);
  }

deleteImageToSend() {
  this.imageToSend = null;
  this.imageToUpload = '';
}
 


sendMessage() {
    var that = this;
    
    if (!this.user || !this.user.id) {
      let message = 'Log in to send message?'
      let loginAlert = Alert.create({
        title: 'Messages',
        message: message,
        buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes',
              handler: () => {
                //gotoLogin();
                // user has clicked the alert button
                // begin the alert's dimiss transition
                let navTransition = loginAlert.dismiss();

                // start some async method
                //someAsyncOperation().then(() => {
                // once the async operation has completed
                // then run the next nav transition after the
                // first transition has finished animating out

                navTransition.then(() => {
                  that.nav.push(AccountPage);
                });
                return false;      
              }
            }]
          });
      this.nav.present(loginAlert);
    } else if(!this.imInThisGroup) {
      let message = 'You need to be a supporter to send messages. To support them, tap the heart button on the previous screen.'
      let supportAlert = Alert.create({
        title: 'Chat',
        message: message,
        buttons: [
          {
            text: 'OK',
            handler: () => {
            }
          }]
      });
      this.nav.present(supportAlert);
    } else {
      that.sendMessageOK();
    }
  }




gotFile(event) {
    var files = event.srcElement.files;
    //console.log(files);
    this.imageToSend = files[0];
    this.imageToUpload = this.imageToSend.name;
  }

  sendMessageOK() {
    //console.log(this.user);
    /*
    if(!this.user || !this.user.id) {
      this.utilService.setStatusMessage(this.statusMessage, 'error', 'You need to be logged in or registered to chat');
      return;
    }
    */
    //console.log(this.imageToSend);
    if(!this.imageToSend && !this.newMessage) {
      return;
    }

    var that = this;
    //console.log(this.newMessage);

    this.newMessage = this.newMessage.replace(/(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/ig, '<a href="$1">$1</a>');

    //console.log(this.newMessage);


    var params = {
       toGroupId: this.group.id,
       message: this.newMessage,
       userId: this.user.id,
       toGroupName: this.group.name,
       imageUrl: null,
       file: null
    };

    if(this.imageToSend) {
        params.imageUrl = this.imageToSend.name;
        params.file = this.imageToSend;
    }

    function onSuccessImage(data) {
      //console.log('success', data);

      that.isSending = false;

        var body = JSON.parse(data.response);
        var status = body.status;
        if (status == 'success' && body.data && body.data.post) {
          //console.log(body);
                that.newMessage = '';
      that.imageToSend = null;
          var post = body.data.post;

          that.zone.run(() => {
            that.mode = 'view';
            //console.log('add post to screen', that.posts.length, post);
            that.posts.unshift({
                id: post.id,
                message: post.message,
                dateTime: new Date(),
                url: post.url,
                username: that.user.username,
                imageUrl: post.imageUrl,
            });
          });
          //console.log('add post to screen', that.posts.length);
        } else {
          
          //console.log(body);
          that.zone.run(() => {
            var err = body.error.err;
            if(body.error.message) {
              err = body.error.message;
            }
            that.utilService.setStatusMessage(that.statusMessage, 'error', err);
        });
        }
    }
    function onFailureImage(err) {
      //var that = that.storeService.getScope();
      console.log('failure', err);
      that.isSending = false;
      that.utilService.setStatusMessage(that.statusMessage, 'error', err);
    }

    function onSuccess(data) {
      that.newMessage = '';
     // console.log('suc');
       // console.log(data);
        var body = JSON.parse(data._body);
        var status = body.status;
        if (status == 'success') {
         // console.log(body);
          var post = body.data.post;

          that.mode = 'view';

          that.posts.unshift({
              id: post.id,
              message: post.message,
              dateTime: new Date(),
              url: post.url,
              username: that.user.username,
              imageUrl: post.imageUrl,
          });
        } else {
          
          //console.log(body);
          var err = body.error.code;
          if(body.error.message) {
            err = body.error.message;
            // if use test card on prod, then get this
          }
          that.utilService.setStatusMessage(that.statusMessage, 'error', err);//  eg   //  eg ER_NO_SUCH_TABLE
        }
    }
    function onFailure(err) {
      console.log('failure', err);
      that.isSending = false;
    }

    that.isSending = true;
    //console.log(that.imageToSend, that.isDashboard);
    if(that.imageToSend) {
      if(that.isDashboard) {
        that.chatService.addWithImage(params, onSuccessImage, onFailureImage, that.onProgress, that).then((result) => {
          //console.log(result);
          that.isSending = false;
          result._body = JSON.stringify({ data: { post: result.data.post }, status: 'success'});
          onSuccess(result);
        })


      } else {
        that.chatService.addWithImage(params, onSuccessImage, onFailureImage, that.onProgress, that);
      }

    } else {
      that.chatService.add(params, that.onProgress).subscribe(onSuccess, onFailure, function(complete) {
          //console.log('complete add chat');
          that.isSending = false;
        });
    }

    /*.then((data) => {
      console.log('fin', data);

    });
*/
/*  
    that.chatService.add(params, that.onProgress).subscribe(
      function(data) {
        console.log('suc');
        console.log(data);
        var body = JSON.parse(data._body);
        var status = body.status;
        if (status == 'success') {
          console.log(body);
          var post = body.data.post;

          that.mode = 'view';

          that.posts.unshift({
              id: post.id,
              message: post.message,
              dateTime: new Date(),
              url: post.url,
              username: that.user.username,
              imageUrl: post.imageUrl,
          });
        } else {
          
          //console.log(body);
          var err = body.error.code;
          if(body.error.message) {
            err = body.error.message;
            // if use test card on prod, then get this
          }
          that.utilService.setStatusMessage(that.statusMessage, 'error', err);//  eg   //  eg ER_NO_SUCH_TABLE
        }
      },
      function(err) {
        
        console.log('error)', err);
      },
      function(complete) {
        console.log('complete donate');

        that.isSending = false;

      }
    );
*/




  }








    openAlbums() {
      var that = this;
        this.plugins.albums.open().then((imgUrls) => {            
            imgUrls.forEach((imageUrl: string) : void => {
                if(imageUrl){                  
                  this.images.push(imageUrl);
                  //this.upload(this.images[0]);
                  this.imageToSend = imageUrl;
                  //console.log(typeof(imageUrl), imageUrl);
                  //this.sendMessage();
                  //this.sendImage(this.images[0]);    
                }
            }); 
        });        
    }


/*
    openAlbums = () : void => {
        this.plugins.albums.open().then((imgUrls) => {            
            imgUrls.forEach((imageUrl: string) : void => {
                if(imageUrl){                  
                  this.images.push(imageUrl);
                  //this.upload(this.images[0]);
                  this.post.imageUrl = imageUrl;
                  this.sendMessage();
                  //this.sendImage(this.images[0]);    
                }
            }); 
        });        
    }
     */ 
    openCamera = () : void => { 
        this.plugins.camera.open().then((imageUrl) => { 
          if(imageUrl) {
            this.images.push(imageUrl);
            //this.upload(this.images[0]);
            //this.sendMessage();
            //this.sendImage(this.images[0]);                
          }
      });
    }
    /*
    startUploading = () : void => {
      var that = this;
      this.progress = 0;
    }
*/


    success = (result: any) : void => {
      //console.log('done'); 
    /*  
      this.upload(this.images[0]);      
        //this.current++;         
        
        if(this.current <= this.total) {              
            //this.setCurrent(this.current);   
            //this.setProgress(0);            
            setTimeout(() : void => {     
                // give the animation time to reset           
                this.upload(this.images[this.current - 1]);
            },1000);
        } else {   
            this.finished = true;
            //this.loading.dismiss();
            //$("#done").show();
            console.log('done');
        }
        */
    }
    
            
    failed = (err: any) : void => {
        var code = err.code;
        //console.log(err);
        //this.loading.dismiss();
        //alert("Failed to upload image. Code: " + code);
    }
    
    onProgress (progressEvent: ProgressEvent, that: any) {
      //console.log('onprog');
      //console.log(that.progress);
        if (progressEvent.lengthComputable) {
          

            that.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            //this.setProgress(this.progress); 
            that.zone.run(() => {
              that.progress = that.progress + 1;
              //console.log('progress is', that.progress, that);
            });
            //console.log(this.progress); 
            if(that.progress < 0 ){
              that.progress = 0;
            }
            if(that.progress > 100) {
                that.progress = 100;
            }         
        }
        
    }

   // setCurrent = (current: number) : void => {
       // $("#current").html(current.toString());
    //}
 
 /*
    upload = (image: string) : void => { 

        this.plugins.file.upload(SERVER_URL + 'postfile' , image, this.success, this.failed, this.onProgress);
    }
*/

  sendImage (image: string) { 

        //this.plugins.file.upload(SERVER_URL + 'postfile' , image, this.success, this.failed, this.onProgress);
    }








formatDate(date) {
  /* if today then say today * time in 24hr
    else day Tue 12:17
    if older than week say short date

  */
  let now = new Date();
  let now2 = now;
  let date2 = date;
  let today0 = now.getFullYear() + now.getMonth() + now.getDate();
  let date0 = date.getFullYear() + date.getMonth() + date.getDate();
  let today = date0 == today0;
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan'];
  let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
  let daysAgo = Math.round((+now - +date) / (60 * 60 * 24 * 1000));
  //console.log(today, daysAgo, now - date, date.getHours(), date.getMinutes());
  let day = days[date.getDay()];
  let time = date.getHours() + ':' + date.getMinutes();
  let output = day + ' ' + time;
  if (today) {
    output = 'Today ' + time;
  } else if(daysAgo > 6) {
    //console.log('more than 6 ');
    //output = date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear() + ' ' + time;
    output = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ' ' + time;
  }
  return output;
}



  monthName(dateTime) {
    return this.utilService.getMonthName(dateTime.getMonth());
  }


  postTapped(event, group) {
/*
    this.storeService.setGroup(group);
    this.nav.push(PostPage, {
      group: group
    });
    // gets rid of back button and shows a menu button on left
*/
  }


togglePanel(post) {
  if (post.edit == true) {
    post.edit = false;
  } else {
    post.edit = true;
  }
  //check post is owned by you
  // or you are the group admin
  //console.log('press', post.username, this.user.username, this.group.ownedByUserId, this.user.id);
  //if(post.username == this.user.username || this.group.ownedByUserId == this.user.id) {
    //post.edit = true;
  //}
}

closePanel($event,post) {
  $event.stopPropagation();
  //console.log('close');
  post.edit = false;
}

deletePost(e, post, index) {
  e.preventDefault(); // added for ionic
  //e.stopPropagation();
  //console.log(index);
  var that = this;

   var alertDelete = Alert.create({
          title: 'Delete this post',
          message: "Are you sure?",
          buttons: [
            {
              text: 'No',
              handler: () => {
                let navTransition = alertDelete.dismiss();
                return false;
              }
            },
            {
              text: 'Yes',
              handler: () => {
                let navTransition = alertDelete.dismiss();
                post.edit = false;
                this.chatService.delete(post).subscribe(
                      function(data) {
                        var body = JSON.parse(data._body);
                        //console.log(data);
                        that.posts.splice(index, 1);
                        that.utilService.setStatusMessage(that.statusMessage, 'status', 'Post deleted.', 3000);
                        },
                      function(err) {
                        that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
                        console.log('error', err);
                      },
                      function() {
                        //console.log('complete');
                      }
                    );
                return false;
              }
            }]
        });// alert
        that.nav.present(alertDelete);





}


openGroup($event, post) {
  //console.log(post);
  $event.stopPropagation();
  var group = { id: this.group.id};
    this.storeService.setGroup(this.group);
        this.nav.remove(2,1);
     this.nav.push(GroupPage, {
        group: this.group,
      });
}

tapImage($event, post) {
  // max image - change class?
  //console.log(post);
  $event.stopPropagation();
  this.imageClass = 'expanding';
  this.expandedImage = post.imageUrl;
}
closeImage() {
  this.imageClass="";
  //console.log('close');
}
openInExternalBrowser(url)
{
 // Open in external browser
 window.open(url,'_system','location=yes'); 
};
 
openInAppBrowser(url)
{
 // Open in app browser
 window.open(url,'_blank'); 
};
 
openCordovaWebView(url)
{
 // Open cordova webview if the url is in the whitelist otherwise opens in app browser
 //console.log(url);
 window.open(url,'_self'); 
};

























clickShare(event, post, index) {
    var that = this;
    //var url = 'http://www.wowgive.com';
    var group = this.group;
    console.log(group);
    var url = 'http://www.wowgive.com/fundraising/' + slugify(group.name)  + '-' + group.id + '.html#posts';

    function slugify(name) {
      name = name.replace(/[-\s|\.]+/g, '-');
      return name.trim();
  }
  console.log(url);
  var img = post.imageUrl 
  var subject = "Please support " + this.group.name + '';
  var message = post.message + '  ' + url;
 

/*

    var messagel = message.toLowerCase();
            if(messagel.indexOf('wowgive') == -1) {
              message += '\n\nPlease help support  via the WowGive app, available on the app store.';
            }
            if(messagel.indexOf('wowcode') == -1) {
              message += '\n\nEnter WowCode ' + this.group.code + ' in the WowGive mobile app to connect with us.';
            }

*/
    console.log(message);
    console.log(subject);
    

    var to = null;
    var cc = null;
    var bcc = null;
    var files = [];
    var number = null;
    var isAndroid = this.isAndroid;

    var buttons = [
        {
          text: 'Send Email',
          handler: () => {

            //message += '\n' + this.group.name;
            //message += '\n\n<img src="' + img + '" />\n';

            if (message) message = message.replace(/\n/g, '<br>');

            files.push(img);
            SocialSharing.shareViaEmail(message, subject, to, cc, bcc, files);
          }
        }, {
          text: 'Send Text',
          handler: () => {
            //message += '\n' + this.group.name;

            SocialSharing.shareViaSMS(message, number);
          }
        }, {
          text: 'Share via Facebook',
          handler: () => {
            // add url to end of message and copypaste
            //message += '\n' + url + '\n';
            Clipboard.copy(message);
            var prompt = "Long press in the 'Say something about this' box to paste in a message";
            that.shareViaFacebook(message, img, url, prompt );
            /*
            this seems to work, but leaves menu on screen
            SocialSharing.canShareVia('facebook',message, img, url, null,
              function(success) {
                //console.log('suc', success);
                that.shareViaFacebook(message, img, url, prompt );
                
              }, function(err) {
                console.log(err, 'err');
                // if no FB app, then share via web!
                that.shareFacebook(subject, message, img, url, prompt );
                //that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need the Facebook app on your phone to do this.');
              }
            );
            */
          }
        }, {
          text: 'Share via Instagram',
          handler: () => {

            Clipboard.copy(message);
            //message += ' \n' + url;

            var alertPaste = Alert.create({
                  title: 'Tip',
                  message: "Long press in the 'Write a caption' box to paste in a message",
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => {
                        let navTransition = alertPaste.dismiss();
                        navTransition.then(() => {
                          SocialSharing.shareViaInstagram(message, img);
                        });
                        return false;
                      }
                    }]
                });// alert
                that.nav.present(alertPaste);

                /*
            SocialSharing.canShareVia('instagram',message, img, url, null,
              function(success) {
                console.log('suc', success);
                var alertPaste = Alert.create({
                  title: 'Tip',
                  message: "Press in the 'Write a caption' box to paste in a message",
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => {
                        let navTransition = alertPaste.dismiss();
                        navTransition.then(() => {
                          SocialSharing.shareViaInstagram(message, img);
                        });
                        return false;
                      }
                    }]
                });// alert
                that.nav.present(alertPaste);
              }, function(err) {
                console.log(err, 'err');
                that.zone.run(() => { 
                  that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need the Instagram app on your phone to do this.');
                });
              }
            );  //  socialCan
            */
            
          }
        }, {
          text: 'Share via Twitter',
          handler: () => {
            message = message.substr(0,91);  //  140 -23 * 2
            //message = "I'm raising money for " + this.group.name + " with @WowGive and I'd love your support. #WowCode " + this.group.code +'.';
            SocialSharing.shareViaTwitter(message, img, url);
            /*

  // canshare said no twitter app on iphone 6 even when installed
              SocialSharing.canShareVia('twitter',message, img, url, null,
                function(success) {
                  console.log('suc', success);
                  SocialSharing.shareViaTwitter(message, null, url);
                }, function(err) {
                  console.log(err, 'err');
                  that.zone.run(() => { 
                    that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need the Twitter app on your phone to do this.');
                  });
                }
              );
              */
          }
        }, {
          text: 'Share via WhatsApp',
          handler: () => {


Clipboard.copy(message);

            var alertPaste = Alert.create({
                  title: 'Tip',
                  message: "Long press in the 'Tap to add a caption' box to paste in a message",
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => {
                        let navTransition = alertPaste.dismiss();
                        navTransition.then(() => {
                          SocialSharing.shareViaWhatsApp(message, img, null);
                        });
                        return false;
                      }
                    }]
                });// alert
                that.nav.present(alertPaste);
              }
            //SocialSharing.shareViaWhatsApp(message, img, null);
            /*
            doing the canShare said that whatsapp wasn't on iphone 6??

             SocialSharing.canShareVia('whatsapp',message, null, url, null,
                function(success) {
                  console.log('suc', success);
                  SocialSharing.shareViaWhatsApp(message, null, url);
                }, function(err) {
                  console.log(err, 'err');
                  that.zone.run(() => { 
                    that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need WhatsApp  on your phone to do this.');
                  });
                }
              );
*/
        }, {
          text: "Let's see the options",
          handler: () => {
            that.socialShare(subject, message, url);
          }
        }, {
          text: 'Cancel',
          style: 'cancel',
          handler: () => {
          }
        }
      ];
 
    let actionSheet = ActionSheet.create({
      title: 'SHARE OPTIONS',
      subTitle: 'You will be able to type or paste a message before anything is posted',
      buttons: buttons
    });
    
    this.nav.present(actionSheet);
}


socialShare(subject, message, url) {
  // this is the complete list of currently supported params you can pass to the plugin (all optional)
var options = {
  message: message, // not supported on some apps (Facebook, Instagram)
  subject: subject,  // fi. for email
  files: ['', ''], // an array of filenames either locally or remotely
  url: url
  //chooserTitle: 'Pick an app' // Android only, you can override the default share sheet title
}

var onSuccess = function(result) {
  console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
  console.log("Shared to app: " + result.app); // On Android result.app is currently empty. On iOS it's empty when sharing is cancelled (result.completed=false)
}

var onError = function(msg) {
  console.log("Sharing failed with message: " + msg);
}
console.log(options);
  SocialSharing.share(options.message, options.subject, options.files, options.url);
  //SocialSharing.shareWithOptions(options.message, options.subject, options.files, options.url);

}
/*
shareViaFacebook(message, img, url, prompt ) {
  var that = this;
    if(that.isAndroid) {
      console.log('isAndroid');
      SocialSharing.shareViaFacebookWithPasteMessageHint(message, img, url, prompt );
    } else {
      var alertPaste = Alert.create({
        title: 'Tip',
        message: prompt,
        buttons: [
          {
            text: 'Ok',
            handler: () => {
              let navTransition = alertPaste.dismiss();
              navTransition.then(() => {
                SocialSharing.shareViaFacebook(message, img, url);
              });
              return false;
            } //  handler
          }]  //   buttons
      }); //   alert create
      that.nav.present(alertPaste);
    } //   if android
}
*/

shareViaFacebook(message, img, url, prompt ) {
  var that = this;
    
    var alertPaste = Alert.create({
      title: 'Tip',
      message: prompt,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            let navTransition = alertPaste.dismiss();
            navTransition.then(() => {
              if(that.isAndroid) {
                SocialSharing.shareViaFacebookWithPasteMessageHint(message, img, url, prompt );
              } else {
                SocialSharing.shareViaFacebook(message, img, url);
              }
            });
            return false;
          } //  handler
        }]  //   buttons
    }); //   alert create
    that.nav.present(alertPaste);
}


  shareFacebook(subject, message, img, url, prompt ) {
    // when posting via FB app doesn't work with other plugin
    // then post without the app via web

    var options = {
      method: "share",
      href: url,
      caption: subject,
      description: message,
      picture: img,
    };  

    var alertPaste = Alert.create({
      title: 'Tip',
      message: prompt,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            let navTransition = alertPaste.dismiss();
            navTransition.then(() => {
              facebookConnectPlugin.showDialog(options,
                function (response) {
                  console.log('response', response)
                }, function (err) {
                  console.log('err', err)
                }
              );
            });
            return false;
          }
        }]
    });
    this.nav.present(alertPaste);


/*
    facebookConnectPlugin.showDialog(options,
      function (response) {
        console.log('response', response)
      }, function (err) {
        console.log('err', err)
      }
    );
    */


  }













































}


