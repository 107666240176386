export class UtilService {
    months: any;

    constructor() {
        this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'January'];
    }
     
    setStatusMessage(statusMessage, statusType, text, duration) {
        if(!duration) duration = 3000;
        statusMessage.type = statusType;  //  status or error
        statusMessage.visible = true;
        statusMessage.text = text;
        //console.log('statusType', statusType);
        if (statusType != 'success') {
			setTimeout(function() {
        //console.log('timm', statusMessage);
				statusMessage.visible = false;
			}, duration);
		}
    }

    getMonthName(month) {
        return this.months[month];
    }
/*
constructor(nav: NavController) {
  this.nav = nav;
}

presentToast() {
  let toast = Toast.create({
    message: 'User was added successfully',
    duration: 3000
  });

  toast.onDismiss(() => {
    console.log('Dismissed toast');
  });

  this.nav.present(toast);
}*/
    

}