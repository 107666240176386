import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs/Observable';
import {SERVER_URL} from '../config';

import {LocalService} from './local-service';
import {StoreService} from './store-service';

@Injectable()
export class PaymentService {
    http: any;
    storeService: any;
    payment: any;

  constructor(http: Http, lacalService: LocalService, storeService: StoreService) {
    this.http = http;
    this.storeService = storeService;
    this.payment = {};
  }



  get(query) {
      //console.log(SERVER_URL);
      let url = SERVER_URL + 'payments';
      if(query.userId) {
          url = SERVER_URL + 'users/' + query.userId + '/payments?';
      }
      if (query.groupId) {
          url = SERVER_URL + 'groups/' + query.groupId + '/payments?';
      }
      if (query.all) {
          //url = url + '&all=' + query.collateByMonth;
      }
      if(query.order) {
          url = url + '&order=' + query.order;
      }
      if (query.currentMonth) {
          url = url + '&currentmonth=' + query.currentMonth;
      }
      if (query.collateByMonth) {
          url = url + '&collatebymonth=true';
      }
      //console.log(url);
      let body = JSON.stringify({});
      //console.log(this);
      return this.http.get(url, { headers: this.storeService.getHeaders() });
  }


  getRecurring(query) {
      //console.log(SERVER_URL);
      let url = SERVER_URL + 'recurringpayments';
      if (query.userId) {
          url = SERVER_URL + 'users/' + query.userId + '/recurringpayments?order=' + query.order;
      }
      if (query.groupId) {
          url = SERVER_URL + 'groups/' + query.groupId + '/recurringpayments?order=' + query.order;
      }
      //console.log(url);
      let body = JSON.stringify({});
      //console.log(this);
      return this.http.get(url, { headers: this.storeService.getHeaders() });
  }



/*

{
        method : 'POST',
        url : 'someurl/',
        headers: {
 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
*/
/*
    getToken(token) {
        //console.log(SERVER_URL, token);
        let url = SERVER_URL + 'createcustomer';
        //console.log(url);
        //let body = JSON.stringify({ stripeToken: "tok_17jCIlFt7eFMAPq1cssNa95v"});
        let body = JSON.stringify({ stripeToken: token });
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        //console.log(this.http);
        return this.http.post(url, body, options);

    }
*/

    createCustomer(token) {
        //console.log(SERVER_URL, token);
        let url = SERVER_URL + 'createcustomer';
        //console.log(url);
        let body = JSON.stringify({ stripeToken: "tok_17jZaYFt7eFMAPq1cIhsAHZ7"});
        //let body = JSON.stringify({ stripeToken: token });
        //let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: this.storeService.getHeaders() });
/*
        return Observable.create(observer => {
            observer.next(this.http.post(url));
            observer.complete();
        });
        */
/*
        //console.log(this.http);
        return this.http.post(url)
            .map(res => res.json())
            .catch(this.handleError);
*/
        //console.log(this.http);
        return this.http.post(url, body, options);

    }


//not used - see user makepayment
    chargeCard(customerId) {
        //console.log(SERVER_URL, customerId);
        let url = SERVER_URL + 'chargecard';
        //console.log(url);
        //let body = JSON.stringify({ stripeToken: "tok_17jCIlFt7eFMAPq1cssNa95v"});
        let body = JSON.stringify({ customerId: customerId });
        //let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: this.storeService.getHeaders() });
        //console.log(this.http);
        return this.http.post(url, body, options);
        /*
        //console.log(SERVER_URL);
        let url = SERVER_URL + 'chargecard';
        //console.log(url);
        return Observable.create(observer => {
            observer.next(url);
            observer.complete();
        });
        */
    }








    handleError(error) {
        console.error(error);
        return Observable.throw(error.json().error || 'Server error');
    }


}// export
