import {NavController, NavParams, Alert, Loading, Content} from 'ionic-angular';
import {Inject, ViewChild, Component, NgZone} from '@angular/core';

import {GroupService} from '../../services/group-service';
import {StoreService} from '../../services/store-service';
import {UserService} from '../../services/user-service';
import {UtilService} from '../../services/util-service';

import {GroupPage} from '../group/group';
import {AccountPage} from '../account/account';
import {GroupPostsPage} from '../group/groupposts';
import {GroupChatPage} from '../group/groupchat';

@Component({
  templateUrl: 'build/pages/groups/groups.html'
})
export class GroupsPage {
  @ViewChild(Content) content: Content;
  //@ViewChild(Nav) nav: Nav;

  app: any;
  //nav: any;
  navParams: any;
  zone: any;
  icons: any;
  groups: any;
  groupsJoined: any;
  groupService: any;
  storeService: any;
  userService: any;
  utilService: any;
  user: any;
  page: any;
  pageTitle: string;
  isGetting: boolean = false;
  connectionError: boolean;
  statusMessage: any;
  searchingFor: string;
  wowCode: string;
  loading: any;
  searchMode: boolean;
  group: any;
  recordsFound: boolean;
  people: any;
  person: any;
  today: any;
  view: string;

//

  constructor(private nav: NavController, navParams: NavParams, groupService: GroupService, storeService: StoreService, userService: UserService, utilService: UtilService, zone: NgZone) {
   // this.app = app;
   // this.nav = nav;
    //console.log('nav', nav);
    //console.log('this.app.getComponent(nav)', this.app.getComponent('nav'));
    this.navParams = navParams;
    this.groupService = groupService;
    this.zone = zone;
    this.storeService = storeService;
    this.userService = userService;
    this.utilService = utilService;
    this.isGetting = false;
    this.connectionError = false;
    this.searchMode = false;
    this.group = {};
    this.recordsFound = true;
    this.groups = [];
    this.groupsJoined = [];
    this.people = [];
    this.person = {};
    this.view = "home";

    this.page = navParams.get('page');
    //console.log('nav params----', this.page);
    if (this.page == 'groupsSearch') {
      this.openSearch();
    }

    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };
    this.today = new Date();


    
    var that = this;
    this.searchingFor = '';


    ////console.log(this.user);
    ////console.log(this.storeService.gettingUserFromLocal);
    var user = that.storeService.getUser();
    //console.log(user);
    //console.log(that.page);
    //console.log('grouppage');



    this.storeService.getPage().then((page) => {
      that.page = page;
      //console.log(page);
      if (page == 'groupsSearch') {
        this.pageTitle = 'Search';
      } else if (page == 'groupsFeatured') {
        this.pageTitle = 'Featured';
      } else if (page == 'groupsFundraisers') {
        this.pageTitle = 'Fundraisers';
      } else if (page == 'groupsTrending') {
        this.pageTitle = 'Trending';
      } else {
        this.pageTitle = 'Home';
        // home screen
      }
      //console.log(page);
    


      if(that.page == 'groupsFeatured' || that.page == 'groupsTrending'  || that.page == 'groupsFundraisers') {
        that.getGroups();
      } else if (user.then) {
        //console.log('user.then');
        user.then((user) => {
          //console.log('user.then then');
          that.user = user;
          
          //console.log(that.page);
          //console.log(that.groupsJoined);
          if(that.page == 'groupsJoined' && that.user) {
            //console.log('go for it');
            that.getGroups();
         // } else if (that.page == 'groupsJoined' && !that.user && that.groupsJoined.length<1) {
           // that.page = 'groupsFeatured';
            //that.getGroups();
          }
        });  //   then
      } else {
        //console.log('user.NOT then');
        that.user = user;
        //console.log(that.page);
        if (that.page == 'groupsJoined') {
          that.getGroups();
        } else {
          //console.log('dunno what page');
        }
      }

      if (that.page == 'groupsSearch') {
        //that.isGetting = false;
        //that.hideLoadingDefault();
        setTimeout( function() {
          //that.openSearch();  //   doesn't bother trying to getGroups, as search will prompt
        }, 100);
      }
    

/*
      if (page == 'groupsFeatured') {
        this.storeService.getWowCode().then((wowCode) => {
          if(!wowCode) {
            this.promptWowCode();
          }
        });
      }
*/
    });  // storeService.getPage


  }//

scrollToTop() { //zzz
  //if(this.content) {
    //  this.content.scrollToTop();
  //}
}

showLoadingDefault() {
  /*
  let loading = Loading.create({
    content: 'Please wait...',
    spinner: 'circles'
  });

  this.nav.present(loading);
  this.loading = loading;
  */
 // this.isGetting = true;
}
hideLoadingDefault() {
  if(this.loading) {
    this.loading.dismiss();
  }
 // this.isGetting = false;
}


openGroupPosts(group) {
  this.nav.push(GroupPostsPage, {
    group: group
  });
}
openGroupChat(group) {
  this.nav.push(GroupChatPage, {
    group: group
  });
}
openPosts() {
  for(let i=0; i < this.groups.length; i++) {
    if(this.groups[i].id == this.storeService.notification.groupId) {
      this.nav.push(GroupPostsPage, {
        group: this.groups[i]
      });
    }
  }
}



openChat() {
  //console.log('openchat', this.page);
  var groups = this.groups;
  if(this.page=='groupsJoined') {
    groups = this.groupsJoined;
  }
  for(let i=0; i < groups.length; i++) {
    //console.log(i, groups[i].id, this.storeService.notification.groupId);
    if(groups[i].id == this.storeService.notification.groupId) {
      this.nav.push(GroupChatPage, {
        group: groups[i]
      });
    }
  }
}



setIveJoinedThisGroup() {
    //console.log('setIveJoinedThisGroup', this.groupsJoined);
    for (var i = 0; i < this.groups.length; i++) {
      ////console.log(this.groupsJoined[i].groupId, this.group.id);
      //console.log(i, this.groupsJoined[i]);
      if (this.groups[i].id == this.group.id) {
        ////console.log('this is a fave', this.group.id)
        //this.iAmInOneOfTheseGroups = true;
        //console.log('setIveJoinedThisGroup imInThisGroup');
      }
    }
  }



displayGroups(groups, category) {
  var that = this;
  that.zone.run(() => { 
    //console.log('displayGroups', groups, category);
    if(category == 'featured') {
      groups = shuffle(groups);
    }
    var newGroups = [];
    for (let i = 0; i < groups.length; i++) {
      //console.log(groups[i].id);
      var lastChatDateTime = null;
      if (groups[i].lastChatDateTime) {
        lastChatDateTime = new Date(groups[i].lastChatDateTime);
      }
      var gt =  {
        id: groups[i].id,
        name: groups[i].name,
        tagline: groups[i].tagline,
        logoUrl: groups[i].logoUrl,
        mainImageUrl: groups[i].mainImageUrl,
        description: groups[i].description,
        charityNumber: groups[i].charityNumber,
        code: groups[i].code,
        entityId: groups[i].entityId,
        parentId: groups[i].parentId,
        parentName: groups[i].parentName,
        showTotalPaymentForMonth: groups[i].showTotalPaymentForMonth,
        ownedByUserId: groups[i].ownedByUserId,
        targetAmount: groups[i].targetAmount,
        targetDate: null,
        type: groups[i].type,
        status: groups[i].status,
        amount: groups[i].amount,
        donationsQty: groups[i].donationsQty,
        membersTotal: groups[i].membersTotal,
        lastChatMessage: groups[i].lastChatMessage,
        lastChatImageUrl: groups[i].lastChatImageUrl,
        lastChatDateTime: lastChatDateTime,
      };

      if (groups[i].targetDate) {
          gt.targetDate = new Date(groups[i].targetDate);
      }
      newGroups.push(gt);  // push

    }//  for
    this.groups = newGroups;
    newGroups = null;
    groups = null;
  })


  function shuffle(array) {
      var currentIndex = array.length, temporaryValue, randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
  }


}

displayGroupsJoined(groups) {
  var that = this;
  //console.log('displayGroupsJoined', groups);
  that.zone.run(() => { 
    //console.log(groups);
    var newGroups = [];
    for (let i = 0; i < groups.length; i++) {
      //console.log(groups[i].id);
      var lastChatDateTime = null;
      if (groups[i].lastChatDateTime) {
        lastChatDateTime = new Date(groups[i].lastChatDateTime);
      }
      var gt = {
        id: groups[i].id,
        name: groups[i].name,
        tagline: groups[i].tagline,
        logoUrl: groups[i].logoUrl,
        mainImageUrl: groups[i].mainImageUrl,
        description: groups[i].description,
        charityNumber: groups[i].charityNumber,
        code: groups[i].code,
        entityId: groups[i].entityId,
        parentId: groups[i].parentId,
        parentName: groups[i].parentName,
        showTotalPaymentForMonth: groups[i].showTotalPaymentForMonth,
        ownedByUserId: groups[i].ownedByUserId,
        targetAmount: groups[i].targetAmount,
        targetDate: null,
        type: groups[i].type,
        status: groups[i].status,
        donationsQty: groups[i].donationsQty,
        amount: groups[i].amount,
        membersTotal: groups[i].membersTotal,
        lastChatMessage: groups[i].lastChatMessage,
        lastChatImageUrl: groups[i].lastChatImageUrl,
        lastChatDateTime: lastChatDateTime,
        daysLeft: 1,//Math.round((new Date(groups[i].targetDate) - this.today ) / 86400000)  + 1,
      }
       if (groups[i].targetDate) {
          gt.targetDate = new Date(groups[i].targetDate);
      }
      newGroups.push(gt);  // push
      //console.log(groups[i].targetDate, this.today, new Date(groups[i].targetDate)-this.today, ((groups[i].targetDate-this.today)/86400000) );
    }//  for
    //console.log(this);
    this.groupsJoined = newGroups;
    newGroups = null;
    groups = null;
  });
}

  getGroups() {
    var that = this;
            that.isGetting = true;
    //console.log('thatiser', that.user, that.page);
    //this.showLoadingDefault();

    
    if (that.page == 'groupsFeatured') {
      var service = that.groupService.getFeatured();
      getGroupsService(service, 'featured');
      //getLocalGroupsJoined(that);
      //console.log('groupsFeatured');
    } else if (that.page == 'groupsTrending') {
      var service = that.groupService.getTrending();
      getGroupsService(service, 'trending');
      //getLocalGroupsJoined(that);
      //console.log('groupsFeatured');
    } else if (that.page == 'groupsFundraisers') {
      var service = that.groupService.getFundraisers();
      getGroupsService(service, 'fundraisers');
      //getLocalGroupsJoined(that);
      //console.log('groupsFeatured');
    } else if (that.page == 'groupsJoined' && that.user && that.user.id) {
      // get groupsJoined from remote, as logged in.
      service = that.userService.getGroupsJoined({ userId: that.user.id });
      getGroupsService(service, 'joined');
      //service = that.groupService.getFeatured();
      //getGroupsService(service, 'featured');
      //console.log('groupsJoined');
    } else if (that.page == 'groupsJoined') {
      // only got local groupsJoined from WowCode
        //console.log('groupsJoined local');
        var gj = that.storeService.getGroupsJoined();
        if (gj && !gj.then) {
          //console.log('gj', gj);
          if(gj && gj.length>0) {
            that.displayGroupsJoined(gj);
          }
          that.isGetting = false;
          //that.hideLoadingDefault();
        } else {
          gj.then((groupsJoined) => {
            //console.log('gropesoij', groupsJoined);
            if(groupsJoined && groupsJoined.length>0) {
              //console.log('yuuo');
              that.displayGroupsJoined(groupsJoined);
            } else {

            }
            that.isGetting = false;
           // that.hideLoadingDefault();
          });
        }  
        //var service = that.groupService.getFeatured();
        //getGroupsService(service, 'featured');
    }

    function getLocalGroupsJoined() {
      //console.log(this);
      //console.log(that);
      //var that = this;
      console.log(that.storeService.getGroupsJoined());
      if(that.storeService.getGroupsJoined().then) {
        that.storeService.getGroupsJoined().then((groupsJoined) => {
          that.groupsJoined = groupsJoined;
        });
      } else {
        that.groupsJoined = that.storeService.getGroupsJoined();
      }
    }


    function getGroupsService(service, category) {
      //console.log('getGroupsService');
      //console.log(service);
      if(service) {
        service.subscribe(
          function(data) {
            //console.log(data);
            that.connectionError = false;
            //that.isGetting = false;
            //that.hideLoadingDefault();
            var body = JSON.parse(data._body);
            ////console.log(body);
            if (body.data && body.data.groups) {
              //console.log(body.data.groups);
              //console.log(body.data.quantity);

              var groups = body.data.groups;
              
              if(groups.length>0) {
                //console.log('category', category);
                if(category == 'joined') {
                  that.displayGroupsJoined(groups);
                } else {
                  that.displayGroups(groups, category);
                }
                
                
                that.storeService.setGroups(that.groups);
              } else {

              }
            } else if (body.error) {
              //console.log(body.error);
              //that.loginError = body.error.code;
              //console.log('error returned');
              that.isGetting = false;
            } else {
                  //console.log(body);
            }
            //getLocalGroupsJoined();
          }, function(err) {
            //console.log('error', err);
            //console.log(err._body);
            //var body = JSON.parse(err._body);
            ////console.log(body);
            //that.loginError = 'Error ' + err.status;
            ////console.log(this.userService);
            //that.passwordControl.
            //that.hideLoadingDefault();
            that.isGetting = false;
            that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
            that.connectionError = true;
          }, function() {
            //console.log('complete');
            that.zone.run(() => { 
              that.isGetting = false;
            });
          //  that.hideLoadingDefault();
        });  //  service subscribe
      } else {
        //console.log('if else');
        //that.isGetting = false;
        //that.hideLoadingDefault();
      }//  if
      service = null;
    }
  
}




  openSearch() {
    this.searchMode = true;
    var that = this;
  //  this.zone.run(() => {  //   needed or doesn't update untill next cheange detection
          that.isGetting = false;
   // });
     that.groupsJoined = [];
     that.groups = [];
     that.group = {};

    
    //let dimensions = this.content.getContentDimensions();
this.scrollToTop();

  }



  OldpenSearch() {
    var that = this;
    let prompt = Alert.create({
      title: 'Search',
      message: "Enter the name (or part name) of the charity you wish to search for?" + that.isGetting,
      inputs: [
        {
          name: 'name',
          placeholder: 'Name'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: data => {
            //console.log('Cancel clicked');
            //that.isGetting = false;
            that.hideLoadingDefault();
          }
        },
        {
          text: 'Search',
          handler: data => {
            //console.log(data.name);
            that.searchingFor = data.name;
            if (data.name.length < 2) {
              that.utilService.setStatusMessage(that.statusMessage, 'error', 'Not enough characters to search for - minimum 2.')
            } else {
             // that.searchGroup(data.name);
            }
          }
        }
      ]
    });
    that.nav.present(prompt);
  }








  searchGroup() {
    var that = this;
    var group = that.group;
    var person = that.person;
    //console.log(group);

    if(!group.name && !group.code && !group.areaCode && !group.postCode && !group.address && !person.name) {
      that.utilService.setStatusMessage(that.statusMessage, 'error', 'You must enter at least one of the search fields.');
      return;
    }
    let fields = 0;
    this.searchingFor = '';
    if (group.name) {
      this.searchingFor += ' name:' + group.name;
    }
    if (group.code) {
      this.searchingFor += ' WowCode:' + group.code;
    }
    if (group.address) {
      this.searchingFor += ' address:' + group.address;
    }
    if (group.postCode) {
      this.searchingFor =  'postcode:' + group.postCode;
    }
    if (group.areaCode) {
      this.searchingFor = ' telephone:' + group.areaCode;
    }
    if (person.name) {
      this.searchingFor += "fundraiser's name:" + person.name;
    }
    /*if(fields > 1) {
      that.utilService.setStatusMessage(that.statusMessage, 'error', 'You can only search on one field at a time');
      return;
    }*/

     if(person.name && person.name.length < 3) {
      that.utilService.setStatusMessage(that.statusMessage, 'error', 'Enter at least 3 letters');
      return;
    }

    if(group.name && group.name.length < 3) {
      that.utilService.setStatusMessage(that.statusMessage, 'error', 'Enter at least 3 letters');
      return;
    }
    if(group.areaCode && group.areaCode.length < 5) {
      that.utilService.setStatusMessage(that.statusMessage, 'error', 'Enter at least 5 digits');
      return;
    }
    if(group.postcode && group.postcode.length < 3) {
      that.utilService.setStatusMessage(that.statusMessage, 'error', 'Enter at least 4 digits');
      return;
    }
    if(group.address && group.address.length < 4) {
      that.utilService.setStatusMessage(that.statusMessage, 'error', 'Enter at least 4 digits');
      return;
    }

    that.groups = [];
    that.people = [];

   // var service = that.groupService.search( group );
    //that.showLoadingDefault();

    that.isGetting = true;


    if(this.person && this.person.name) {
      //console.log('seacrhForPerson');
      this.searchForPerson(person);
    } else {
      //console.log('seacrhForGroup');
      this.searchForGroups(group);
    }
}


    searchForGroups(group) {
      var that = this;
      that.groupService.search( group ).subscribe(
        function(data) {
          that.connectionError = false;
          //console.log(data);
          var body = JSON.parse(data._body);
          ////console.log(body);
          if (body.data && body.data.groups) {
            ////console.log(body.data.groups);
            ////console.log(body.data.quantity);
            var groups = body.data.groups;

            for (let i = 0; i < body.data.quantity; i++) {
              //console.log(i);
              var address = body.data.groups[i].address ? body.data.groups[i].address.replace(/<br>/g,', ') : '';
              var lastChatDateTime = null;
              if (body.data.groups[i].lastChatDateTime) {
                lastChatDateTime = new Date(body.data.groups[i].lastChatDateTime);
              }
              var gt  = {
                id: body.data.groups[i].id,
                name: body.data.groups[i].name,
                tagline: body.data.groups[i].tagline,
                logoUrl: body.data.groups[i].logoUrl,
                mainImageUrl: body.data.groups[i].mainImageUrl,
                description: body.data.groups[i].description,
                charityNumber: body.data.groups[i].charityNumber,
                code: body.data.groups[i].code,
                entityId: body.data.groups[i].entityId,
                parentId: body.data.groups[i].parentId,
                parentName: body.data.groups[i].parentName,
                showTotalPaymentForMonth: body.data.groups[i].showTotalPaymentForMonth,
                ownedByUserId: body.data.groups[i].ownedByUserId,
                targetAmount: body.data.groups[i].targetAmount,
                type: body.data.groups[i].type,
                donationsQty: body.data.groups[i].donationsQty,
                amount: body.data.groups[i].amount,
                membersTotal: body.data.groups[i].membersTotal,
                address: address,
                lastChatMessage: body.data.groups[i].lastChatMessage,
                lastChatImageUrl: body.data.groups[i].lastChatImageUrl,
                lastChatDateTime: lastChatDateTime,
                targetDate: null
              };
              if (body.data.groups[i].targetDate) {
                gt.targetDate = new Date(body.data.groups[i].targetDate);
              }
              that.groups.push(gt);  // push
            }//  for
            //console.log(that.groups);
            that.storeService.setGroups(that.groups);
            if(body.data.quantity > 0) {
              that.searchMode = false;
              that.recordsFound = true;
            } else {
              that.recordsFound = false;
            }
          } else if (body.error) {
            console.log('error');
            that.connectionError = true;
            that.recordsFound = false;
            that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
            //that.hideLoadingDefault();
            console.log(body.error);
          } else {
            console.log('error2');
            that.recordsFound = false;
          }
        }, function(err) {
          that.connectionError = true;
          that.recordsFound = false;
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          //that.hideLoadingDefault();
        }, function() {
          //that.hideLoadingDefault();
          //console.log('complete');
          that.isGetting = false;

        });  //  service subscribe
    }
  



// might not need this to start
    searchForPerson(person) {
      var that = this;
      that.userService.search( person ).subscribe(
        function(data) {
          that.connectionError = false;
          //console.log(data);
          var body = JSON.parse(data._body);
          ////console.log(body);
          if (body.data && body.data.groups) {
            ////console.log(body.data.groups);
            ////console.log(body.data.quantity);
            var groups = body.data.groups;
            for (let i = 0; i < body.data.quantity; i++) {
              that.groups.push({
                id: body.data.groups[i].id,
                name: body.data.groups[i].name,
                tagline: body.data.groups[i].tagline,
                logoUrl: body.data.groups[i].logoUrl,
                mainImageUrl: body.data.groups[i].mainImageUrl,
                description: body.data.groups[i].description,
                charityNumber: body.data.groups[i].charityNumber,
                code: body.data.groups[i].code,
                status: body.data.groups[i].status,
                showTotalPaymentForMonth: body.data.groups[i].showTotalPaymentForMonth,
                ownedByUserId: body.data.groups[i].ownedByUserId
              });  // push
            }//  for
            that.storeService.setGroups(that.groups);
            if(body.data.quantity > 0) {
              that.searchMode = false;
              that.recordsFound = true;
            } else {
              that.recordsFound = false;
            }
          } else if (body.error) {
            that.connectionError = true;
            that.recordsFound = false;
            that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
            that.hideLoadingDefault();
            console.log(body.error);
          } else {
            that.recordsFound = false;
          }
        }, function(err) {
          that.connectionError = true;
          that.recordsFound = false;
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          that.hideLoadingDefault();
        }, function() {
          that.hideLoadingDefault();

        });  //  service subscribe
    }
  






  browseCharities() {
    this.storeService.setPage('groupsFeatured');
    this.nav.setRoot(GroupsPage);
  }

  createAccount() {
    this.nav.setRoot(AccountPage);
  }  

  getGroupsJoined() {
    var that = this;
    this.userService.getGroupsJoined({ userId: this.user.id }).subscribe(
      function(data) {
        ////console.log(data);
        ////console.log(data._body);
        var body = JSON.parse(data._body);
        ////console.log(body.data);
        //if (body.quantity == 0) body.groupsJoined = null;
        that.storeService.setGroupsJoined(body.data.groupsJoined);
      },
      function(err) {
        //console.log(err);
      }
    )
  }



  groupTapped(event, group) {
    //this.showLoadingDefault({ duration: 2000 });
    this.storeService.setGroup(group);
    this.storeService.setPage('group');
    //console.log(group);
    //console.log(this);
    //console.log(this.nav);
    this.nav.push(GroupPage, {
      group: group
    });
    // gets rid of back button and shows a menu button on left
    /*
    this.nav.setRoot(GroupPage, {
      group: group
    });
    */
  }














  promptWowCode() {
    var that = this;
    let prompt = Alert.create({
      title: 'Search',
      message: "Do you have a WowCode?",
      inputs: [
        {
          name: 'wowCode',
          placeholder: 'WowCode'
        },
      ],
      buttons: [
        {
          text: 'Nope',
          handler: data => {
            that.storeService.setWowCode(0);
            //that.isGetting = false;
          }
        },
        {
          text: 'Yup',
          handler: data => {
            //console.log(data.wowCode);
            that.wowCode = data.wowCode;
            that.storeService.setWowCode(that.wowCode.toUpperCase());
            let navTransition = prompt.dismiss();
            that.getGroup(navTransition);
            
          }
        }
      ]
    });
    that.nav.present(prompt);
  }

  getGroup(navTransition) {
    var that = this;
    var groupId = this.wowCode;
    //that.showLoadingDefault();
    this.groupService.getByCode(groupId).subscribe(
        function(data) {
          //console.log(data);
          var body = JSON.parse(data._body);
          ////console.log(body);
          that.hideLoadingDefault() 
          if (body.data && body.data.groups) {
            //console.log(body.data.groups);
            ////console.log(body.data.quantity);
            var groups = body.data.groups;
            that.storeService.setGroups(groups);
            that.storeService.setGroupsJoined(groups);
            var group = groups[0];

            if(navTransition && navTransition.then) {
              navTransition.then(() => {
                that.nav.push(GroupPage, {
                  group: group
                });
              });
            } else {
              //setTimeout( function() {
                that.nav.push(GroupPage, {
                  group: group
                });
              //}, 1000);
            }
            
            
            
            

          } else if (body.error) {
            //console.log(body.error);
            //that.loginError = body.error.code;
            //console.log('error returned');
          } else {
                //console.log(body);
          }
        }, function(err) {
          //console.log('error', err);
          //if (err) console.log(err._body);
          //var body = JSON.parse(err._body);
          ////console.log(body);
          //that.loginError = 'Error ' + err.status;
          ////console.log(this.userService);
          //that.passwordControl.
        }, function() {
          ////console.log('complete');
        that.isGetting = false;
        //that.hideLoadingDefault();
      });  //  service subscribe

  }

















}
