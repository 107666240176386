




export let FACEBOOK_APP_ID = 591033124433128;
export let APP_NAME = 'WowGive';
export let APP_ID = 1;
export var PERMISSIONS_ADMIN = 100;
export let APP_VERSION = '1.0.8';

export let APP_PLATFORM = 'web'; //  web mobile

//export let BASE_URL = 'http://192.168.1.5:65000/';
//export let WEB_URL = 'http://192.168.1.5:8888/wowgive';
export let BASE_URL = 'https://notifybot-api1.ittradelink.com/';
export let WEB_URL = 'http://www.wowgive.com';

export let SERVER_URL = BASE_URL + '1.0/';	//	on iphone April 30
export let PAYMENT_URL = BASE_URL + 'public/enterccdetails.html';
export let DONATION_URL = BASE_URL + 'public/donate.html';
export let BUCKET_URL = BASE_URL + 'public/';




/*
export let SERVER_URL = 'https://notifybot-api1.ittradelink.com/' + '1.0/';	//	 on device
export let PAYMENT_URL = 'https://notifybot-api1.ittradelink.com/public/enterccdetails.html';	//	 can't be https until main API is https as needs to call it
export let DONATION_URL = 'https://notifybot-api1.ittradelink.com/public/donate.html';
*/


/*
and change
//"proxyUrl": "http://notifybot-api1.ittradelink.com:65000"
    "proxyUrl": "http://localhost"
   */


//export let PAYMENT_URL = 'http://notifybot-bucket1.ittradelink.com/public/enterccdetails.html';	//	 can't be https until main API is https as needs to call it
//possible caching problem, so add param

//export let SERVER_URL = 'https://notifybot-extrapol8.rhcloud.com/' + '1.0/';



/*

targetDate = 1970-01-01T00:00:00.000Z" problem

*/

