import {NavController, NavParams, Platform, Events} from 'ionic-angular';
import {Inject, NgZone,Component, DynamicComponentLoader, Injector} from '@angular/core';

import {SERVER_URL} from '../../config';
import {progressIndicator} from '../../components/progressindicator';

import {PluginsService} from '../../services/plugins-service';
import {ChatService} from '../../services/chat-service';
import {StoreService} from '../../services/store-service';
import {LocalService} from '../../services/local-service';
import {UtilService} from '../../services/util-service';

import {GroupsPage} from '../groups/groups';
import {GroupPage} from '../group/group';
import {AccountPage} from '../account/account';
import {GroupChatPage} from '../group/groupchat';

@Component({
  templateUrl: 'build/pages/latestchat/latestchat.html'
})
export class LatestChatPage {
  utilService: any;
  chatService: any;
  storeService: any;
  localService: any;
  platform: any;
  nav: any;
  icons: any;
  posts: any;
  pageTitle: string;
  user: any;
  oldDate: any;
  oldPaymentMonth: any;
  different: any;
  isGetting: boolean;
  isIOS: boolean;
  group: any;
  groupUser: any;
  mode: string;
  newMessage: string;
  statusMessage: any;
  isSending: boolean;
  events: any;
  zone: any;
  newPost: any;
  subscription: any;
  progress: number;
  injector: any;
  dcl: any;
  images: Array<string> = [];
  imageToSend: any;
  imageClass: string;
  expandedImage: string;
  groupsJoined: any;
  iAmGroupAdmin: boolean;

  constructor(private plugins: PluginsService,
                dcl: DynamicComponentLoader,
                injector: Injector,
                nav: NavController, zone: NgZone, navParams: NavParams, events: Events, chatService: ChatService, storeService: StoreService, localService: LocalService,  utilService: UtilService, platform: Platform) {
    this.nav = nav;
    this.chatService = chatService;
    this.storeService = storeService;
    this.localService = localService;
    this.utilService = utilService;
    this.platform = platform;
    this.pageTitle = 'News';
    this.oldDate = null;
    this.oldPaymentMonth = null
    this.different = false;
    this.isGetting = true;
    this.posts = null;
    this.mode = 'view'
    this.user = this.storeService.getUser();
    this.newMessage = '';
    this.isSending = false;
    this.events = events;
    this.zone = zone;
    this.iAmGroupAdmin = false;

    this.storeService.notification = null;
    
    this.storeService.setPage('groupchat');
    //this.storeService.setPage('posts');
    //console.log(navParams);
    this.group = navParams.get('group');
    this.groupUser = navParams.get('groupUser');
    //console.log('group', this.group);
    this.isIOS = this.platform.is('ios');

    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };
    this.injector = injector;
    this.dcl = dcl;
    //this.componentRef = componentRef;
    this.progress = 0;
    this.imageToSend = null;


    var that = this;

    that.posts = [];

    that.displayPosts();


  }

  ionViewWillLeave() {
    //console.log('ionViewWillLeave');
    this.events.unsubscribe('groupposts:chat', this.subscription);
    this.storeService.setPage('');
  }

  displayPosts() {
    //console.log('displayChat');
    var that = this;
    let query = { action: 'latestOfEach', order: 'desc' };
    //console.log(that);  //ubdefined
    //var that = this;
    //console.log(that);
    that.isGetting = true;
    that.chatService.get(query).subscribe(
      function(data) {
        //console.log(data);
        that.isGetting = false;
        var body = JSON.parse(data._body);
        if (body.data && body.data.posts) {
          ///console.log(body.data.posts);
          //console.log(body.data.quantity);
          //var posts = body.data.posts;
          //console.log(posts);
          
          var posts = [], userColors = [];
          let len = body.data.posts.length;
          //console.log('qty', len);
          let colors = ['a','b','c','d','e','f','g','h','i'];

          var unique = {};
          var distinct = [];
          var c = 0;
          for( var i in body.data.posts ){
            if( typeof(unique[body.data.posts[i].userId]) == "undefined"){
              distinct.push(body.data.posts[i].userId);
              userColors.push(colors[c]);
              c++;
            }
            unique[body.data.posts[i].userId] = 0;
          }
          //console.log(distinct);
          //console.log(userColors);

          for (let i = 0; i < len; i++) {
            var p = {
              id: body.data.posts[i].id,
              message: body.data.posts[i].message,
              messageShort: '',
              dateTime: new Date(body.data.posts[i].dateTime),
              url: body.data.posts[i].url,
              username: body.data.posts[i].username,
              groupName: body.data.posts[i].groupName,
              groupId: body.data.posts[i].groupId,
              groupLogoUrl: body.data.posts[i].groupLogoUrl,
              imageUrl: body.data.posts[i].imageUrl,
              userColor:  userColors[distinct.indexOf(body.data.posts[i].userId)],
              edit: false
            }
            if (body.data.posts[i].message) {
              p.messageShort = body.data.posts[i].message.substr(0,200) + '...';
            }
            posts.push(p);
          }

          that.posts = posts;
          posts = null;
          body = null;
          data = null;

          
        } else if (body.error) {
          console.log(body.error);
          //that.loginError = body.error.code;
          //console.log('error returned');
        } else {
          //console.log(body);
        }
      },
      function(err) {
        console.log('error', err);
        that.isGetting = false;
        //console.log(err._body);
      },
      function() {
        //console.log(that.isGetting);
        that.zone.run(() => {
          //console.log(that.isGetting);
          //console.log('complete');
          that.isGetting = false;
          //console.log(that.isGetting);

        });
      }
    );
  }








formatDate(date) {
  /* if today then say today * time in 24hr
    else day Tue 12:17
    if older than week say short date

  */
  let now = new Date();
  let now2 = now;
  let date2 = date;
  let today0 = now.getFullYear() + now.getMonth() + now.getDate();
  let date0 = date.getFullYear() + date.getMonth() + date.getDate();
  let today = date0 == today0;
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan'];
  let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
  let daysAgo = Math.round((+now - +date) / (60 * 60 * 24 * 1000));
  //console.log(today, daysAgo, now - date, date.getHours(), date.getMinutes());
  let day = days[date.getDay()];
  let time = date.getHours() + ':' + date.getMinutes();
  let output = day + ' ' + time;
  if (today) {
    output = 'Today ' + time;
  } else if(daysAgo > 6) {
    //console.log('more than 6 ');
    //output = date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear() + ' ' + time;
    output = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ' ' + time;
  }
  return output;
}



  monthName(dateTime) {
    return this.utilService.getMonthName(dateTime.getMonth());
  }


  tapPost(post) {
    //console.log(post);
    var group = { id: post.groupId};
    this.storeService.setGroup(group);
   /*this.nav.push(GroupChatPage, {
      group: group,
      groupUser: this.user
    });
    */
   this.nav.push(GroupPage, {
      group: group,
      groupUser: this.user
    });
  }


openPanel(post) {
  //check post is owned by you
  // or you are the group admin
  //console.log('press', post.username, this.user.username, this.group.ownedByUserId, this.user.id);
  if(post.username == this.user.username || this.group.ownedByUserId == this.user.id) {
    post.edit = true;
  }
}

closePanel($event,post) {
  $event.stopPropagation();
  //console.log('close');
  post.edit = false;
}

deletePost(e, post, index) {
  e.preventDefault(); // added for ionic
  //e.stopPropagation();
  //console.log(index);
  var that = this;
  post.edit = false;
  this.chatService.delete(post).subscribe(
        function(data) {
          var body = JSON.parse(data._body);
          //console.log(data);
          that.posts.splice(index, 1);
          },
        function(err) {
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          //console.log('error', err);
          //if (err) //console.log(err._body);
          //var body = JSON.parse(err._body);
          ////console.log(body);
          //that.loginError = 'Error ' + err.status;
        },
        function() {
          //console.log('complete');
        }
      );


}




tapImage($event, post) {
  // max image - change class?
  //console.log(post);
  $event.stopPropagation();
  this.imageClass = 'expanding';
  this.expandedImage = post.imageUrl;
}
closeImage() {
  this.imageClass="";
  //console.log('close');
}
openInExternalBrowser(url)
{
 // Open in external browser
 window.open(url,'_system','location=yes'); 
};
 
openInAppBrowser(url)
{
 // Open in app browser
 window.open(url,'_blank'); 
};
 
openCordovaWebView(url)
{
 // Open cordova webview if the url is in the whitelist otherwise opens in app browser
 //console.log(url);
 window.open(url,'_self'); 
};

}


