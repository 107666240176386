import {NavController, NavParams, Alert} from 'ionic-angular';
import {Inject} from '@angular/core';
import {Component} from '@angular/core';

import {PaymentService} from '../../services/payment-service';
import {StoreService} from '../../services/store-service';
import {UtilService} from '../../services/util-service';
import {UserService} from '../../services/user-service';
import {LocalService} from '../../services/local-service';

import {GroupsPage} from '../groups/groups';
import {AccountPage} from '../account/account';

@Component({
  templateUrl: 'build/pages/payments/recurringpayments.html'
})
export class RecurringPaymentsPage {
  utilService: any;
  paymentService: any;
  storeService: any;
  userService: any;
  localService: any;
  nav: any;
  icons: any;
  recurringPayments: any;
  pageTitle: string;
  user: any;
  oldDate: any;
  oldPaymentMonth: any;
  different: any;
  isProcessing: boolean
  statusMessage: any;
  recurringPaymentStatuses: any;
  mode: string;
  recurringPayment: any;
  isGetting: boolean;
  connectionError: boolean;


/*

select sum(amount), sum(ccTransactionfee) from debits
select sum(amount) from payments
104.95
2.67 3.27

how calculate what to pay charities
need to take off fees, ideally with the 20p etc.

addPayment
userService
geUser
paymentService.chargeCustomerCard
takes via stripe
takeCardPayment inserts into debits and calculates fee
send email - needs to go to charity, not me.
that.sendPaymentToGroup inserts into payments - needs to take off ccfee


addRecurringPayment
paymentService.addRecurring, just inserts into recurringpayments

run recurringPayments
processRecurringPayments
userService.processRecurringPayments
goes through each user, grabs all payments for a user and debits - adds to debits table
user.addPayment
paymentService.chargeCustomerCard
takes via stripe
takeCardPayment
paymentService.sendPaymentToGroup

*/

  constructor(nav: NavController, paymentService: PaymentService, storeService: StoreService, utilService: UtilService, userService: UserService, localService: LocalService ) {
    this.nav = nav;
    this.paymentService = paymentService;
    this.storeService = storeService;
    this.utilService = utilService;
    this.userService = userService;
    this.localService = localService;
    this.pageTitle = 'Monthly Donations';
    this.oldDate = null;
    this.oldPaymentMonth = null
    this.different = false;
    this.isProcessing = false;
    this.connectionError = false;
    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };
    this.recurringPaymentStatuses = ['Cancelled', 'Active', 'Paused'];
    this.mode = 'view';
    this.isGetting = true;

    this.recurringPayments = null;
    this.recurringPayment = {};
    this.user = this.storeService.getUser();
    this.storeService.setPage('recurringpayments');
    
    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };

    if (this.user.then) {
      //console.log('yes this is a promise');
      this.user.then((user) => {
        this.user = user;
        if (user) {
          //console.log(this.user);
          this.displayRecurringPayments();
        } else {
          this.isGetting = false;
        }
      });
    } else {
      //console.log('not promise');
      //console.log(this.user);
      if (this.user) {
      this.displayRecurringPayments();
      }
    }

  }


  displayRecurringPayments() {
    let query = { userId: this.user.id, order: 'desc' };
    var that = this;
    
    this.paymentService.getRecurring(query).subscribe(
      function(data) {
        that.connectionError = false;
        //console.log(data);
        var body = JSON.parse(data._body);
        if (body.data && body.data.recurringPayments) {
          //console.log(body.data.recurringPayments);
          //console.log(body.data.quantity);
          var recurringPayments = body.data.recurringPayments;
          that.recurringPayments = [];
          for (let i = 0; i < body.data.quantity; i++) {
            that.recurringPayments.push({
              id: body.data.recurringPayments[i].id,
              amount: body.data.recurringPayments[i].amount,
              totalPaid: body.data.recurringPayments[i].totalPaid,
              countPaid: body.data.recurringPayments[i].countPaid,
              status: body.data.recurringPayments[i].status,
              statusString: that.recurringPaymentStatuses[body.data.recurringPayments[i].status],
              currencySymbol:  that.storeService.getCurrencySymbol(body.data.recurringPayments[i].currency),
              groupCode: body.data.recurringPayments[i].code,
              groupName: body.data.recurringPayments[i].name,
              dateTimeCreated: new Date(body.data.recurringPayments[i].dateTimeCreated),
              dateTimeCancelled: body.data.recurringPayments[i].dateTimeCancelled ? new Date(body.data.recurringPayments[i].dateTimeCancelled) : null,
            });
            
            //storeService.setpayments(that.recurringPayments);
          }
          if(body.data.quantity == 0) {
            that.recurringPayments = null;
          }
        } else if (body.error) {
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          that.isGetting = false;
          that.connectionError = true;
          //console.log(body.error);
          //that.loginError = body.error.code;
          //console.log('error returned');
        } else {
          //console.log(body);
        }
      },
      function(err) {
        that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
        that.isGetting = false;
        that.connectionError = true;
        //console.log('error', err);
        //console.log(err._body);
        //var body = JSON.parse(err._body);
        ////console.log(body);
        //that.loginError = 'Error ' + err.status;
        ////console.log(this.userService);
        //that.passwordControl.

      },
      function() {
        //console.log('complete');
        that.isGetting = false;
        //console.log(that.recurringPayments);
      }
    );
  }

  browseCharities() {
    this.storeService.setPage('groupsFeatured');
    this.nav.setRoot(GroupsPage);
  }

  createAccount() {
    this.nav.setRoot(AccountPage);
  }
  
  openEditPanel(index) {
    //console.log('edit');
    this.recurringPayments[index].enterNewAmount = true;
  }
  //closeEditPanel(index) {
    ////console.log('close');
    //this.recurringPayments[index].enterNewAmount = false;
  //}

  openEditRecurringPayment(index) {
    this.recurringPayments[index].mode = 'edit';
    this.recurringPayments[index].oldAmount = this.recurringPayments[index].amount;
  }


  checkIfNeedToEnterPin(callbackSuccess, callbackFailure) {
    var that = this;
    var oldDate = new Date(that.user.pinLastEntered).valueOf();
    if (that.user.pinLastEntered) {
      let timeNow = new Date();
      var diff = timeNow.valueOf() - oldDate;
      var diffInHours = diff/1000/60/60;
      var diffInMinutes = diff/1000/60;
      //console.log('diff', diff, diffInHours, diffInMinutes);
      if(diffInHours < 1) {
        callbackSuccess(that);
        return;
      }
    }

    let enterPin = Alert.create({
      title: 'Authentication',
      message: 'Please enter the second and fifth digits of your password',
      inputs: [
        {
          name: 'pin',
          placeholder: '??',
          type: 'password'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
          }
        },
        {
          text: 'Submit',
          handler: data => {
            var params = { pin: data.pin, id: that.user.id };
            that.userService.checkPin(params).subscribe(
              function(data) {
                var body = JSON.parse(data._body);
                if(body.status == 'success') {
                  that.user.pinLastEntered = new Date;
                  that.localService.saveUserLocal(that.user);
                  callbackSuccess(that);
                  return;
                } else {
                   that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.message);
                   callbackFailure(that);
                }
              }
             )
          }
        }
      ]
    });  // let
    that.nav.present(enterPin);
  }




  closeEditRecurringPayment(index) {
    
    delete this.recurringPayments[index].mode;
  }
  saveAmount(recurringPayment, index) {
    //console.log(index, this.recurringPayments[index].amount);
    this.setRecurringPaymentConfirmed(recurringPayment, index, 'changeAmount');
  }
  cancelEditAmount(index) {
    this.recurringPayments[index].enterNewAmount = false;
    this.recurringPayments[index].amount = this.recurringPayments[index].oldAmount;
    //this.closeEditRecurringPayment(index);
    delete this.recurringPayments[index].mode;
  }

  
  setRecurringPayment(recurringPayment, index, action) {
    recurringPayment.index = index;
    recurringPayment.action = action;
    this.recurringPayment = recurringPayment;
    this.recurringPayments[index].enterNewAmount = false;
    this.checkIfNeedToEnterPin(this.okToSetRecurringPayment, function() {});
  }
  okToSetRecurringPayment(that) {
    var recurringPayment = that.recurringPayment;
    var action = recurringPayment.action;
    var index = recurringPayment.index;
    if (action == 0) {
      let confirm = Alert.create({
        title: 'Cancel this monthly donation?',
        message: 'Are you sure you wish to cancel this donation for ever? You can also pause or change the amount.',
        buttons: [
          {
            text: 'No',
            handler: () => {
              //console.log('Disagree clicked');
            }
          },
          {
            text: 'Yes cancel',
            handler: () => {
              //console.log('Agree clicked');
              that.setRecurringPaymentConfirmed(recurringPayment, index, action);
            }
          }
        ]
      });
      that.nav.present(confirm);
    } else {
      that.setRecurringPaymentConfirmed(recurringPayment, index, action);
    }
  }


  setRecurringPaymentConfirmed(recurringPayment, index, action) {
    var that = this;
    var params = { user: { id: that.user.id }, recurringPayment: { id: recurringPayment.id, amount: recurringPayment.amount, status: that.recurringPayments[index].status } };
    if (action != 'changeAmount') {
      params.recurringPayment.status = action;
    } else {
      //params.recurringPayment.amount = recurringPayment.amount;
    }
    that.isProcessing = true;
    that.userService.setRecurringPayment(params).subscribe(
      function(data) {
        //console.log('ok response from server', data);
        var body = JSON.parse(data._body);
        //console.log(body);
        //console.log(body.data);
        if (body.data && body.data.recurringPayments) {
          if (action == 'changeAmount') {
            that.recurringPayments[index].enterNewAmount = false;
            that.utilService.setStatusMessage(that.statusMessage, 'status', 'Donation changed to ' + recurringPayment.currencySymbol + recurringPayment.amount + ' each month from now on');
            that.recurringPayments[index].amount = recurringPayment.amount;
          } else if (action == '2') {
            that.utilService.setStatusMessage(that.statusMessage, 'status', 'This regular payment has been paused. Tap the "play" key when you want to resume');
            that.recurringPayments[index].status = action;
            that.recurringPayments[index].statusString = that.recurringPaymentStatuses[action];
           } else if (action == '0') {
            that.utilService.setStatusMessage(that.statusMessage, 'status', 'This regular payment has been cancelled');
            that.recurringPayments[index].status = action;
            that.recurringPayments[index].statusString = that.recurringPaymentStatuses[action];
          } else {  //  1
            that.utilService.setStatusMessage(that.statusMessage, 'status', 'This regular payment has been resumed');
            that.recurringPayments[index].status = action;
            that.recurringPayments[index].statusString = that.recurringPaymentStatuses[action];
          }
        } else if (body.error) {
          //console.log(body.error);
          that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code)
          //console.log('error returned');
        } else {
          //console.log(body);
        }
      },
      function(err) {
        //console.log('error)', err);
        that.utilService.setStatusMessage(that.statusMessage, 'error', err.status)
      },
      function() {
        that.isProcessing = false;
        delete that.recurringPayments[index].mode;
      }
    );  // subscribe
  }  //  setRecurringPaymentConfirmed


}  //  export
