
import {Component, Input} from '@angular/core';


@Component({
  selector: 'progressIndicator',
  inputs:['progress'],
  template: '<progress max="100" value="{{progress}}" ></progress>'
})
export class progressIndicator {
	@Input() progress: any;
}