import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {Observable} from 'rxjs/Observable';

import {SERVER_URL} from '../config';
import {APP_PLATFORM} from '../config';
import {APP_ID} from '../config';

import {LocalService} from './local-service';
import {StoreService} from './store-service';
import {FileService}  from './file-service';



@Injectable()
export class ChatService {
    http: any;
    storeService: any;
    fileService: any;
    posts: any;
    isDashboard: boolean;

  constructor(http: Http, lacalService: LocalService, storeService: StoreService, fileService: FileService) {
    this.http = http;
    this.storeService = storeService;
    this.fileService = fileService;
    this.posts = {};
    this.isDashboard = APP_PLATFORM == 'web';
  }



  get(query) {
      //console.log(query);
      let url = SERVER_URL + 'chat?';
      if (query.groupId) {
          url = SERVER_URL + 'groups/' + query.groupId + '/chat?';
      }
      if (query.userId) {
          url = SERVER_URL + 'users/' + query.userId + '/chat?';
      }
      if(query.order) {
        url = url + 'order=' + query.order;
      }
      if(query.action) {
        url = url + '&query=' + query.action;
      }
      if(query.groupJoinedId) {
        url = url + '&groupJoinedId=' + query.groupJoinedId;
      }

      if(query.limit) {
        url = url + '&limit=' + query.limit;
      }
      //console.log(url);
      let body = JSON.stringify({});
      //console.log(this);
      return this.http.get(url, { headers: this.storeService.getHeaders() });
  }

  delete(post: any) {
      //console.log('delete', post);
      let url = SERVER_URL + 'chat/' + post.id;
      //console.log(url);

      var payload = { };

      let options = new RequestOptions({ headers: this.storeService.getHeaders() });
      let body = JSON.stringify(payload);
      return this.http.delete(url, options);
  }


  add(data: any, callbackSuccess: any, callbackFailure: any, progressHandler: any, that: any) {
      //console.log(progressHandler);
      let url = SERVER_URL + 'chat';

      let options = new RequestOptions({ headers: this.storeService.getHeaders() });
      let body = JSON.stringify(data);
      return this.http.post(url, body, options);
  }

  addWithImage(data: any, callbackSuccess: any, callbackFailure: any, progressHandler: any, that: any) {
     // console.log(progressHandler);
      //let url = SERVER_URL + 'chat';

      let options = new RequestOptions({ headers: this.storeService.getHeaders() });
      let body = JSON.stringify(data);
     // return this.http.post(url, body, options);

    if(this.isDashboard) {
      //console.log('upload it');
      //this.uploader.uploadAll();
      let url = SERVER_URL + 'postfile';

    var formData = new FormData();
    formData.append("message", data.message);
    formData.append("file",  data.file, data.imageUrl);
    formData.append("toGroupId",  data.toGroupId);
    formData.append("userId",  data.userId);
    formData.append("toGroupName",  data.toGroupName);
    formData.append("action",  'chat');
    //formData.append("imageUrl",  data.imageUrl);

//console.log(formData, url);
//console.log(data.file);

//return Observable.fromPromise(new Promise((resolve, reject) => {
  var promise = new Promise((resolve, reject) => {
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.response))
                } else {
                    reject(xhr.response)
                }
            }
        }
    xhr.open('POST', url, true);
    //xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-access-token', this.storeService.accessToken);
    xhr.setRequestHeader('appid', APP_ID.toString());
    xhr.send(formData);
    });
return promise; //  PromiseObservable.create(promise); // Observable.fromPromise(promise)
//return this.http.post(url, formData, options);
/*
    this.http.post(url, formData,
        {
            headers: options
        });

*/
    } else {
      this.fileService.uploadImage(data, callbackSuccess, callbackFailure, progressHandler, that);
    }


    /*
    var params = {
       toGroupId: this.group.id,
       message: this.newMessage,
       userId: this.user.id,
       toGroupName: this.group.name,
       imageUrl: this.imageToSend
    };



    return this.fileService.uploadImage(data, callbackSuccess, callbackFailure, progressHandler)
      .map( (responseData) => {

      return responseData.json();

    })*/

    
    /*return Observable.create(observer => {
            observer.next(this.fileService.uploadImage(data, progressHandler));
            //observer.complete();
        });
      */  
/*    
    return this.fileService.uploadImage(data, progressHandler).then((data) => {
      console.log('datatatat', data);
      return data;
    });
    */
    /*
    return this.fileService.uploadImage(data, progressHandler).subscribe(
      data => {
        data
      }
    );
    */
    //return this.fileService.uploadImage(data, progressHandler);
      //this.fileService.uploadImage(data, callbackSuccess, callbackFailure, progressHandler);
  }





}// export
