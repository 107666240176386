import {NavController, NavParams, Platform, Events} from 'ionic-angular';
import {Inject, NgZone} from '@angular/core';
import {Component} from '@angular/core';

import {PostService} from '../../services/post-service';
import {StoreService} from '../../services/store-service';
import {LocalService} from '../../services/local-service';
import {UtilService} from '../../services/util-service';

import {GroupsPage} from '../groups/groups';
import {AccountPage} from '../account/account';

import {PERMISSIONS_ADMIN} from '../../config';

@Component({
  templateUrl: 'build/pages/group/groupposts.html'
})
export class GroupPostsPage {
  utilService: any;
  postService: any;
  storeService: any;
  localService: any;
  platform: any;
  nav: any;
  icons: any;
  posts: any;
  pageTitle: string;
  user: any;
  oldDate: any;
  oldPaymentMonth: any;
  different: any;
  isGetting: boolean;
  isIOS: boolean;
  group: any;
  groupUser: any;
  mode: string;
  newMessage: string;
  statusMessage: any;
  isSending: boolean;
  events: any;
  zone: any;
  newPost: any;
  subscription: any;
  canPost: boolean;

  constructor(nav: NavController, zone: NgZone, navParams: NavParams, events: Events, postService: PostService, storeService: StoreService, localService: LocalService,  utilService: UtilService, platform: Platform) {
    this.nav = nav;
    this.postService = postService;
    this.storeService = storeService;
    this.localService = localService;
    this.utilService = utilService;
    this.platform = platform;
    this.pageTitle = 'News';
    this.oldDate = null;
    this.oldPaymentMonth = null
    this.different = false;
    this.isGetting = true;
    this.posts = null;
    this.mode = 'view'
    this.user = this.storeService.getUser();
    this.newMessage = '';
    this.isSending = false;
    this.events = events;
    this.zone = zone;
    this.canPost = false;

    this.storeService.notification = null;
    
    this.storeService.setPage('groupposts');
    //this.storeService.setPage('posts');
    console.log(navParams);
    this.group = navParams.get('group');
    this.groupUser = navParams.get('groupUser');
    if(this.groupUser.permission == PERMISSIONS_ADMIN) {
      this.canPost = true;
    }
    console.log('group', this.group);
    this.isIOS = this.platform.is('ios');

    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };
    var that = this;

    that.displayPosts();
    
    
    that.subscription = that.events.subscribe('groupposts:add', (newPost) => {
      console.log('received new message');
      if (newPost && Array.isArray(newPost)) {
        newPost = newPost[0];  // somehow it's an array now
        console.log(newPost);
        console.log(that.group);
        if(that.group.id == parseInt(newPost.additionalData.g)) {
          that.newPost = newPost;
          that.zone.run(() => {  //   needed or doesn't update untill next cheange detection
            console.log('add', that.newPost.message);
            that.posts.unshift({ message: that.newPost.message, dateTime: new Date(that.newPost.additionalData.dt), id: that.newPost.additionalData.pid, username: that.newPost.additionalData.un});
            console.log(that.posts);
            that.storeService.notification = null;
          });
        }
      }
    });
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave');
    this.events.unsubscribe('groupposts:add', this.subscription);
  }

  displayPosts() {
    console.log('displayPosts');
    var that = this;
    let query = { groupId: that.group.id, order: 'desc' };
    console.log(that);  //ubdefined
    //var that = this;
    //console.log(that);
    that.isGetting = true;
    that.postService.get(query).subscribe(
      function(data) {
        console.log(data);
        var body = JSON.parse(data._body);
        if (body.data && body.data.posts) {
          //console.log(body.data.posts);
          //console.log(body.data.quantity);
          //var posts = body.data.posts;
          //console.log(posts);
          console.log('qty', body.data.quantity);
          
          var posts = [];
          for (let i = 0; i < body.data.quantity; i++) {
            posts.push({
              id: body.data.posts[i].id,
              message: body.data.posts[i].message,
              dateTime: new Date(body.data.posts[i].dateTime),
              url: body.data.posts[i].url,
              username: body.data.posts[i].username,
              imageUrl: body.data.posts[i].imageUrl,
            });
            //console.log(that.posts);
            //storeService.setpayments(that.posts);
          }
          that.posts = posts;
          posts = null;
          body = null;
          data = null;
          
        } else if (body.error) {
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          console.log(body.error);
          //that.loginError = body.error.code;
          //console.log('error returned');
        } else {
          console.log(body);
        }
      },
      function(err) {
        that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
        console.log('error', err);
        //console.log(err._body);
      },
      function() {
        console.log('complete');
        that.isGetting = false;
      }
    );
  }


  cancelSend() {
    this.mode = 'view';
  }
  openCompose() {
    this.mode = 'compose';
  }

  sendMessage() {
    var that = this;
    console.log(this.newMessage);
    var params = {
       toGroupId: this.group.id,
       message: this.newMessage,
       userId: this.user.id,
       toGroupName: this.group.name,
    };

    that.postService.add(params).subscribe(
      function(data) {

        var body = JSON.parse(data._body);
        var status = body.status;
        if (status == 'success') {
          console.log(body);
          var post = body.data.post;

          that.mode = 'view';

          that.posts.unshift({
              id: post.id,
              message: post.message,
              dateTime: new Date(),
              url: post.url,
              username: that.user.username,
              imageUrl: post.imageUrl,
          });
        } else {
          
          //console.log(body);
          var err = body.error.code;
          if(body.error.message) {
            err = body.error.message;
            // if use test card on prod, then get this
          }
          that.utilService.setStatusMessage(that.statusMessage, 'error', err);//  eg   //  eg ER_NO_SUCH_TABLE
        }
      },
      function(err) {
        
        console.log('error)', err);
      },
      function(complete) {
        //console.log('complete donate');
        that.isSending = false;

      }
    );
  }

  monthName(dateTime) {
    return this.utilService.getMonthName(dateTime.getMonth());
  }


  postTapped(event, group) {
/*
    this.storeService.setGroup(group);
    this.nav.push(PostPage, {
      group: group
    });
    // gets rid of back button and shows a menu button on left
*/
  }


openInExternalBrowser(url)
{
 // Open in external browser
 window.open(url,'_system','location=yes'); 
};
 
openInAppBrowser(url)
{
 // Open in app browser
 window.open(url,'_blank'); 
};
 
openCordovaWebView(url)
{
 // Open cordova webview if the url is in the whitelist otherwise opens in app browser
 //console.log(url);
 window.open(url,'_self'); 
};

}


