import {NavController, NavParams, MenuController, Alert, ActionSheet, Loading, Platform} from 'ionic-angular';
import {Component} from '@angular/core';
import {Keyboard} from 'ionic-native';

import {GroupPage} from '../group/group';
import {GroupsPage} from '../groups/groups';


import {StoreService} from '../../services/store-service';
import {GroupService} from '../../services/group-service';
import {UtilService} from '../../services/util-service';
import {LocalService} from '../../services/local-service';




@Component({
  templateUrl: 'build/pages/group/groupcontact.html'
})
export class GroupContactPage {
  nav: any;
  menuController: any;
  storeService: any;
  groupService: any;
  userService: any;
  utilService: any;
  paymentService: any;
  localService: any;
  statusMessage: any;
  isGettingContacts: boolean;
  isGettingContactEmail: boolean;
  isGettingContactText: boolean;
  isGettingFacebook: boolean;
  inviteMessage: any;
  contact: any;
  mailto: string;
  mailtoMessage: string;
  wowCode: string
  groupsJoined: any;
  alertOptions: any;
  group: any;
  user: any;
  loading: any;
  platform: any;
  //groupHandler: any;



  constructor(nav: NavController, platform: Platform, navParams: NavParams, menuController: MenuController, groupService: GroupService, storeService: StoreService, utilService: UtilService, localService: LocalService) {
    this.nav = nav;
    this.platform = platform;
    this.storeService = storeService;
    this.utilService = utilService;
    this.localService = localService;
    this.groupService = groupService;
    this.menuController = menuController;

    this.storeService.setPage('groupContacts');

    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };
    //this.group = 0;

    var that = this;

    this.group = navParams.get('group');
    console.log('nav params----', this.group);
    /*
    if(this.group) {
        that.getGroup(this.group);
      } else {
      this.group = this.storeService.getGroup();
      if(this.group.then) {
        //console.log('yes this is a promise');
        this.group.then((group) => {
          that.getGroup(group);
        });
      } else {
        that.getGroup(this.group);
      }
    }
    */
  }  //constructor



  getGroup(group) {
    var that = this;
    that.showLoadingDefault();

    that.groupService.getById( that.group.id ).subscribe(
        function(data) {
          //console.log(data);
          var body = JSON.parse(data._body);
          ////console.log(body);
          if (body.data && body.data.groups) {
            //console.log(body.data.groups);

            var groups = body.data.groups;
            
            if(groups.length>0) {
              that.group = groups[0];
              console.log(that.group);
            } else {
              that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
              that.hideLoadingDefault();
            }

          } else if (body.error) {
            //console.log(body.error);
            //that.loginError = body.error.code;
            //console.log('error returned');
            that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
             that.hideLoadingDefault();
          } else {
                //console.log(body);
          }
        }, function(err) {
          //console.log('error', err);
          //console.log(err._body);
          //var body = JSON.parse(err._body);
          ////console.log(body);
          //that.loginError = 'Error ' + err.status;
          ////console.log(this.userService);
          //that.passwordControl.
          that.hideLoadingDefault();
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
        }, function() {
          //console.log('complete');
        //that.isGetting = false;
          that.hideLoadingDefault();
      });  //  service subscribe

  }  //  constructor





showLoadingDefault() {
  //console.log('showLoadingDefault');
  let loading = Loading.create({
    content: 'Please wait...',
    spinner: 'circles'
    //dismissOnPageChange: false
  });

  this.nav.present(loading);
  this.loading = loading;
  //console.log('showLoadingDefault2');
}
hideLoadingDefault() {
  //console.log('hide', this.loading);
  if(this.loading) {
    this.loading.dismiss();
  }

}











}// export


