import {NavController, NavParams, Platform} from 'ionic-angular';
import {Inject} from '@angular/core';
import {Component} from '@angular/core';

import {PostService} from '../../services/post-service';
import {StoreService} from '../../services/store-service';
import {LocalService} from '../../services/local-service';
import {UtilService} from '../../services/util-service';

import {GroupsPage} from '../groups/groups';
import {AccountPage} from '../account/account';

@Component({
  templateUrl: 'build/pages/posts/posts.html'
})
export class PostsPage {
  utilService: any;
  postService: any;
  storeService: any;
  localService: any;
  platform: any;
  nav: any;
  icons: any;
  posts: any;
  pageTitle: string;
  user: any;
  oldDate: any;
  oldPaymentMonth: any;
  different: any;
  isGetting: boolean;
  isIOS: boolean;
  statusMessage: any;

  constructor(nav: NavController, postService: PostService, storeService: StoreService, localService: LocalService,  utilService: UtilService, platform: Platform) {
    this.nav = nav;
    this.postService = postService;
    this.storeService = storeService;
    this.localService = localService;
    this.utilService = utilService;
    this.platform = platform;
    this.pageTitle = 'News';
    this.oldDate = null;
    this.oldPaymentMonth = null
    this.different = false;
    this.isGetting = true;
    this.posts = null;
    this.user = this.storeService.getUser();
    this.displayPosts();
    this.storeService.setPage('posts');
    //this.storeService.setPage('posts');

    var platforms = platform.platforms();
    //var isAndroid = platforms.indexOf('android') >= 0 ? true : false;
    this.isIOS = platforms.indexOf('ios') >= 0 ? true : false;
    //console.log('is', this.isIOS);

    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };

  }


  displayPosts() {
    console.log('display);');
    let query = { groupId: 1, order: 'desc' };
    var that = this;
    this.postService.get(query).subscribe(
      function(data) {
        that.isGetting = false;
        console.log(data);
        var body = JSON.parse(data._body);
        if (body.data && body.data.posts) {
          //console.log(body.data.posts);
          //console.log(body.data.quantity);
          var posts = body.data.posts;
          that.posts = [];
          for (let i = 0; i < body.data.quantity; i++) {
            that.posts.push({
              id: body.data.posts[i].id,
              message: body.data.posts[i].message,
              dateTime: new Date(body.data.posts[i].dateTime),
              url: body.data.posts[i].url,
              username: body.data.posts[i].username,
              imageUrl: body.data.posts[i].imageUrl,
            });
            //console.log(that.posts);
            //storeService.setpayments(that.posts);
          }
        } else if (body.error) {
          that.isGetting = false;
          //console.log(body.error);
          //that.loginError = body.error.code;
          //console.log('error returned');
        } else {
          //console.log(body);
        }
      },
      function(err) {
        that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
        that.isGetting = false;
        //console.log('error', err);
        //console.log(err._body);
      },
      function() {
        //console.log('complete');
        that.isGetting = false;
      }
    );
  }



  monthName(dateTime) {
    return this.utilService.getMonthName(dateTime.getMonth());
  }


  postTapped(event, group) {
/*
    this.storeService.setGroup(group);
    this.nav.push(PostPage, {
      group: group
    });
    // gets rid of back button and shows a menu button on left
*/
  }


openInExternalBrowser(url)
{
 // Open in external browser
 window.open(url,'_system','location=yes'); 
};
 
openInAppBrowser(url)
{
 // Open in app browser
 window.open(url,'_blank'); 
};
 
openCordovaWebView(url)
{
 // Open cordova webview if the url is in the whitelist otherwise opens in app browser
 console.log(url);
 window.open(url,'_self'); 
};

}


