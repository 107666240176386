import {NavController, NavParams} from 'ionic-angular';
import {Inject} from '@angular/core';
import {Component} from '@angular/core';

import {PaymentService} from '../../services/payment-service';
import {StoreService} from '../../services/store-service';
import {UtilService} from '../../services/util-service';

import {GroupsPage} from '../groups/groups';
import {AccountPage} from '../account/account';

@Component({
  templateUrl: 'build/pages/payments/payments.html'
})
export class PaymentsPage {
  utilService: any;
  paymentService: any;
  storeService: any;
  nav: any;
  icons: any;
  payments: any;
  pageTitle: string;
  user: any;
  oldDate: any;
  oldPaymentMonth: any;
  different: any;
  isGetting: boolean;
  statusMessage: any;
  connectionError: boolean;

  constructor(nav: NavController, paymentService: PaymentService, storeService: StoreService, utilService: UtilService) {
    this.nav = nav;
    this.paymentService = paymentService;
    this.storeService = storeService;
    this.utilService = utilService;
    this.pageTitle = 'My donations';
    this.oldDate = null;
    this.oldPaymentMonth = null
    this.different = false;
    this.isGetting = true;
    this.connectionError = false;

    this.payments = null;
    this.user = this.storeService.getUser();
    this.storeService.setPage('payments');
    
    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };

    if (this.user.then) {
      //console.log('yes this is a promise');
      this.user.then((user) => {
        this.user = user;
        if (user) {
          //console.log(this.user);
          this.displayPayments();
        } else {
          this.isGetting = false;
        }
      });
    } else {
      //console.log('not promise');
      //console.log(this.user);
      if (this.user) {
        this.displayPayments();
      }
    }

  }


  displayPayments() {
    let query = { userId: this.user.id, order: 'desc' };
    var that = this;
    this.paymentService.get(query).subscribe(
      function(data) {
        that.connectionError = false;
        //console.log(data);
        var body = JSON.parse(data._body);
        if (body.data && body.data.payments) {
          //console.log(body.data.payments);
          //console.log(body.data.quantity);
          var payments = body.data.payments;
          that.payments = [];
          for (let i = 0; i < body.data.quantity; i++) {
            that.payments.push({
              id: body.data.payments[i].id,
              amount: body.data.payments[i].amount,
              currency: body.data.payments[i].currency,
              groupCode: body.data.payments[i].code,
              groupName: body.data.payments[i].name,
              dateTime: new Date(body.data.payments[i].dateTime),
              recurringPaymentId: body.data.payments[i].recurringPaymentId,
            });
            //storeService.setpayments(that.payments);
          }
        } else if (body.error) {
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          that.isGetting = false;
          that.connectionError = true;
          //console.log(body.error);
          //that.loginError = body.error.code;
          //console.log('error returned');
        } else {
          //console.log(body);
        }
        if(body.data.quantity == 0) {
          that.payments = null;
        }
      },
      function(err) {
        that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
        that.isGetting = false;
        that.connectionError = true;
        //console.log('error', err);
        //console.log(err._body);
        //var body = JSON.parse(err._body);
        ////console.log(body);
        //that.loginError = 'Error ' + err.status;
        ////console.log(this.userService);
        //that.passwordControl.

      },
      function() {
        //console.log('complete');
        that.isGetting = false;
      }
    );
  }



  browseCharities() {
    this.storeService.setPage('groupsFeatured');
    this.nav.setRoot(GroupsPage);
  }

  createAccount() {
    this.nav.setRoot(AccountPage);
  }

  monthName(dateTime) {
    return this.utilService.getMonthName(dateTime.getMonth());
  }

/*
  datesAreDifferent(dateTime) {
    var date = dateTime.setHours(0, 0, 0, 0);
    //console.log(date, this.oldDate);
    if (date != this.oldDate) {
      this.oldDate = date;
      return '1';
    }
    this.oldDate = date;
  }

  checkMonth(dateTime) {
    var paymentMonth = dateTime.getMonth();
    var oldMonth = this.oldPaymentMonth;
    this.oldPaymentMonth = paymentMonth;
    return oldMonth;
  }

  monthIsDifferent(dateTime) {
    var paymentMonth = dateTime.getMonth();
    //console.log(paymentMonth, this.oldPaymentMonth);
    this.different = false;
    if (paymentMonth != this.oldPaymentMonth) {
      //return 1; //this.utilService.getMonthName(dateTime.getMonth());
      this.different = true;
    }
    this.oldPaymentMonth = paymentMonth;
    //this.cdr.detectChanges();
    return this.different;
  }
 
  dateCheck(postDateTime) {
    var currentDateTime = new Date();
    var currentDate = currentDateTime.setHours(0, 0, 0, 0);
    var yesterday = currentDate - 1000 * 24 * 60 * 60;
    var postDate = new Date(postDateTime).setHours(0, 0, 0, 0);
    var postDateFormatted = $filter('date')(postDateTime, 'shortDate');
    if (postDate == currentDate) {
      return $filter('date')(postDateTime, 'h:mm a').toLowerCase();
    } else if (postDate == yesterday) {
      return 'yesterday';
    } else {
      return $filter('date')(postDate, 'EEE');
    }
  }
  */

  groupTapped(event, group) {
    /*
    this.storeService.setGroup(group);
    this.nav.push(GroupPage, {
      group: group
    });
    */
  }
}
