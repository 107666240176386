import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs/Observable';
import {SERVER_URL} from '../config';

import {LocalService} from './local-service';
import {StoreService} from './store-service';

@Injectable()
export class PostService {
    http: any;
    storeService: any;
    posts: any;

  constructor(http: Http, lacalService: LocalService, storeService: StoreService) {
    this.http = http;
    this.storeService = storeService;
    this.posts = {};
  }



  get(query) {
      console.log(query);
      let url = SERVER_URL + 'posts';
      if (query.groupId) {
          url = SERVER_URL + 'groups/' + query.groupId + '/posts?';
      }
      if(query.order) {
        url = url + 'order=' + query.order;
      }
      console.log(url);
      let body = JSON.stringify({});
      //console.log(this);
      return this.http.get(url, { headers: this.storeService.getHeaders() });
  }


  add(data) {
      //console.log(query);
      let url = SERVER_URL + 'posts';

      let options = new RequestOptions({ headers: this.storeService.getHeaders() });
      let body = JSON.stringify(data);
      //console.log(this);
      return this.http.post(url, body, options);
  }



}// export
