import {Headers} from '@angular/http';
import {Injectable} from '@angular/core';
import {Events} from 'ionic-angular';

import {LocalService} from './local-service';
import {APP_ID} from '../config';

@Injectable()

export class StoreService {
    user: any;
    group: any;
    groups: any;
    groupsJoined: any;
    page: any;
    inviteMessage: any;
    headers: any;
    localService: any;
    events: any;
    gettingUserFromLocal: boolean;
    wowcode: string;
    popups: any;
    notification: any;
    scope: any;
    accessToken: string;

    constructor(localService: LocalService, events: Events) {
        //this.user = {};
        //this.user.firstName = 'Blank';
        //this.user.lastName = 'Name';
        this.gettingUserFromLocal = false;
        this.group = null;
        this.groups = null;
        this.groupsJoined = null;
        this.headers = {};
        this.setHeaders('notRegisteredYet');
        this.localService = localService;
        this.events = events;
        this.inviteMessage = '';
        this.popups = [];
        //this.getUser();

       // this.events.subscribe('user:got', (user) => {
         //   //console.log('8989', user);
            //if (user.id)
           // this.showAccount();
        //});

    }

    getHeaders() {
        return this.headers;
    }
    setHeaders(access_token) {
        this.headers = new Headers({ 'Content-Type': 'application/json', 'x-access-token': access_token, 'appid': APP_ID });
        this.accessToken = access_token;
    }
    
    getCurrencySymbol(currency) {
            if(currency == 'usd') return '$';
            if(currency == 'eur') return '€';
            if(currency == 'gbp') return '£';
    }



    getUser() {
        //console.log('getUser', this.user);
        if (this.user && !this.user.then) {
            //console.log('publishing user from store1');
            this.events.publish('user:got', this.user);
            return this.user;
        }
        //} else {
        //console.log(this.gettingUserFromLocal);
        if (this.gettingUserFromLocal == false) {

            //console.log('no user in store, so will ask local');
            var that = this;
            this.gettingUserFromLocal = true;
            return this.localService.getUser().then((user) => {
                that.gettingUserFromLocal = false;
                //console.log('got user from local', user);
                that.user = user;
                //console.log('publishing user from local1');
                this.events.publish('user:got', user);
                return user;
            });
        } else {
            return 1;
        }
        //}
    }

    setUser(user) {
        //console.log('setUser---', JSON.stringify(user), user);
        this.user = user;
        this.localService.saveUserLocal(user);
    }

  

    setUserName(firstName, lastName) {
        this.user.firstName = firstName;
        this.user.lastName = lastName;
    }

    getUserName() {
        return this.user.firstName + ' ' + this.user.lastName;
    }

    getGroups() {
        return this.groups;
    }
    setGroups(groups) {
        this.groups = groups;
    }

    getGroup() {
        //console.log('store', JSON.stringify(this.group));
        if (this.group) {
            //console.log('publishing group from store1');
            this.events.publish('group:got', this.group);
            return this.group;
        }
        var that = this;
        return this.localService.getGroup().then((group) => {
            //console.log('got from local', group);
            that.group = group;
            //console.log('publishing user from local1');
            this.events.publish('group:got', group);
            return group;
        });
    }


    setGroup(group) {
        this.group = group;
        this.localService.saveGroup(group);
    }

    getGroupsJoined() {
        //console.log('getGroupsJoined in store', this.groupsJoined);
        if (this.groupsJoined) {
            //console.log('publishing groupsJoined from store1');
            this.events.publish('groupsJoined:got', this.groupsJoined);
            return this.groupsJoined;
        }
        var that = this;
        return this.localService.getGroupsJoined().then((groupsJoined) => {
            //console.log('got groupsJoined from local', groupsJoined);
            that.groupsJoined = groupsJoined;
            //console.log('publishing groupsJoined from local1');
            this.events.publish('groupsJoined:got', groupsJoined);
            return groupsJoined;
        });
    }
    setGroupsJoined(groupsJoined) {
        this.groupsJoined = groupsJoined;
        this.localService.saveGroupsJoined(groupsJoined);
    }






    getPage() {
        //console.log('store', this.page);
        //if (this.page) {
            ////console.log('publishing group from store1');
            //this.events.publish('group:got', this.group);
          //  return this.page;
        //} else {
            return this.localService.getPage('page');
            //return null;
        //}
    }

    setPage(page) {
        this.page = page;
        this.localService.setPage(page);
        //console.log('settingPage--------', page);
        //this.localService.saveGroup(group);
    }





    getInviteMessage() {
       return this.localService.getInviteMessage();
    }

    setInviteMessage(message) {
        this.inviteMessage = message;
        this.localService.setInviteMessage(message);
        //console.log('settingInviteMessage--------', message);
    }



    getWowCode() {
       return this.localService.getWowCode();
    }

    setWowCode(wowcode) {
        this.wowcode = wowcode;
        this.localService.setWowCode(wowcode);
        ////console.log('setting wowcode--------', wowcode);
    }





}