import {NavController, NavParams, MenuController, App, Alert, ActionSheet, Loading, Platform} from 'ionic-angular';
import {Component} from '@angular/core';
import {Keyboard} from 'ionic-native';
import {ViewChild} from '@angular/core';
import {Nav} from 'ionic-angular';

import {GroupPage} from '../group/group';
import {GroupsPage} from '../groups/groups';
import {LatestChatPage} from '../latestchat/latestchat';

import {StoreService} from '../../services/store-service';
import {GroupService} from '../../services/group-service';
//import {UserService} from '../../services/user-service';
import {UtilService} from '../../services/util-service';
import {LocalService} from '../../services/local-service';


declare const cordova: any;


@Component({
  templateUrl: 'build/pages/entercode/entercode.html'
})
export class EnterCodePage {
  nav: any;
  app: any;
  menuController: any;
  storeService: any;
  groupService: any;
  userService: any;
  utilService: any;
  paymentService: any;
  localService: any;
  statusMessage: any;
  isGettingContacts: boolean;
  isGettingContactEmail: boolean;
  isGettingContactText: boolean;
  isGettingFacebook: boolean;
  inviteMessage: any;
  contact: any;
  mailto: string;
  mailtoMessage: string;
  wowCode: string
  groupsJoined: any;
  alertOptions: any;
  group: any;
  user: any;
  loading: any;
  platform: any;

//@ViewChild(Nav) nav: Nav;

  constructor(nav: NavController, platform: Platform, navParams: NavParams, menuController: MenuController, app: App, groupService: GroupService, storeService: StoreService, utilService: UtilService, localService: LocalService) {
    this.nav = nav;
    this.platform = platform;
    this.storeService = storeService;
    this.utilService = utilService;
    this.localService = localService;
    this.groupService = groupService;
    this.app = app;
    this.menuController = menuController;

    this.storeService.setPage('enterCode');

    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };
    this.group = 0;

    var that = this;

    this.platform.ready().then(() => {
      //cordova.plugins.Keyboard.disableScroll(true);
      Keyboard.disableScroll(true);
    });

  }  //  constructor

  explore() {
    this.storeService.setWowCode(0);
    this.storeService.setPage('latestChat');
    this.nav.setRoot(LatestChatPage, { page: 'latestChat' } );
  }

  browse() {
    this.storeService.setWowCode(0);
    //let nav = this.app.getComponent('nav');
    this.storeService.setPage('groupsFeatured');
    this.nav.setRoot(GroupsPage, { page: 'groupsFeatured' } );
  }

  search() {
    this.storeService.setWowCode(0);
   // let nav = this.app.getComponent('nav');
    this.storeService.setPage('groupsSearch');
    this.nav.setRoot(GroupsPage, { page: 'groupsSearch' } );
  }


  clickWowCode() {
    var that = this;
    console.log(this.wowCode);
    console.log(this.storeService);
    console.log(this.storeService.setWowCode);
    console.log(this.storeService.setWowCode());
    if(that.wowCode == undefined) {
      return;
    }
    that.storeService.setWowCode(this.wowCode.toUpperCase());
    var groupId = that.wowCode;
    that.showLoadingDefault();
    that.groupService.getByCode(groupId).subscribe(
      function(data) {
        that.hideLoadingDefault();
        //console.log(data);
        var body = JSON.parse(data._body);
        ////console.log(body);
        if (body.data && body.data.groups) {
          //console.log(body.data.groups);
          ////console.log(body.data.quantity);
          var groups = body.data.groups;
          that.storeService.setGroups(groups);
          that.storeService.setGroupsJoined(groups);
          var group = groups[0];
          //console.log(group);
        //  let nav = that.app.getComponent('nav');
          that.nav.setRoot(GroupPage, {group: group} );

        } else if (body.error) {
          that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code);
          that.storeService.setWowCode(0);
          //console.log(body.error);

        } else {
          that.storeService.setWowCode(0);
              console.log(body);
              if(body.status == 'failure') {
                that.utilService.setStatusMessage(that.statusMessage, 'error', "Sorry, we can't find that WowCode");
              } else {
                that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry there seems to be a connection problem');
              }
        }
      }, function(err) {
        that.hideLoadingDefault();
        that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry there seems to be a connection problem');
        that.storeService.setWowCode(0);
        //console.log('error', err);
        //if (err) console.log(err._body);
        //var body = JSON.parse(err._body);
        ////console.log(body);
        

      }, function() {
        console.log('complete')
        that.hideLoadingDefault();
    });  //  service subscribe

  }





showLoadingDefault() {
  //console.log('showLoadingDefault');
  let loading = Loading.create({
    content: 'Please wait...',
    spinner: 'circles'
    //dismissOnPageChange: false
  });

  this.nav.present(loading);
  this.loading = loading;
  //console.log('showLoadingDefault2');
}
hideLoadingDefault() {
  //console.log('hide', this.loading);
  if(this.loading) {
    this.loading.dismiss();
  }

}











}// export


