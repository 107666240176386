import {App, Platform, Storage, SqlStorage, Events, MenuController, ionicBootstrap} from 'ionic-angular';
import {Keyboard, Push, Device, StatusBar} from 'ionic-native';
import {Inject, NgZone, enableProdMode} from '@angular/core';
import {Component} from '@angular/core';
import {ViewChild} from '@angular/core';
import {Nav} from 'ionic-angular';

import {PERMISSIONS_ADMIN} from './config';
import {PluginsService} from './services/plugins-service';


import {APP_VERSION} from './config';
import {APP_PLATFORM} from './config';

import {LocalService} from './services/local-service';
import {UserService} from './services/user-service';
import {PaymentService} from './services/payment-service';
import {StoreService} from './services/store-service';
import {ValidationService} from './services/validation-service';
import {GroupService} from './services/group-service';
import {UtilService} from './services/util-service';
import {PostService} from './services/post-service';
import {ChatService} from './services/chat-service';
import {FileService} from './services/file-service';

//import {RootPage} from './pages/root/root';
import {HomePage} from './pages/home/home';
import {GroupsPage} from './pages/groups/groups';
import {GroupUsersPage} from './pages/group/groupusers';
import {FavesPage} from './pages/faves/faves';
import {AccountPage} from './pages/account/account';
import {PaymentsPage} from './pages/payments/payments';
import {RecurringPaymentsPage} from './pages/payments/recurringpayments';
import {PaymentsReceivedPage} from './pages/payments/payments-received';
import {RemotePage} from './pages/remote/remote';
import {PostsPage} from './pages/posts/posts';
import {InvitePage} from './pages/invite/invite';
import {EnterCodePage} from './pages/entercode/entercode';
import {GroupContactPage} from './pages/group/groupcontact';
import {GroupPage} from './pages/group/group';
import {GroupPostsPage} from './pages/group/groupposts';
import {GroupChatPage} from './pages/group/groupchat';
import {GroupEditPage} from './pages/group/groupedit';
import {LatestChatPage} from './pages/latestchat/latestchat';
import {GroupTweetPage} from './pages/group/grouptweet';



enableProdMode();




@Component({
  templateUrl: 'build/app.html',
})
export class WowGive {
  app: any;
  platform: any;
  device: any;

  storeService: any;
  localService: any;
  pages: any;
  rootPage: any;
  user: any;
  events: any;
  userService: any;
  groupsJoined: any;
  menu: any;
  isAndroid: boolean;
  isIOS: boolean;
  isDashboard: boolean;
  zone: any;
  gotFreshPushToken: boolean;
  notification: any;
  appStarted: boolean;
  startedByNotification: boolean;
  status: string;
  appVersion: string;

@ViewChild(Nav) nav: Nav;

  constructor(zone: NgZone, platform: Platform, storeService: StoreService, localService: LocalService, events: Events, userService: UserService, menu: MenuController, app: App) {

    this.app = app;
    this.platform = platform;
    //this.device = device;
    this.menu = menu;
    this.zone = zone;
    this.gotFreshPushToken = false;
    this.notification = {};
    this.appStarted = false;
    this.startedByNotification = false;
    //this.app.getComponent('nav').pop();
    this.userService = userService;
    this.storeService = storeService;
    this.localService = localService;
    this.groupsJoined = null;
    //this.groupService = groupService;
    this.events = events;
    //this.localService = LocalService;
    //this.nav = this.app.getComponent('nav');
    this.isAndroid = this.platform.is('android');
    this.isIOS = this.platform.is('ios');
    this.isDashboard = APP_PLATFORM == 'web';
    this.status = 'normal';

    this.appVersion = APP_VERSION;
   
    console.log(this.appVersion);

    var that = this;

    //console.log(this.platform);
    
    this.pages = this.buildLeftMenu();

    document.addEventListener('resume', () => {
        this.status = 'resumed';
        //console.log('app resume');
        // ideally if received notification then show something, maybe in header
        //tell gou page done donation??
        //var that = this;
        var tt = this;
        //console.log(tt);
        this.storeService.getPage().then((page) => {
          //console.log('page', page);
          //console.log(that);
          //console.log(this);
          
          if(page == 'group') {  //  assume restarting app back from a donation
             // console.log(tt);

            //  tt.storeService.getGroup().then((group) => {
                //setTimeout( function() {
                this.zone.run(() => { 
                  tt.events.publish('reloadGroupPage');
                //}, 500);
              });
            }
          });


    });
    document.addEventListener('pause', () => {
        //console.log('app pause');
    });


    this.storeService.getUser().then((user) => {
      //console.log(user);
      //console.log('this.storeService.getUser().then((use0');
      that.user = user;
      if (user) {
        that.storeService.getGroupsJoined().then((groupsJoined) => {
          that.groupsJoined = groupsJoined;
          this.events.publish('userLocal:got', that.user);
        });
      } else {
        this.events.publish('userLocal:got', that.user);
      }
    });


    this.events.subscribe('user:got', (user) => {
      //console.log('App.js got user', user);
      if (user && Array.isArray(user)) {
        user = user[0];
        that.user = user;
        //console.log('app.js has correct user', user)

        //only do this onece when first start;
        //console.log('that.gotFreshPushToken', that.gotFreshPushToken);
        if(!that.gotFreshPushToken) {
          that.registerPush();
        }

      } else {
        user = that.userService.new();
        //console.log('app.js created new user', user);
      }

      if (user && user.id) {
        //console.log('push profile');
        that.pages.pop();
        that.pages.push( { title: 'My profile', component: AccountPage, params: { page: 'account' } });

       } else {
          //console.log('push register');
          that.pages.pop();
          that.pages.push( { title: 'Register/Login', component: AccountPage, params: { page: 'account' } });
       }

      //this.storeService.setUser(user);
    });

    this.events.subscribe('groupsJoined:got', (groupsJoined) => {
      //console.log('App.js got groupsJoined', groupsJoined);
      that.pages = that.buildLeftMenu();  //   reset left Menu without the admin pages as not admin of a group if not a member
      if (groupsJoined && Array.isArray(groupsJoined)) {
        groupsJoined = groupsJoined[0];  //   wrapped with array from subscribing
        if (groupsJoined && groupsJoined.length > 0) {
          //console.log('arry', groupsJoined.length, groupsJoined);
          // am I the admin of any of these?
          //console.log(that.pages[5]);
          if(that.pages[5].title != 'Donations received' ) {
            for(var i = 0; i < groupsJoined.length; i ++) {
              //console.log(i, groupsJoined[i].permission, PERMISSIONS_ADMIN);
              if (groupsJoined[i].permission == PERMISSIONS_ADMIN) {
                that.pages.splice(5, 0, { title: 'Donations received', component: PaymentsReceivedPage, params: { page: 'paymentsReceived' } });
                break;
              }
              
            }
          }
        }
      }
    });

    this.events.subscribe('userLocal:got', (user) => {
      //console.log('App.js got userLocal', user);
      if (user && Array.isArray(user)) {
        user = user[0];
        that.user = user;
        //console.log('app.js has correct userLocal', user)
      } else {
        user = that.userService.new();
        //console.log('app.js created new userLocal', user);
      }
      //console.log('user got that.appStarted', that.appStarted, that.startedByNotification);

      if(!that.appStarted && !that.startedByNotification) {
        // only change page if not from notification
        // if notification, then already gone to page from on.notification in register push
        this.appStarted = true;  //  app starting the normal way - not from notification

       // that.nav = that.app.getComponent('nav');


        this.storeService.getPage().then((page) => {
          //console.log('page', page);
          
          if(page == 'posts') {  //  assume restarting app back from a news article, so go back to news page
            that.nav.setRoot(PostsPage);
          //} else if(page == 'group') {  //  assume restarting app back from a donation
            // only need this for browser, as IOS handles this through resume
             //   that.storeService.getGroup().then((group) => {
               // that.nav.setRoot(GroupPage, {group:group} );
            
          } else {
            // assume app is starting from fresh
            //console.log('thinks got ------');
            // check if got a wowcode, which means that user already has at least one fave
            
            //if(!this.notification.message) {

              this.storeService.getWowCode().then((wowCode) => {
                //console.log(wowCode);
                if(this.isDashboard) {
                  if(that.user && that.user.id) {
                    if( that.groupsJoined && that.groupsJoined.length > 0) {
                      that.storeService.setPage('home');
                      that.nav.setRoot(HomePage, { page: 'home' });
                    } else {
                      that.storeService.setPage('latestChat');
                      that.nav.setRoot(LatestChatPage, { page: 'latestChat' });
                    }
                  } else {
                    that.storeService.setPage('account');
                    that.nav.setRoot(AccountPage, { page: 'account' });
                  }
                } else {
                  if(wowCode) {
                    if(that.user && that.groupsJoined && that.groupsJoined.length > 0) {
                      // if logged in user and got faves then show faves
                      //console.log('thinks got groupsjoined', that.groupsJoined);
                      //that.storeService.setPage('groupsJoined');
                      //that.nav.setRoot(GroupsPage, { page: 'groupsJoined' });
                      that.storeService.setPage('home');
                      that.nav.setRoot(HomePage, { page: 'home' });
                    } else if(wowCode && wowCode !='0') {
                      //console.log('thinks got wowcode', wowCode);
                      // if enetered a wowcode then show that fave
                      //that.storeService.setPage('groupsJoined');
                      //that.nav.setRoot(GroupsPage, { page: 'groupsJoined' });
                      that.storeService.setPage('home');
                      that.nav.setRoot(HomePage, { page: 'home' });
                    } else {
                      // no faves, so show latest posts from all
                      //that.storeService.setPage('groupsFeatured');
                      //that.nav.setRoot(GroupsPage, { page: 'groupsFeatured' });
                      that.storeService.setPage('latestChat');
                      that.nav.setRoot(LatestChatPage, { page: 'latestChat' });
                      //that.storeService.setPage('home');
                      //that.nav.setRoot(HomePage, { page: 'home' });
                    } //   that.user
                  //that.nav.setRoot(GroupsPage);
                  //that.nav.setRoot(Home);
                  } else {
                    that.nav.setRoot(EnterCodePage);
                  } // wowcode
                } // platform

              });
            
            
            //that.storeService.setPage('groupsFeatured');
            //that.nav.setRoot(GroupsPage);
          }
        });
      }
    });






    this.platform.ready().then(() => {
      //console.log('ready....');

      if (StatusBar) {
        StatusBar.show();
        if(this.isAndroid) {
          StatusBar.backgroundColorByHexString("#888888");   //  styleDefault(); #f8f8f8
        } else {
          StatusBar.styleLightContent();
        }   
      }    


      if(this.isAndroid) {
        platform.backButton.subscribe(function () {
          // do a search for platform.exitApp(); and comment out all these and nav.pop()
          //console.log('back tapped', that.storeService.popups.length);
          if(that.storeService.popups.length==0) {
            var activeNav = that.nav;
            if (activeNav) {
              // need to check if enterwowcode
              if (activeNav.length() === 1) {
                if(that.menu.isOpen()) {
                 // console.log('exit');
                  platform.exitApp();
                } else {
                  that.storeService.getPage().then((page) => {
                    if(page == 'enterCode') {
                      platform.exitApp();
                    } else {
                      //console.log('open');
                      that.menu.open();
                    } //  if
                  }); //that
                } //   if menu isOpen
              } else {  //  activeNav.length() is > 1) {
                //console.log('pop');
                that.nav.pop();
                //console.log(activeNav);
               // console.log(activeNav.length(), activeNav._views.length);
              }   //  if (activeNav.length() === 1) {
            }   //   if activeNav
          }   //  if(that.storeService.popups.length==0) {
        }); //  platform.backButton.subscribe(function () {
      } //  if(this.isAndroid) {



      /*
      this.platform.registerBackButtonAction = function(e) {  //   doesn't work yet
        //console.log('registerBackButtonAction');
        e.preventDefault();
      }

      

      document.addEventListener('backbutton', () => {
        //console.log(this.nav, this.nav.canGoBack());
        ////console.log(app, this.app);
        ////console.log('nav', this.nav);
        if (!this.nav.canGoBack()) {
          //console.log('cant go back');
          //this.platform.exitApp();  //doesn't work
          //this.app.exitApp();// not a function
          return;
        }
        this.nav.pop()
      }, false);

      // First, let's hide the keyboard accessory bar (only works natively) since
      // that's a better default:

      */
    });

    //this.storeService.setGroupsJoined(null);

    //this.rootPage = GroupsPage;
    ////console.log(this.rootPage);
    //this.initializeApp(this.rootPage);
      
  
  }




















registerPush() {
  var that = this;
  //console.log(this);
  //console.log('registerPush');
  this.platform.ready().then(() => {
    //console.log('ready.again..in register push.');

    if(this.isIOS) {

    }

    if(that.user && that.user.id) {
      //https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/PAYLOAD.md
      var push = Push.init({
         android: {
             senderID: "3401364148",
             iconColor: "green"
         },
         ios: {
             alert: "true",
             badge: "true",
             sound: "true",
             clearBadge: "true"
         },
         windows: {}
      });
      //console.log(push);
      //console.log(Push);

      if(push && !push['error']) {
        push.on('registration', (data) => {
            //console.log('registration succeeded', data.registrationId);
            that.gotFreshPushToken = true;
            /*if(that.isIOS) {
              var deviceFamily = 'ios';
            } else {
              var deviceFamily = 'android';
            }*/
            var device = device || {};  //   stop typescript errors ' device not defined'
            //console.log(that.device); //   this doesn't work
            //console.log(device);
            //console.log(Device);
            /*
            var devicePlatform = device.platform;
            var deviceVersion = device.version;
            var deviceManufacturer = device.manufacturer;
            var deviceModel = device.model;
*/
            var devicePlatform = Device.device.platform;
            var deviceVersion = Device.device.version;
            var deviceManufacturer = Device.device.manufacturer;
            var deviceModel = Device.device.model;
            //console.log('model', Device.model());

            //isVirtual, platform, serial, uuid

            //console.log('will now save user');
            that.userService.save({ id: that.user.id, pushToken: data.registrationId, devicePlatform: devicePlatform , deviceVersion: deviceVersion, deviceManufacturer: deviceManufacturer, deviceModel: deviceModel}).subscribe(
              function(data) {
                //console.log('ok response from server', data);

           
                //console.log(data.status); //  200
                var body = JSON.parse(data._body);
                //console.log(body);
                //console.log(body.data);
                if(body.data && body.data.user) {
                  //console.log('ok');
                } else if(body.error) {
                  //console.log(body.error);
                  //that.saveError = body.error.code;
                  //that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code)
                  //console.log('error returned');
                } else {
                  //console.log(body);
                }
              },
              function(err) {
                //console.log('error)', err);
                //that.saveError = err.status;
                //that.utilService.setStatusMessage(that.statusMessage, 'error', err.status)
              
              },
              function() {
                //console.log('complete');
              }
            );


        });// push.on 'reg'

        push.on('notification', (data) => {
          /*
            console.log('notification', data.message);
            console.log(data.title);
            console.log(data.count);
            console.log(data.sound);
            console.log(data.image);
            console.log(data.additionalData);
            console.log(data);
            */
            if(data.additionalData) {
              if(data.additionalData['z']) {
                if(data.additionalData['z'] == 1) {
                  data['command'] = 'post';
                } else if(data.additionalData['z'] == 2) {
                  data['command'] = 'chat';
                }
              }
              if(data.additionalData['g']) {
                data['groupId'] = data.additionalData['g'];
              }
              
            }
            that.zone.run(() => {  //   needed or doesn't update untill next cheange detection
              that.storeService.notification = data;
            });

            if(data.additionalData['z']) {
              if((data['command'] == 'post') || (data['command'] == 'chat')){
                /*
                console.log(that.notification);
                console.log(that.notification.message);
                console.log(that.notification.message==null);
                console.log(that.notification.message);
                console.log('notification that.appStarted', that.appStarted, that.startedByNotification);
                */
                if(!that.appStarted) {
                  // only go to post screen if opening app from cold
                  // 
                  that.startedByNotification = true;
                  
                  
                  that.notification = data;
                  //console.log('opening app from cold - so go straight to groupposts page');

                  that.zone.run(() => {  //   needed or doesn't update untill next cheange detection
                    //console.log('headers', that.storeService.headers);
                    if(!that.storeService.headers) {
                      //console.log('need to set headers');
                      that.storeService.setHeaders(that.user.access_token);
                    }
                    that.storeService.setHeaders(that.user.access_token);
                    that.nav = that.app.getComponent('nav');
                    that.appStarted = true;

                    if(data['command'] == 'post') {
                      that.storeService.setPage('groupposts');
                      that.nav.setRoot(GroupPostsPage, {
                        group: { id: data['groupId'] }
                      });
                    } else if (data['command'] == 'chat') {
                      that.storeService.setPage('groupchat');
                      that.nav.setRoot(GroupChatPage, {
                        group: { id: data['groupId'] }
                      });
                    }
                    

                    
                  });

                  
                } else {  //  app alredy started
                  that.notification = data;
                  //console.log('app already open so dont change page');
                  that.storeService.getPage().then((page) => {
                    //console.log(page);
                    if(page == 'groupposts') {
                      // also check if same group!!
                      //console.log('in groupposts page so add message to screen', that.notification.groupId);
                      that.events.publish('groupposts:add', that.notification);
                    } else if(page == 'groupchat') {
                      // also check if same group!!
                      //console.log('in grouchat page so add message to screen', that.notification.groupId);
                      that.events.publish('groupchat:add', that.notification);
                    }

                  });
                
                }

              }
            }
        });

        push.on('error', (e) => {
            console.log('error', e.message);
        });
      }  //   if push
    }  //  no user so can't store token
  });
}
















    
/*
    document.addEventListener('backbutton', () => {  //   handle android back button
   //   let nav = this.app.getComponent('nav');
      if (!this.nav.canGoBack()) {
        return navigator.app.exitApp();
      }
      return nav.pop();
    }, false);
*/



  initializeApp(r) {
    //var that = this;
    
  }

    /*
        Do we have a user stored local(ly?
        if yes then retrieve and go to main menu
        If no user then create one locally with no ID and go to welcome screen
            try to save user remotely and return remote ID which is saved locally
                if can't then flag as problem, and keep trying (suggests no connection)
    */



      //The SqlStorage service supports these options: { name: the name of the database (__ionicstorage by default)
        //backupFlag: // where to store the file, default is BACKUP_LOCAL which DOES NOT store to iCloud. Other options: BACKUP_LIBRARY, BACKUP_DOCUMENTS existingDatabase: whether to load this as an existing database (default is false) }





  buildLeftMenu() {
    var pages = [
      /*{ title: 'Root', component: RootPage, params: { page: 'root' } },*/
      { title: 'Home', component: HomePage, params: { page: 'home' } },
      { title: 'Discover', component: LatestChatPage, params: { page: 'latestChat' } },
      { title: 'Featured charities', component: GroupsPage, params: { page: 'groupsFeatured' } },
      { title: 'Featured fundraisers', component: GroupsPage, params: { page: 'groupsFundraisers' } },
      //{ title: 'Start fundraising', component: GroupEditPage, params: { page: 'groupEdit' } },
      //{ title: 'My faves', component: GroupsPage, params: { page: 'groupsJoined' } },
      { title: 'Trending', component: GroupsPage, params: { page: 'groupsTrending' } },
      { title: 'Search for charity', component: GroupsPage, params: { page: 'groupsSearch' } },
      { title: 'News', component: PostsPage, params: { page: 'news', groupId: 1 } },
      //{ title: 'Invite friends', component: InvitePage, params: { page: 'invite' } },
      { title: 'My donations', component: PaymentsPage, params: { page: 'payments' } },
      { title: 'My regular donations', component: RecurringPaymentsPage, params: { page: 'recurringpayments' } },
      
      { title: 'About WowGive', component: RemotePage, params: { page: 'about' } },
      { title: 'Contact WowGive', component: RemotePage, params: { page: 'contact' } },
      { title: 'Profile', component: AccountPage, params: { page: 'account' } },
    ];
    //arr.splice(1, 0, { title: 'Donate My Tweet', component: DonateTweetPage, params: { page: 'donateTweet' } },);

    //pages.push({ title: 'Profile', component: AccountPage });
    //pages.push({ title: 'About WowGive', component: RemotePage, params: { page: 'about' } });
    //{ title: 'Start a new group', component: StartGroupPage },
    //{ title: 'Join a group', component: JoinGroupPage }
    return pages;
  }  


  openPage(page) {
    // close the menu when clicking a link from the menu
    this.menu.close(); // zzz
    //console.log(this.nav);
    // navigate to the new page if it is not the current page
    //let nav = this.app.getComponent('nav');
    this.storeService.setPage(page.params.page);
    this.nav.setRoot(page.component, page.params)
    .then(data => {
      //console.log(data);
  }, (error) => {
      console.log(error);
  })
  }
}



/*
  clickJoinGroup(group) {
    //console.log(this.user);
    if (!group) {
      var group = this.storeService.getGroup();
    }
    var groupUser = {
      groupId: group.id,
      userId: this.user.id,
      avatar: this.user.avatar,
      permission: 1,
      action: 'join'
    };
    //console.log(group);
    //console.log(groupUser);
    var that = this;

    this.groupService.joinGroup(groupUser).subscribe(
      function(data) {
        //console.log('data', data);
        //console.log(data.status);
        //console.log(data._body);
        var body = JSON.parse(data._body);
        //console.log(body);
        //console.log(body.data);
        var status = body.status;
        if (status == 'success') {
          that.utilsService.setStatusMessage(that.statusMessage, 'status', 'Added to your faves!');
        } else {
          that.utilsService.setStatusMessage(that.statusMessage, 'err', body.error.code);//  eg   //  eg ER_NO_SUCH_TABLE
        }
      },
      function(err) {
        //console.log('error)', err);
      },
      function(complete) {
        //console.log('complete)', complete);
      }
    );
  }
*/








ionicBootstrap(WowGive, [UserService, LocalService, FileService, PaymentService, PluginsService, StoreService, ValidationService, GroupService, PostService, ChatService, UtilService, Events], {
  tabbarPlacement: 'bottom'
});

