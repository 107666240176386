import {Injectable} from "@angular/core";
import {Camera, ImagePicker} from 'ionic-native';

import {StoreService} from './store-service';

@Injectable()
export class PluginsService {
    storeService: any;
    
    constructor(storeService: StoreService) {
        this.storeService = storeService;
        var that = this;
    }     
    
    albums = {            
        open () : Promise<any>  { 
            return ImagePicker.getPictures({
                    quality: 100,                        
                    maximumImagesCount: 15,
            }).then((imgUrls) => {
                console.log(imgUrls);
                return imgUrls;
            }, (err) => {                                   
                if(err.error == "cordova_not_available") {               
                    alert("Cordova is not available, please make sure you have your app deployed on a simulator or device");                                   
                } else {                
                    alert("Failed to open albums: " + err.error);
                }
            });
        },         
    }
    
    camera = {       
        open () : Promise<any>  {
            var options = {
                destinationType: 1,
                sourceType: 1,
                encodingType: 0,
                quality:100,
                allowEdit: false,
                saveToPhotoAlbum: true,            
                correctOrientation: true,
            };        
            return Camera.getPicture(options).then((imgUrl) => {
                console.log(imgUrl);
                return imgUrl;
            }, (err) => {                
                if(err.error == "cordova_not_available") {
                    alert("Cordova is not available, please make sure you have your app deployed on a simulator or device");            
                } else {
                    alert("Failed to open camera: " + err.error);                
                }    
            });
        } 
    }  
    
    file = {
        upload (url: string, image: string, onSuccess: any, onFailed: any, onProgress: any) : void {

            var ft = new FileTransfer();                       
            //var options = new FileUploadOptions();
            //var filename = _.uniqueId() + ".jpg";
            var filename = 'chat';// + ".jpg";
            console.log(this);
            //console.log(that);
            var options = {
                fileKey: "file",
                fileName: filename,    // not needed, so use as a category
                mimeType: "image/jpeg",
                chunkedMode: false,
                //return this.http.get(url, { headers: this.storeService.getHeaders() });
                /*headers: { 
                    'Content-Type' : 'application/json',// was undefined
                    appId: 1
                },
                */
                headers: this.storeService.getHeaders(),
                params: {
                    fileName: filename,
                    userId: 1,
                    toGroupId: 109,
                    message: 'test'
                }
            }
             


            
            ft.onprogress =  (e: ProgressEvent) => onProgress(e);  
            ft.upload(image, url, onSuccess, onFailed, options);            
        }
    }  
}