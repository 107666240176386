import {NavController, NavParams, Platform, Events, Alert} from 'ionic-angular';
import {Inject, NgZone} from '@angular/core';
import {Component} from '@angular/core';

import {GroupService} from '../../services/group-service';
import {StoreService} from '../../services/store-service';
import {LocalService} from '../../services/local-service';
import {UtilService} from '../../services/util-service';

import {GroupsPage} from '../groups/groups';

import {PluginsService} from '../../services/plugins-service';
import {ChatService} from '../../services/chat-service';

import {PERMISSIONS_ADMIN} from '../../config';
import {APP_PLATFORM} from '../../config';
import {WEB_URL} from '../../config';


@Component({
  templateUrl: 'build/pages/group/grouptweet.html'
})
export class GroupTweetPage {
  utilService: any;
  groupService: any;
  storeService: any;
  localService: any;
  chatService: any;
  platform: any;
  nav: any;
  icons: any;
  users: any;
  pageTitle: string;
  user: any;
  oldDate: any;
  oldPaymentMonth: any;
  different: any;
  isGetting: boolean;
  isIOS: boolean;
  group: any;
  groupUser: any;
  mode: string;
  newMessage: string;
  statusMessage: any;
  isSending: boolean;
  events: any;
  zone: any;
  newPost: any;
  subscription: any;
  canPost: boolean;
  isDashboard: boolean;
  twitterSignupUrl: string;
  usersLength: number = 0;
  totalFollowers: number = 0;
  imageToUpload: string;
  images: Array<string> = [];
  imageToSend: any;
  tweetId: string = '';
  tweetLength: number = 0;

  constructor(private plugins: PluginsService, nav: NavController, zone: NgZone, navParams: NavParams, events: Events, chatService: ChatService, groupService: GroupService, storeService: StoreService, localService: LocalService,  utilService: UtilService, platform: Platform) {
    this.nav = nav;
    this.groupService = groupService;
    this.storeService = storeService;
    this.localService = localService;
    this.utilService = utilService;
    this.chatService = chatService;
    this.platform = platform;
    this.pageTitle = 'News';
    this.oldDate = null;
    this.oldPaymentMonth = null
    this.different = false;
    this.isGetting = true;
    this.users = null;
    this.user = this.storeService.getUser();
    this.newMessage = '';
    this.isSending = false;
    this.events = events;
    this.zone = zone;
    this.canPost = false;
    this.isDashboard = APP_PLATFORM == 'web';
    this.twitterSignupUrl = WEB_URL + '/tweetdonate.html?groupId=';
    this.imageToUpload = '';
    this.imageToSend = null;

    this.storeService.notification = null;
    
    this.storeService.setPage('groupTweet');
    this.group = navParams.get('group');
    this.twitterSignupUrl += this.group.id;
    /*
    this.groupUser = navParams.get('groupUser');
    if(this.groupUser.permission == PERMISSIONS_ADMIN) {
      this.canPost = true;
    }
    */
    //console.log('group', this.group);
    this.isIOS = this.platform.is('ios');

    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };
    var that = this;

    that.getTwitterFollowers();
    

  }


  calculateTweetLength() {
    //console.log('dsd', this.tweetLength);
    this.tweetLength = this.newMessage.length;
  }

  getTwitterFollowers() {
    //console.log('getTwitterFollowers');
    var that = this;
    let query = { groupId: that.group.id, order: 'desc' };
    //console.log(that); 
    that.isGetting = true;
    that.groupService.getSocialTwitter(query).subscribe(
      function(data) {
        //console.log(data);
        var body = JSON.parse(data._body);
        if (body.data && body.data.groups) {
          //console.log(body.data.posts);
          //console.log(body.data.quantity);
          //var posts = body.data.posts;
          //console.log(posts);
           that.usersLength = body.data.groups.length
          //console.log('qty', body.data.groups.length);
          
          var users = [];
          var totalFollowers = 0;
          for (let i = 0; i < that.usersLength; i++) {

            users.push({
              id: body.data.groups[i].id,
              twitterUsername: body.data.groups[i].twitterUsername,
              avatar: body.data.groups[i].avatar,
              twitterFollowers: body.data.groups[i].twitterFollowers,
            });
            totalFollowers += body.data.groups[i].twitterFollowers;
            //console.log(i, users[i]);
           
            //console.log(that.posts);
            //storeService.setpayments(that.posts);
          }
          that.users = users;
          that.totalFollowers = totalFollowers;
          users = null;
          body = null;
          data = null;
          
        } else if (body.error) {
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          console.log(body.error);
          //that.loginError = body.error.code;
          //console.log('error returned');
        } else {
          //console.log(body);
        }
      },
      function(err) {
        that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
        console.log('error', err);
        //console.log(err._body);
      },
      function() {
        //console.log('complete');
        that.isGetting = false;
      }
    );
  }


  cancelSend() {
    this.mode = 'view';
  }
  openCompose() {
    this.mode = 'compose';
  }
deleteImageToSend() {
  this.imageToSend = null;
  this.imageToUpload = '';
}


  retweet() {
    var that = this;
    //console.log(this.newMessage);
    if(this.usersLength == 0) {
      this.utilService.setStatusMessage(this.statusMessage, 'error', 'You need to get some Twitter supporters first, or add your own account using the link below.');
      return;
    }
    if(this.tweetId && this.tweetId.length > 5) {
        var params = {
           groupId: this.group.id,
           tweetId: this.tweetId,
           userId: this.user.id,
           action: 'retweetfgs'
        };
        that.isSending = true;
      that.groupService.sendTweet(params).subscribe(
          function(data) {

            var body = JSON.parse(data._body);
            var status = body.status;
            if (status == 'success') {
              //console.log(body);
              that.utilService.setStatusMessage(that.statusMessage, 'status', 'Retweet sent successfully!');
              that.tweetId = '';
            } else {
              //console.log(body);
              var err = body.error.code;
              if(body.error.message) {
                err = body.error.message;
              }
              that.utilService.setStatusMessage(that.statusMessage, 'error', err);//  eg   //  eg ER_NO_SUCH_TABLE
            }
          },
          function(err) {
            
            console.log('error)', err);
          },
          function(complete) {
            //console.log('complete donate');
            that.isSending = false;

          }
        );
    } else {
      that.utilService.setStatusMessage(that.statusMessage, 'error', 'You need to enter a Twitter status ID');
    }

  }



  tweet() {
     if(this.usersLength == 0) {
      this.utilService.setStatusMessage(this.statusMessage, 'error', 'You need to get some Twitter supporters first, or add your own account using the link below.');
      return;
    }
    var that = this;
    //console.log(this.newMessage);
    if((this.newMessage && this.newMessage.length > 1) || this.imageToUpload) {
      var params = {
         groupId: this.group.id,
         message: this.newMessage,
         userId: this.user.id,
         action: 'sendtweetfgs'
      };
      that.sendMessageOK();
    } else {
      that.utilService.setStatusMessage(that.statusMessage, 'error', 'You need to enter some text or an image to tweet');
    }

  }















gotFile(event) {
    var files = event.srcElement.files;
    //console.log(files);
    this.imageToSend = files[0];
    this.imageToUpload = this.imageToSend.name;
  }

  sendMessageOK() {
    //console.log(this.user);

    //console.log(this.imageToSend);
    if(!this.imageToSend && !this.newMessage) {
      return;
    }

    var that = this;
    //console.log(this.newMessage);
    that.isSending = true;

    //this.newMessage = this.newMessage.replace(/(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/ig, '<a href="$1">$1</a>');

    //console.log(this.newMessage);

/*
    var params = {
       toGroupId: this.group.id,
       message: this.newMessage,
       userId: this.user.id,
       toGroupName: this.group.name,
       imageUrl: null,
       file: null
    };
    */
      var params = {
       groupId: this.group.id,
       message: this.newMessage,
       userId: this.user.id,
       action: 'sendtweetfgs',
      toGroupName: this.group.name,
       imageUrl: null,
       file: null
    };

    if(this.imageToSend) {
        params.imageUrl = this.imageToSend.name;
        params.file = this.imageToSend;
    }

    function onSuccessImage(data) {
      //console.log('success', data);

      that.isSending = false;

        var body = JSON.parse(data.response);
        var status = body.status;
        if (status == 'success' && body.data && body.data.post) {
          //console.log(body);
        that.zone.run(() => {
          that.newMessage = '';
          that.imageToSend = null;
          that.imageToUpload = '';
        });

        } else {
          
          //console.log(body);
          that.zone.run(() => {
            var err = body.error.err;
            if(body.error.message) {
              err = body.error.message;
            }
            that.utilService.setStatusMessage(that.statusMessage, 'error', err);
        });
        }
    }
    function onFailureImage(err) {
      //var that = that.storeService.getScope();
      console.log('failure', err);
      that.isSending = false;
      that.utilService.setStatusMessage(that.statusMessage, 'error', err);
    }

    function onSuccess(data) {
      that.newMessage = '';
      //console.log('suc');
        //console.log(data);
        var body = JSON.parse(data._body);
        var status = body.status;
        if (status == 'success') {
          //console.log(body);
          that.utilService.setStatusMessage(that.statusMessage, 'status', 'Tweet sent successfully!')

        } else {
          
          //console.log('else', body);
          var err = body.error.code;
          if(body.error.message) {
            err = body.error.message;
            // if use test card on prod, then get this
          }
          that.utilService.setStatusMessage(that.statusMessage, 'error', err);//  eg   //  eg ER_NO_SUCH_TABLE
        }
    }
    function onFailure(err) {
      console.log('failure', err);
      that.isSending = false;
       that.utilService.setStatusMessage(that.statusMessage, 'error', err.error.message);
    }

    that.isSending = true;
    ///console.log(that.imageToSend, that.isDashboard);
    if(that.imageToSend) {
      if(that.isDashboard) {
       that.groupService.sendTweetWithImage(params, onSuccessImage, onFailureImage, that.onProgress, that).then((result) => {
          //console.log(result);
          that.isSending = false;
          if(result.status == 'failure') {
            //result._body = JSON.stringify({ data: result.error, status: 'failure'});
            onFailure(result);
          } else {
            result._body = JSON.stringify({ data: result.data, status: 'success'});
            onSuccess(result);
          }
        })


      } else {
        that.groupService.sendTweetWithImage(params, onSuccessImage, onFailureImage, that.onProgress, that);
      }

    } else {
      that.groupService.sendTweet(params, that.onProgress).subscribe(onSuccess, onFailure, function(complete) {
          //console.log('complete add chat');
          that.isSending = false;
        });
    }
  }








    openAlbums() {
      var that = this;
        this.plugins.albums.open().then((imgUrls) => {            
            imgUrls.forEach((imageUrl: string) : void => {
                if(imageUrl){                  
                  this.images.push(imageUrl);
                  //this.upload(this.images[0]);
                  this.imageToSend = imageUrl;
                  //console.log(typeof(imageUrl), imageUrl);
                  //this.sendMessage();
                  //this.sendImage(this.images[0]);    
                }
            }); 
        });        
    }


/*
    openAlbums = () : void => {
        this.plugins.albums.open().then((imgUrls) => {            
            imgUrls.forEach((imageUrl: string) : void => {
                if(imageUrl){                  
                  this.images.push(imageUrl);
                  //this.upload(this.images[0]);
                  this.post.imageUrl = imageUrl;
                  this.sendMessage();
                  //this.sendImage(this.images[0]);    
                }
            }); 
        });        
    }
     */ 
    openCamera = () : void => { 
        this.plugins.camera.open().then((imageUrl) => { 
          if(imageUrl) {
            this.images.push(imageUrl);
            //this.upload(this.images[0]);
            //this.sendMessage();
            //this.sendImage(this.images[0]);                
          }
      });
    }
    /*
    startUploading = () : void => {
      var that = this;
      this.progress = 0;
    }
*/


    success = (result: any) : void => {
      //console.log('done'); 
    /*  
      this.upload(this.images[0]);      
        //this.current++;         
        
        if(this.current <= this.total) {              
            //this.setCurrent(this.current);   
            //this.setProgress(0);            
            setTimeout(() : void => {     
                // give the animation time to reset           
                this.upload(this.images[this.current - 1]);
            },1000);
        } else {   
            this.finished = true;
            //this.loading.dismiss();
            //$("#done").show();
            console.log('done');
        }
        */
    }
    
            
    failed = (err: any) : void => {
        var code = err.code;
        //console.log(err);
        //this.loading.dismiss();
        //alert("Failed to upload image. Code: " + code);
    }
    
    onProgress (progressEvent: ProgressEvent, that: any) {
      //console.log('onprog');
      //console.log(that.progress);
        if (progressEvent.lengthComputable) {
          

            that.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            //this.setProgress(this.progress); 
            that.zone.run(() => {
              that.progress = that.progress + 1;
              //console.log('progress is', that.progress, that);
            });
            //console.log(this.progress); 
            if(that.progress < 0 ){
              that.progress = 0;
            }
            if(that.progress > 100) {
                that.progress = 100;
            }         
        }
        
    }

   // setCurrent = (current: number) : void => {
       // $("#current").html(current.toString());
    //}
 
 /*
    upload = (image: string) : void => { 

        this.plugins.file.upload(SERVER_URL + 'postfile' , image, this.success, this.failed, this.onProgress);
    }
*/

  sendImage (image: string) { 

        //this.plugins.file.upload(SERVER_URL + 'postfile' , image, this.success, this.failed, this.onProgress);
    }











openHelpRetweet() {
  let alert = Alert.create({
    title: 'Retweet',
    message: "To Retweet any Tweet, you need its 'status ID'  - its unique identifier. You can find this simply by clicking on the text of the tweet so that the whole Tweet becomes the focus of the page, and then you will see the url of the page change. The long number at the end of the url is the status ID. Copy and paste this into the status ID field, and tap the Retweet button to send it to all your supporters and their followers.",
    buttons: ['Ok']
  });
  this.nav.present(alert);
}

openHelpTweet() {
  let alert = Alert.create({
    title: 'Tweet',
    message: 'Send a Tweet with or without an image to all your supporters and their followers.',
    buttons: ['Ok']
  });
  this.nav.present(alert);
}











  monthName(dateTime) {
    return this.utilService.getMonthName(dateTime.getMonth());
  }




openInExternalBrowser(url)
{
 // Open in external browser
 window.open(url,'_system','location=yes'); 
};
 
openInAppBrowser(url)
{
 // Open in app browser
 window.open(url,'_blank'); 
};
 
openCordovaWebView(url)
{
 // Open cordova webview if the url is in the whitelist otherwise opens in app browser
 //console.log(url);
 window.open(url,'_self'); 
};

}


