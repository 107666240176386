import {NavController, NavParams} from 'ionic-angular';
import {Inject} from '@angular/core';
import {Component} from '@angular/core';

import {PaymentService} from '../../services/payment-service';
import {StoreService} from '../../services/store-service';
import {UtilService} from '../../services/util-service';

import {AccountPage} from '../account/account';
import {InvitePage} from '../invite/invite';

@Component({
  templateUrl: 'build/pages/payments/payments-received.html'
})
export class PaymentsReceivedPage {
  utilService: any;
  paymentService: any;
  storeService: any;
  nav: any;
  icons: any;
  payments: any;
  pageTitle: string;
  user: any;
  oldDate: any;
  oldPaymentMonth: any;
  different: any;
  group: any;
  groupsJoined: any;
  isGetting: boolean;
  statusMessage: any;
  connectionError: boolean;

  constructor(nav: NavController, paymentService: PaymentService, storeService: StoreService, utilService: UtilService) {
    this.nav = nav;
    this.paymentService = paymentService;
    this.storeService = storeService;
    this.utilService = utilService;
    this.pageTitle = 'Donations received';
    this.oldDate = null;
    this.oldPaymentMonth = null
    this.different = false;
    this.isGetting = true;
    this.connectionError = false;
    this.storeService.setPage('paymentsreceived');

    this.payments = null;
    this.user = this.storeService.getUser();
    this.groupsJoined = this.storeService.getGroupsJoined();
    //console.log('groupsJoined', this.groupsJoined);

 this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };

    if(this.groupsJoined.then) {
      //console.log('yes this is a promise');
      this.groupsJoined.then((groupsJoined) => {
        this.groupsJoined = groupsJoined;
        this.displayPaymentsReceived();
      });
    } else {
      this.displayPaymentsReceived();
    }
  }





  displayPaymentsReceived() {
    for (var i = 0; i < this.groupsJoined.length; i++) {
      if (this.groupsJoined[i].permission == 100) {
        this.group = this.groupsJoined[i];
      }
    }
    
    //console.log('group', this.group);

    var that = this;
    let query = { groupId: that.group.id, order: 'desc' };
    that.paymentService.get(query).subscribe(
      function(data) {
        that.connectionError = false;
        //console.log(data);
        var body = JSON.parse(data._body);
        if (body.data && body.data.payments) {
          //console.log(body.data.payments);
          //console.log(body.data.quantity);
          var payments = body.data.payments;
          that.payments = [];
          for (let i = 0; i < body.data.quantity; i++) {
            that.payments.push({
              id: body.data.payments[i].id,
              amount: body.data.payments[i].amount,
              currencySymbol: '£',
              //username: body.data.payments[i].username,
              name: body.data.payments[i].name,
              dateTime: new Date(body.data.payments[i].dateTime),
            });
            //storeService.setpayments(that.payments);
          }
        } else if (body.error) {
          that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
          that.isGetting = false;
          that.connectionError = true;
          //console.log(body.error);
          //that.loginError = body.error.code;
          //console.log('error returned');
        } else {
          //console.log(body);
        }
        if(body.data.quantity == 0) {
          that.payments = null;
        }
      },
      function(err) {
        that.utilService.setStatusMessage(that.statusMessage, 'error', 'Problem connecting to server.', 6000);
        that.isGetting = false;
        that.connectionError = true;
        //console.log('error', err);
        //console.log(err._body);
        //var body = JSON.parse(err._body);
        ////console.log(body);
        //that.loginError = 'Error ' + err.status;
        ////console.log(this.userService);
        //that.passwordControl.

      },
      function() {
        //console.log('complete');
        that.isGetting = false;
      }
    );
  }

  inviteFriends() {
    this.nav.setRoot(InvitePage);
  }

  createAccount() {
    this.nav.setRoot(AccountPage);
  }

  monthName(dateTime) {
    return this.utilService.getMonthName(dateTime.getMonth());
  }


  groupTapped(event, group) {
    /*
    this.storeService.setGroup(group);
    this.nav.push(GroupPage, {
      group: group
    });
    */
  }

}
