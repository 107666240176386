import {NavController, NavParams, MenuController, App, Alert, ActionSheet, Events, Platform, Loading} from 'ionic-angular';
import {ViewChild} from '@angular/core';
import {NgClass} from '@angular/common';
import {NgZone} from '@angular/core';
import {Component} from '@angular/core';
import {Contacts, SMS, Keyboard, SocialSharing, Clipboard} from 'ionic-native';
import {Http} from '@angular/http';

import {StoreService} from '../../services/store-service';
import {GroupService} from '../../services/group-service';
import {UserService} from '../../services/user-service';
import {UtilService} from '../../services/util-service';
import {PaymentService} from '../../services/payment-service';
import {LocalService} from '../../services/local-service';
import {ChatService} from '../../services/chat-service';

import {DONATION_URL} from '../../config';
import {APP_PLATFORM} from '../../config';

import {AccountPage} from '../account/account';
import {GroupPaymentsPage} from '../payments/grouppayments';
import {GroupUsersPage} from '../group/groupusers';
import {GroupContactPage} from '../group/groupcontact';
import {GroupPostsPage} from '../group/groupposts';
import {GroupChatPage} from '../group/groupchat';
import {GroupEditPage} from '../group/groupedit';
import {GroupTweetPage} from '../group/grouptweet';

declare const facebookConnectPlugin: any;

@Component({
  templateUrl: 'build/pages/group/group.html'
})
export class GroupPage {
  //nav: any;
  app: any;
  menuController: any;
  http: any;
  group: any;
  storeService: any;
  groupService: any;
  userService: any;
  utilService: any;
  paymentService: any;
  chatService: any;
  localService: any;
  events: any;
  statusMessage: any;
  donation: any;
  donationSaved: any;
  payments: any;
  user: any;
  isIOS: boolean;
  isAndroid: boolean;
  isDashboard: boolean;
  allowPushNotifications: boolean;
  donateButtonText: string;
  isSending: boolean;
  recurring: boolean;
  imInThisGroup: boolean;
  groupsJoined: any;
  paymentsVisible: boolean;
  loading: any;
  isSent: boolean;
  platform: any;
  zone: any;
  today: any;
  descriptionShow: boolean;
  posts: any;
  postsHaveImages: boolean;
  imageToUpload: string;
  imageToSend: any;
  logoToUpload: string;
  logoToSend: any;
  claimDisabled: boolean;
  currencySymbol: string;

//@ViewChild(Nav) nav: Nav;

  constructor(private nav: NavController, http: Http, zone: NgZone, navParams: NavParams, platform: Platform, menuController: MenuController, app: App, events: Events, storeService: StoreService, groupService: GroupService, userService: UserService, utilService: UtilService, paymentService: PaymentService, localService: LocalService, chatService: ChatService) {
   //this.nav = nav;
    this.storeService = storeService;
    this.groupService = groupService;
    this.userService = userService;
    this.utilService = utilService;
    this.localService = localService;
    this.chatService = chatService;
    this.platform = platform;
    this.zone = zone;
    this.http = http;

    this.paymentService = paymentService;
    this.app = app;
    this.menuController = menuController;
    this.events = events;
    this.payments = [];
    this.paymentsVisible = false;
    this.isSent = false;
    this.descriptionShow = false;

  this.postsHaveImages = false;
  this.claimDisabled = false;

    this.isIOS = this.platform.is('ios');
    this.isAndroid = this.platform.is('android');
    this.isDashboard = APP_PLATFORM == 'web';
    //console.log('IOS--------', this.isIOS);

    this.statusMessage = {
      visible: false,
      text: '',
      type: 'error'
    };

    this.donateButtonText = 'DONATE';

    this.donation = {
      visible: false,
      amountVisible: true,
      amount: '',
      recurring: false,
      confirmationVisible: false,
      confirmationMessage: '',
      confirmationMessage2: '',
      buttonText: this.donateButtonText,
      buttonClass: '',
      helpVisible: false,
      valid: false,
      secondaryText: '       ',
      url: ''
    }



    this.donationSaved = {};
    this.user = this.storeService.getUser();
    this.menuController.enable(true, 'rightGroup');
    this.menuController.enable(false, 'rightMain');
    this.isSending = false;
    this.imInThisGroup = false;
    this.today = new Date();



    var getCurrencySymbol = function(currency) {
            if(currency == 'usd') return '$';
            if(currency == 'eur') return '€';
            if(currency == 'gbp') return '£';
    };

    this.currencySymbol = getCurrencySymbol(this.user.currency);


    var that = this;
    this.group = navParams.get('group');
    //console.log('nav params----', this.group);
    /*
    if(this.group && this.group.description) {
       that.gotGroupSoContinue();
    } else {
      //console.log('no group so need to get');
      that.getGroup();
      return;
    }
    */

    that.getGroup();  // get group anyway as extra fields
}

gotGroupSoContinue() {
  var that = this;
  this.getAmountDonatedThisMonthToGroup();
  this.getTotalUsersForGroup();
  this.getGroupUser();  //  get groupuser allowPushNotification
  if(this.group.targetDate) {
    let today = new Date();
      //console.log(this.group.targetDate, typeof(this.group.targetDate));
      this.group.daysLeft =  Math.round((new Date(this.group.targetDate).setHours(0,0,0,0) - today.setHours(0,0,0,0)) / 86400000).toString();
      //console.log(this.group.daysLeft);
  }

    this.groupsJoined = this.storeService.getGroupsJoined();
    //console.log('groupsJoined', this.groupsJoined);
    if (this.groupsJoined.then) {
      //console.log('groupsJoined - promise');
      this.groupsJoined.then((groupsJoined) => {
        if (groupsJoined) {
          this.groupsJoined = groupsJoined;
          ////console.log('groupsJoined--', this.groupsJoined);
          this.setIveJoinedThisGroup();
        }
      });
    } else {
      this.setIveJoinedThisGroup();
    }




    
    //console.log('groupage');

  this.displayChat();

    this.events.subscribe('reloadGroupPage',() => {
      //console.log('reloadGroupPage');
      //this.nav.pop();
       //this.nav.push(GroupPage);
       //that.zone.run(() => { 
         that.group = that.storeService.getGroup();
         that.user = that.storeService.getUser();
         that.getAmountDonatedThisMonthToGroup();
         that.getTotalUsersForGroup();
         that.getGroupUser();
      // });
    });

    this.events.subscribe('reloadGroupPageUser',() => {
      //console.log('reloadGroupPageUser');
       that.user = that.storeService.getUser();
    });
  
    //console.log(nav, nav);
    //this.displayPaymentsReceived();



    //this.events.subscribe('userLocal:got', (user) => {


/*
    document.addEventListener('backbutton', (e) => {
      console.log('back');
      console.log(that.storeService.popups);
      e.preventDefault();
      if(that.donation.visible) {
        //setTimeout(that.clickCloseDonate, 100);
        setTimeout(function(){
          console.log('closepanel yes2');
          console.log(that.donation);
          that.clickCloseDonate();
        }, 500);
      }
    });
    */
    this.platform.ready().then(() => {
      //console.log('ready....');
      
      if(this.isAndroid) {
        that.platform.backButton.subscribe(function () {
          if(that.donation.visible) {
            that.zone.run(() => {  //   needed or doesn't update untill next cheange detection
              that.clickCloseDonate();
            });
          }
        });
      }

    });  //  ready



    function addAnEventListener(obj, evt, func) {
      if ('addEventListener' in obj) {
        obj.addEventListener(evt, func, false);
      } else if ('attachEvent' in obj) {//IE
        obj.attachEvent('on' + evt, func);
      }
    }


    function iFrameListener(event, a, b) {
     // console.log('iFrameListener');
     // console.log(event.data);
      that.closeKeyboard();
      if (event.data.message == 'donationSent') {
        var data = event.data;
        //console.log('donationSent received');
        that.zone.run(() => {  //   needed or doesn't update untill next change detection

          that.utilService.setStatusMessage(that.statusMessage, 'status', "Donation sent!", 5000);
        });
        setTimeout( function() {
          //that.events.publish('reloadGroupPageUser');
        }, 1000);
      
      }
    }

    addAnEventListener(window, 'message', iFrameListener);



    
  }//  constructor
  

closeKeyboard() {
  Keyboard.close();
}


  gotoGroupTweet() {
    this.nav.push(GroupTweetPage, {
      group: this.group,
      groupUser: this.group.user
    });
  }


  openPosts() {
    this.nav.push(GroupPostsPage, {
      group: this.group,
      groupUser: this.group.user
    });
  }





  openChat() {
    this.nav.push(GroupChatPage, {
        group: this.group,
        groupUser: this.group.user
      });
    return;
}
oldOpenChat() {
    var that = this;
    if(!this.imInThisGroup) { // don't bother with this now
      let message = 'You need to be a supporter to check messages. To support them, tap the heart button.'
      let supportAlert = Alert.create({
        title: 'Chat',
        message: message,
        buttons: [
          {
            text: 'OK',
            handler: () => {
            }
          }]
      });
      this.nav.present(supportAlert);
    } else if (!this.user || !this.user.id) {
      let message = 'Log in to view messages?'
      let loginAlert = Alert.create({
        title: 'Messages',
        message: message,
        buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes',
              handler: () => {
                //gotoLogin();
                // user has clicked the alert button
                // begin the alert's dimiss transition
                let navTransition = loginAlert.dismiss();

                // start some async method
                //someAsyncOperation().then(() => {
                // once the async operation has completed
                // then run the next nav transition after the
                // first transition has finished animating out

                navTransition.then(() => {
                  that.nav.push(AccountPage);
                });
                return false;      
              }
            }]
          });
      this.nav.present(loginAlert);
    } else {
      this.nav.push(GroupChatPage, {
        group: this.group,
        groupUser: this.group.user
      });
    }
  }



  clickStartFundraising() {
    var that = this;
    if(!this.imInThisGroup) {
      let message = 'You need to be a supporter before you can fundraise. To support , tap the heart button.'
      let supportAlert = Alert.create({
        title: 'Fundraise',
        message: message,
        buttons: [
          {
            text: 'OK',
            handler: () => {
            }
          }]
      });
      this.nav.present(supportAlert);
    } else if (!this.user || !this.user.id) {
      let message = 'Log in to fundraise?'
      let loginAlert = Alert.create({
        title: 'Fundraise',
        message: message,
        buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes',
              handler: () => {
                //gotoLogin();
                // user has clicked the alert button
                // begin the alert's dimiss transition
                let navTransition = loginAlert.dismiss();

                // start some async method
                //someAsyncOperation().then(() => {
                // once the async operation has completed
                // then run the next nav transition after the
                // first transition has finished animating out

                navTransition.then(() => {
                  that.nav.push(AccountPage);
                });
                return false;      
              }
            }]
          });
      this.nav.present(loginAlert);
    } else {
      this.nav.push(GroupEditPage, {
        parentGroup: this.group,
        user: this.user,
        action: 'addChildGroup'
      });
    }
  }



  clickEditGroup() {
    //this.storeService.setGroup(group);
    //console.log('openGroupEditPage');
    this.nav.push(GroupEditPage, {
        group: this.group,
        user: this.user,
        action: 'editGroup'
      });
  }

  openPayments() {
    //this.storeService.setGroup(group);
    //console.log('openPayments');
    this.nav.push(GroupPaymentsPage, {
      group: this.group
    });
  }

  openUsers() {
    //this.storeService.setGroup(group);
    //console.log('openUsers');
    this.nav.push(GroupUsersPage, {
      group: this.group
    });
  }

  openContactPage() {
    //this.storeService.setGroup(group);
    //console.log('openContactPage');
    //setTimeout(() => {
      // this._applicationRef.tick();
     //}, 200);
     var that = this;
     if( this.isIOS) {
       setTimeout(() => { //   needs delay otherwise crashes slower iphone
        that.nav.push(GroupContactPage, {
          group: that.group
        });
      }, 500);
    } else {
        that.nav.push(GroupContactPage, {
          group: that.group
        });
    }
  }

  togglePayments() {// not needed as opening new page
    //this.paymentsVisible = !this.paymentsVisible;
  }

  displayPaymentsReceived() {// not needed as opening new page
    var that = this;
    let query = { groupId: that.group.id, order: 'desc' };
    that.paymentService.get(query).subscribe(
      function(data) {
        //console.log(data);
        var body = JSON.parse(data._body);
        if (body.data && body.data.payments) {
          //console.log(body.data.payments);
          //console.log(body.data.quantity);
          var payments = body.data.payments;
          that.payments = [];
          for (let i = 0; i < body.data.quantity; i++) {
            that.payments.push({
              id: body.data.payments[i].id,
              amount: body.data.payments[i].amount,
             // currencySymbol: that.getCurrencySymbol(body.data.payments[i].currency),
              //username: body.data.payments[i].username,
              name: body.data.payments[i].name,
              dateTime: new Date(body.data.payments[i].dateTime),
            });
            //storeService.setpayments(that.payments);
          }
        } else if (body.error) {
          //console.log(body.error);
          //that.loginError = body.error.code;
          //console.log('error returned');
        } else {
          //console.log(body);
        }
        if(body.data.quantity == 0) {
          that.payments = null;
        }
      },
      function(err) {
        //console.log('error', err);
        //console.log(err._body);
        //var body = JSON.parse(err._body);
        ////console.log(body);
        //that.loginError = 'Error ' + err.status;
        ////console.log(this.userService);
        //that.passwordControl.

      },
      function() {
        //console.log('complete');
        //that.isGetting = false;
      }
    );
  }




  getAmountDonatedThisMonthToGroup() {
    var that = this;
    var params = { groupId: that.group.id, collateByMonth: true, currentMonth: false };
    if (that.group.type == 0) {
      // if charity, then only get this month's total
      // for fundraisers, get total of all time
      params.currentMonth = true;
    }
    //console.log('getAmountDonatedThisMonthToGroup');
    //console.log(params);
    this.paymentService.get( params ).subscribe(
      function(data) {
        //console.log(data);
        var body = JSON.parse(data._body);
        ////console.log(body);
        if (body.data && body.data.payments) {
          if(body.data.payments.length > 0) {
            var payments = body.data.payments[0];
            //console.log('group', that.group);
            //console.log('payments', payments, payments.totalAmount);
            that.group.totalPaymentThisMonth = payments.totalAmount;
            that.group.totalPaymentsThisMonth = payments.totalPayments;
            that.group.targetPercentage = (that.group.totalPaymentThisMonth / that.group.targetAmount) * 100;
            //console.log(that.group.totalPaymentThisMonth, that.group.targetPercentage);
          } else {
            that.group.totalPaymentThisMonth = 0;
            that.group.totalPaymentsThisMonth = 0;
            that.group.targetPercentage = 0;
          }
        }
      }, function(err) {
        //console.log(err);
      }, function() {

      });  //  service subscribe
  }


  getGroup() {
    var that = this;
    //console.log(that.group);
    this.groupService.getById(that.group.id).subscribe(
      function(data) {
        //console.log(data);
        var body = JSON.parse(data._body);
        //console.log(body);
        if (body.data && body.data.groups) {
          var group = body.data.groups[0];
          //console.log(group);
          that.group = group
          if (that.group.targetDate) {
            that.group.targetDate = new Date(that.group.targetDate);
          }
          that.gotGroupSoContinue();
        }
      }, function(err) {
        //console.log(err);
      }, function() {

      });  //  service subscribe 
  }



  getTotalUsersForGroup() {
    var that = this;
    var params2 = { groupId: that.group.id };
    that.group.totalUsers = 0;
    this.groupService.getUsersTotal(params2).subscribe(
      function(data) {
        //console.log(data);
        var body = JSON.parse(data._body);
        //console.log(body);
        if (body.data && body.data.users) {
          var users = body.data.users[0];
          //console.log(users);
          that.group.totalUsers = users.quantity;
        }
      }, function(err) {
        //console.log(err);
      }, function() {

      });  //  service subscribe 
  }

  getGroupUser() {
    var that = this;
    var params = { groupId: that.group.id, userId: that.user.id };
    this.groupService.getUser(params).subscribe(
      function(data) {
        //console.log(data);
        var body = JSON.parse(data._body);
        //console.log(body);
        if (body.data && body.data.users) {
          var user = body.data.users[0];
          //console.log(user);
          that.group.user = user;
          that.allowPushNotifications = user.allowPushNotifications;
        }
      }, function(err) {
        //console.log(err);
      }, function() {

      });  //  service subscribe 
  }



 displayChat() {
    //console.log('displayChat', this.group);
    var that = this;
    let query = { groupId: that.group.id, order: 'desc' , limit: 6};
    that.chatService.get(query).subscribe(
      function(data) {
        //console.log(data);
        var body = JSON.parse(data._body);
        if (body.data && body.data.posts) {
          //console.log(body.data.posts);
          //console.log(body.data.quantity);
          //var posts = body.data.posts;
          //console.log(posts);
          //console.log('qty', body.data.quantity);
          var posts = [];
          let len = body.data.quantity;
          that.postsHaveImages = false;
          for (let i = 0; i < len; i++) {
            posts.push({
              id: body.data.posts[i].id,
              message: body.data.posts[i].message,
              dateTime: new Date(body.data.posts[i].dateTime),
              url: body.data.posts[i].url,
              username: body.data.posts[i].username,
              imageUrl: body.data.posts[i].imageUrl,
              edit: false
            });
            if (body.data.posts[i].imageUrl != null) {
              that.postsHaveImages = true;
            }
          }

          that.posts = posts;
          posts = null;
          body = null;
          data = null;
          
        } else if (body.error) {
          //console.log(body.error);
          //that.loginError = body.error.code;
          //console.log('error returned');
        } else {
          //console.log(body);
        }
      },
      function(err) {
        console.log('error', err);
        //console.log(err._body);
      },
      function() {
        that.zone.run(() => {
          //console.log('complete');

        });
      }
    );
  }



  setIveJoinedThisGroup() {
    //console.log('setIveJoinedThisGroup', this.groupsJoined);
    for (var i = 0; i < this.groupsJoined.length; i++) {
      ////console.log(this.groupsJoined[i].groupId, this.group.id);
      //console.log(i, this.groupsJoined[i]);
      if (this.groupsJoined[i].id == this.group.id) {
        ////console.log('this is a fave', this.group.id)
        this.imInThisGroup = true;
        //console.log('setIveJoinedThisGroup imInThisGroup');
      }
    }
  }


  openRightMenu() {
    //this.menuController.open('rightGroup');
    var that = this;
    var buttons = [
        {
          text: 'Support',
          handler: () => {
            that.clickJoinGroup();
          }
        }, {
          text: 'Unsupport',
          handler: () => {
            that.clickLeaveGroup();
          }
        }, {
          text: 'Contact Details',
          handler: () => {
            that.openContactPage();
          }
        }, {
          text: 'Receive notifications',
          handler: () => {
            that.clickAllowNotifications();
          }
        }, {
          text: 'Block notifications',
          handler: () => {
            that.clickDenyNotifications();
          }
        }, {
          text: 'Claim this charity',
          handler: () => {
            that.clickClaim();
          }
        }, {
          text: 'Cancel',
          style: 'cancel',
          handler: () => {
          }
        }
      ];
   // if(!this.isIOS) {
      buttons.unshift({
          text: 'Share',
          handler: () => {
            that.clickShare();
          }
        });
  //  }
    let actionSheet = ActionSheet.create({
      title: 'Options',
      buttons: buttons
    });
    
    this.nav.present(actionSheet);
  }



clickClaim() {
   if (this.user && this.user.id) {

    if(this.imInThisGroup) {
      //console.log('clickclaim');
          var group = this.group;
          this.claimDisabled = true;
            var groupUser = {
              groupId: group.id,
              userId: this.user.id,
              //userEmail: this.user.email,
              action: 'claim'
            };
            var that = this;
            this.groupService.claimGroup(groupUser).subscribe(
              function(data) {
                //console.log('data', data);
                //console.log(data.status);
                //console.log(data._body);
                var body = JSON.parse(data._body);
                //console.log(body);
                //console.log(body.data);
                var status = body.status;
                if (status == 'success') {
                  let claimAlert = Alert.create({
                    title: 'Claim',
                    message: "Your claim has been sent, and we'll get in touch asap. Please ensure your email address in your profile is correct, so we can reply.",
                    buttons: [
                      {
                        text: 'OK',
                        handler: () => {
                        }
                      }]
                  });
                  that.nav.present(claimAlert);
                  //that.utilService.setStatusMessage(that.statusMessage, 'status', "Your claim has been sent, and we'll get in touch asap. Please ensure your email address in your profile is correct.");
                } else {
                  that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code);//  eg   //  eg ER_NO_SUCH_TABLE
                }
              },
              function(err) {
                console.log('error)', err);
              },
              function(complete) {
                //console.log('complete)', complete);
              }
            );

      } else {  // not in group
          let message = 'You need to be a supporter to claim this page. To support them, tap the heart button on the previous screen.'
          let supportAlert = Alert.create({
            title: 'Sorry',
            message: message,
            buttons: [
              {
                text: 'OK',
                handler: () => {
                }
              }]
          });
          this.nav.present(supportAlert);
      } //   end not in group

   } else {
      let message = 'You need to be registered to claim a charities page. <br>Do you want to register now - it only takes 30 seconds?'
      let gotoAccount = Alert.create({
        title: 'Sorry',
        message: message,
        buttons: [
          {
            text: 'No',
            handler: () => {
            }
          },
          {
            text: 'Yes',
            handler: () => {
              var that = this;
                let navTransition = gotoAccount.dismiss();
                navTransition.then(() => {
                //  setTimeout(function() {
                  that.nav.push(AccountPage, {
                    group: that.group
                  });
                //}, 500);
                });

            }
          }]
      });
      this.nav.present(gotoAccount);
   }
}

clickShare() {
    var that = this;
    //var url = 'http://www.wowgive.com';
    var url = 'http://www.wowgive.com/fundraising/' + slugify(that.group.name)  + '-' + that.group.id + '.html';

    function slugify(name) {
      name = name.replace(/[-\s|\.]+/g, '-');
      return name.trim();
  }
  //console.log(url);
    var img = this.group.mainImageUrl;
    //'http://wowgive.com/img/logowithslogan.png'
    /*
    var message = this.group.description;
    message = message.replace(/<p>/g,'');
    message = message.replace(/<\/p>/g,'\n');
    message = message.replace(/<h4>/g,'');
    message = message.replace(/<\/h4>/g,'\n');

    text & email ok on iphone

*/

  if (this.group.type == 1) {
    //  FUNDRAISER OWNER
    if(this.user && this.group.ownedByUserId == this.user.id) {
      var subject = this.group.name + ' - WowCode: ' + this.group.code;
      var message = "I'm raising money for " + this.group.parentName + " (one of my favourite charities), and I'd love your support for my fundraiser - " + this.group.name + ". Please follow me in the WowGive mobile app. \n\nI'll be posting updates and photos in WowGive about my fundraising progress, so I look forward to seeing you there!";
    } else {
      //  FUNDRAISER SUPPORTER
      var subject = this.group.name + ' - WowCode: ' + this.group.code;
      var message = "Please help me in supporting " + this.group.name + ". We're raising money for " + this.group.parentName + " (one of my favourite charities). Please follow me in the WowGive mobile app. \n\nI'll be posting updates and photos in WowGive about my fundraising progress, so I look forward to seeing you there!";
    }
  } else {
    // CHARITY
    if(this.user && this.group.ownedByUserId == this.user.id) {
      //  CHARITY ADMIN
      var subject = "Please support " + this.group.name + '!'; 
      var message = "I'd like to invite you to join us in supporting " + this.group.name + ". Please follow us in the WowGive mobile app. You'll be able to find out more about our work and keep track of our progress within the app. We look forward to seeing you there!";
    } else {
      //  CHARITY SUPPORTER
      var subject = "Please support " + this.group.name + '!'; 
      var message = "I'd like to invite you to join me in supporting " + this.group.name + ". Please follow us in the WowGive mobile app where you can find out more about the great work that " + this.group.name + " are doing. I look forward to seeing you there!";
    }
  }


/*
    var messagel = message.toLowerCase();
            if(messagel.indexOf('wowgive') == -1) {
              message += '\n\nPlease help support  via the WowGive app, available on the app store.';
            }
            if(messagel.indexOf('wowcode') == -1) {
              message += '\n\nEnter WowCode ' + this.group.code + ' to find us';
            }
*/

    //console.log(message);
    //console.log(subject);
    

    var to = null;
    var cc = null;
    var bcc = null;
    var files = [];
    var number = null;
    var isAndroid = this.isAndroid;

    var buttons = [
        {
          text: 'Send Email',
          handler: () => {
            message = 'Hi,\n\n' + message + '\n\n' + url; //   + '\n\n<img src="' + img + '" />';
            message += '\n\nThanks\n';
            if (this.user.name) message += '\n' + this.user.name;

            message = message.replace(/\n/g, '<br>');
            //console.log(message);

            files.push(img); 
            SocialSharing.shareViaEmail(message, subject, to, cc, bcc, files);
          }
        }, {
          text: 'Send Text',
          handler: () => {
            message = 'Hi,\n\n' + message + '\n\n' + url;
            message += '\n\nThanks\n';
            if (this.user.name) message += '\n' + this.user.name;
            //message += '\n' + this.group.name;

            SocialSharing.shareViaSMS(message, number);
          }
        }, {
          text: 'Share via Facebook',
          handler: () => {
            // add url to end of message and copypaste
            message += '\n' + url + '\n';
            Clipboard.copy(message);
            var prompt = "Long press in the 'Say something about this' box to paste in a message";
            that.shareViaFacebook(message, img, null, prompt );
            /*
            this seems to work, but leaves menu on screen
            SocialSharing.canShareVia('facebook',message, img, url, null,
              function(success) {
                //console.log('suc', success);
                that.shareViaFacebook(message, img, url, prompt );
                
              }, function(err) {
                console.log(err, 'err');
                // if no FB app, then share via web!
                that.shareFacebook(subject, message, img, url, prompt );
                //that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need the Facebook app on your phone to do this.');
              }
            );
            */
          }
        }, {
          text: 'Share via Instagram',
          handler: () => {

            Clipboard.copy(message);

            var alertPaste = Alert.create({
                  title: 'Tip',
                  message: "Long press in the 'Write a caption' box to paste in a message",
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => {
                        let navTransition = alertPaste.dismiss();
                        navTransition.then(() => {
                          SocialSharing.shareViaInstagram(message, img);
                        });
                        return false;
                      }
                    }]
                });// alert
                that.nav.present(alertPaste);

                /*
            SocialSharing.canShareVia('instagram',message, img, url, null,
              function(success) {
                console.log('suc', success);
                var alertPaste = Alert.create({
                  title: 'Tip',
                  message: "Press in the 'Write a caption' box to paste in a message",
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => {
                        let navTransition = alertPaste.dismiss();
                        navTransition.then(() => {
                          SocialSharing.shareViaInstagram(message, img);
                        });
                        return false;
                      }
                    }]
                });// alert
                that.nav.present(alertPaste);
              }, function(err) {
                console.log(err, 'err');
                that.zone.run(() => { 
                  that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need the Instagram app on your phone to do this.');
                });
              }
            );  //  socialCan
            */
            
          }
        }, {
          text: 'Share via Twitter',
          handler: () => {
            //message = "I'm raising money for " + this.group.name + " with @WowGive ";
            message = "Please follow " + this.group.name + " in @WowGive ";
            //message = "I'm raising money for " + this.group.name + " with @WowGive and I'd love your support. #WowCode " + this.group.code +'.';
            SocialSharing.shareViaTwitter(message, img, url);
            /*

  // canshare said no twitter app on iphone 6 even when installed
              SocialSharing.canShareVia('twitter',message, img, url, null,
                function(success) {
                  console.log('suc', success);
                  SocialSharing.shareViaTwitter(message, null, url);
                }, function(err) {
                  console.log(err, 'err');
                  that.zone.run(() => { 
                    that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need the Twitter app on your phone to do this.');
                  });
                }
              );
              */
          }
        }, {
          text: 'Share via WhatsApp',
          handler: () => {
            message = 'Hi,\n\n' + message;
            message += '\n\n' + url + '\n\n';
           // if (this.user.name) message += '\n' + this.user.name + '\n\n';

            Clipboard.copy(message);

            var alertPaste = Alert.create({
                  title: 'Tip',
                  message: "Long press in the 'Tap to add a caption' box to paste in a message",
                  buttons: [
                    {
                      text: 'Ok',
                      handler: () => {
                        let navTransition = alertPaste.dismiss();
                        navTransition.then(() => {
                          SocialSharing.shareViaWhatsApp(message, img, null);
                        });
                        return false;
                      }
                    }]
                });// alert
                that.nav.present(alertPaste);
              }

            //SocialSharing.shareViaWhatsApp(message, null, url);
            /*
            doing the canShare said that whatsapp wasn't on iphone 6??

             SocialSharing.canShareVia('whatsapp',message, null, url, null,
                function(success) {
                  console.log('suc', success);
                  SocialSharing.shareViaWhatsApp(message, null, url);
                }, function(err) {
                  console.log(err, 'err');
                  that.zone.run(() => { 
                    that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, you need WhatsApp  on your phone to do this.');
                  });
                }
              );
*/

          
        }, {
          text: "Let's see the options",
          handler: () => {
            that.socialShare(subject, message, url);
          }
        }, {
          text: 'Cancel',
          style: 'cancel',
          handler: () => {
          }
        }
      ];
 
    let actionSheet = ActionSheet.create({
      title: 'SHARE OPTIONS',
      subTitle: 'You will be able to type or paste a message before anything is posted',
      buttons: buttons
    });
    
    this.nav.present(actionSheet);
}


socialShare(subject, message, url) {
  // this is the complete list of currently supported params you can pass to the plugin (all optional)
var options = {
  message: message, // not supported on some apps (Facebook, Instagram)
  subject: subject,  // fi. for email
  files: ['', ''], // an array of filenames either locally or remotely
  url: url
  //chooserTitle: 'Pick an app' // Android only, you can override the default share sheet title
}

var onSuccess = function(result) {
  //console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
  //console.log("Shared to app: " + result.app); // On Android result.app is currently empty. On iOS it's empty when sharing is cancelled (result.completed=false)
}

var onError = function(msg) {
  console.log("Sharing failed with message: " + msg);
}
console.log(options);
  SocialSharing.share(options.message, options.subject, options.files, options.url);
  //SocialSharing.shareWithOptions(options.message, options.subject, options.files, options.url);

}
/*
shareViaFacebook(message, img, url, prompt ) {
  var that = this;
    if(that.isAndroid) {
      console.log('isAndroid');
      SocialSharing.shareViaFacebookWithPasteMessageHint(message, img, url, prompt );
    } else {
      var alertPaste = Alert.create({
        title: 'Tip',
        message: prompt,
        buttons: [
          {
            text: 'Ok',
            handler: () => {
              let navTransition = alertPaste.dismiss();
              navTransition.then(() => {
                SocialSharing.shareViaFacebook(message, img, url);
              });
              return false;
            } //  handler
          }]  //   buttons
      }); //   alert create
      that.nav.present(alertPaste);
    } //   if android
}
*/
shareViaFacebook(message, img, url, prompt ) {
  var that = this;
    
    var alertPaste = Alert.create({
      title: 'Tip',
      message: prompt,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            let navTransition = alertPaste.dismiss();
            navTransition.then(() => {
              if(that.isAndroid) {
                SocialSharing.shareViaFacebookWithPasteMessageHint(message, img, url, prompt );
              } else {
                SocialSharing.shareViaFacebook(message, img, url);
              }
            });
            return false;
          } //  handler
        }]  //   buttons
    }); //   alert create
    that.nav.present(alertPaste);
}

  shareFacebook(subject, message, img, url, prompt ) {
    // when posting via FB app doesn't work with other plugin
    // then post without the app via web

    var options = {
      method: "share",
      href: url,
      caption: subject,
      description: message,
      picture: img,
    };  

    var alertPaste = Alert.create({
      title: 'Tip',
      message: prompt,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            let navTransition = alertPaste.dismiss();
            navTransition.then(() => {
              facebookConnectPlugin.showDialog(options,
                function (response) {
                  //console.log('response', response)
                }, function (err) {
                  console.log('err', err)
                }
              );
            });
            return false;
          }
        }]
    });
    this.nav.present(alertPaste);


/*
    facebookConnectPlugin.showDialog(options,
      function (response) {
        console.log('response', response)
      }, function (err) {
        console.log('err', err)
      }
    );
    */


  }





  clickAgreeAddToFaves() {
    this.clickJoinGroup();
    this.clickCloseDonate();
  }



  clickOpenDonate() {
    /*
      if no user or no user id then msg login or create account
      if user but no payment method then msg add payment
      if user.then, then get user

    */
    //console.log(this.group);
    //console.log(this.user); // is promise
    var that = this;
    that.isSent = false;
    that.donation.buttonText = 'DONATE';
    that.donation.secondaryText = '';
    that.donation.valid = false;
    /*
    if(this.user && this.user.then) {
      //console.log('then');
      this.storeService.getUser().then((user) => {
        that.user = user;
        that.checkValidUser();
      });
    } else {
      that.checkValidUser();
    }
  }
  checkValidUser() {
    */


    var theNav = that.nav;
    //var that = this;
    //console.log(theNav);

    function gotoLogin() {
      //var that = this;

      setTimeout( function() {
        //console.log(theNav);
        theNav.push(AccountPage);
      }, 1000); // needs this otherwise crashes on IOS
    }

    //console.log('theuser is', that.user);
    if (that.user && that.user.cardLast4) {
      //console.log('got last4');
      if (!that.isSending) {

        if(this.isDashboard) {
          let getTheAppPrompt = Alert.create({
            title: 'Get the app',
            message: 'Donations can only be made with the mobile app. Search for WowGive on the app store.',
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                }
              }
              ]
          });
          this.nav.present(getTheAppPrompt);

        } else {
          //console.log('should be able to donate');
          that.donation.visible = true;
          that.donation.buttonClass = 'opacity-low';
          that.storeService.popups.push('donation');
        }
/*
              var params = {
      user: { id: that.user.id },
      payment: { amount: donation.amount, recurring: donation.recurring },
      group: { id: that.group.id, name: that.group.name }
      that.user.access_token ??
    }
*/
   
      }
    } else {
      //console.log('cant donate');
      let message = 'You need to choose a payment method before you can donate - do this now?'
      if (!this.user || !this.user.id) message = 'You need to login or create an account before you can donate - do this now?';
      let gotoAccount = Alert.create({
        title: 'No payment method',
        message: message,
        buttons: [
          {
            text: 'No',
            handler: () => {
            }
          },
          {
            text: 'Yes',
            handler: () => {
              //gotoLogin();
              // user has clicked the alert button
              // begin the alert's dimiss transition
              let navTransition = gotoAccount.dismiss();

              // start some async method
              //someAsyncOperation().then(() => {
              // once the async operation has completed
              // then run the next nav transition after the
              // first transition has finished animating out

              navTransition.then(() => {
                //this.nav.pop();
                theNav.push(AccountPage);
              });
              //});
              return false;
              
            }
          }]
      });
      this.nav.present(gotoAccount);
    }
  }



  clickCloseDonate() {
    //console.log(this.donation);
    //console.log('clickCloseDonate');
    this.donation.amountVisible = false;
    this.donation.amount = '';
    this.donation.recurring = false;
    this.donation.confirmationMessage = '';
    this.donation.confirmationVisible = false;
    this.donation.buttonClass = '';
    this.donation.visible = false;
    this.storeService.popups = [];
    //console.log(this.donation);
    this.closeKeyboard();
  }

  clickCancelDonation() {
    this.donation.confirmationVisible = false;
    this.closeKeyboard();
  }

  clickDontCloseDonate($event) {  // when click inner panel - don't wan to close it
    $event.stopPropagation();
  }

  clickOpenHelp() {
    this.donation.helpVisible = true;
  }
  clickCloseHelp() {
    this.donation.helpVisible = false;
  }
  /*
  clickOpenDonateAmount() {
    this.donation.amountVisible = true;
  }
  clickCloseDonateAmount() {
    this.donation.amountVisible = false;
  }
*/


  createDonationMessage(that) {
    let message = 'I wish to donate £' + that.donation.amount + ' now to ' + that.group.name + '';
      //console.log(that.recurring);
      if (that.donation.recurring) {
        let d = new Date();
        let month = that.utilService.getMonthName(d.getMonth() + 1);
       
        let y = d.getFullYear();
        message = 'I wish to donate £' + that.donation.amount + ' to ' + that.group.name + ' monthly starting on ' + month + ' 1, ' + y + '';
      }
      if (that.user.namePublic) {
        message = message + ' using the name ' + that.user.name + '!';
      } else {
        message = message + ' using the name ' + that.user.username + '!';
      }
      //console.log(message);
      return message;
  }

  setDonationRecurring(state) {
    
    this.donation.recurring = state;
    
    //console.log('setDonationRecurring', state);
    //var that = this;
    //that.donation.confirmationMessage = that.createDonationMessage(this);
    this.clickDonate(this.donation.amount);
  }

  clickDonate(amount) {
    //var that = this;

    if (this.isSending) {
      return;
    }
    
    if(amount) {
      this.donation.amount = amount;
    } 
    //console.log('am', this.donation.amount)
    //console.log(that.user.id, that.donation.amount, that.donation.recurring);

     if(this.donation.recurring == true) {
      this.donation.url = "#";
    } else {
       this.donation.url = DONATION_URL + '?id=' + this.user.id + '&gid=' + this.group.id + '&amt=' + this.donation.amount + '&recur=' + this.donation.recurring + '&token=' + this.user.access_token + '&gn=' + this.group.name + '#' + Math.random();
        //console.log(this.donation.url);
    }

    if(this.donation.amount && parseInt(this.donation.amount) >= 1) {
      //that.donation.confirmationMessage = this.createDonationMessage(that);
      //that.donation.confirmationVisible = true;
      this.donation.buttonText = 'GIVE ' + this.currencySymbol + parseFloat(this.donation.amount).toFixed(2)  + '';
      this.donation.secondaryText = 'Donation will be sent immediately';
      if(this.donation.recurring) {
        this.donation.buttonText = 'GIVE ' + this.currencySymbol + parseFloat(this.donation.amount).toFixed(2) + ' MONTHLY'
        let d = new Date();
        let month = this.utilService.getMonthName(d.getMonth() + 1);
       
        let y = d.getFullYear();
       
        this.donation.secondaryText = 'Donations will start on ' + month + ' 1, ' + y + ' and can be paused, cancelled or modified at any time from your regular donations page.';
      }
      this.donation.valid = true;
    } else {
      //console.log('invalid amount');
      this.donation.buttonText = 'DONATE';
      this.donation.secondaryText = '';
      this.donation.valid = false;
    }
  }




confirmDonationIOS() {
  //console.log('confirmDonationIOS');
  //console.log(this.donation.recurring);
  if (this.donation.recurring) {
    this.confirmDonation();
  } else {
    //console.log('open');
    this.clickCloseDonate();
    /*
    var ref = window.open(this.donation.url,'_system','location=yes,closebuttoncaption="back'); 
    
    setTimeout( function() {
      ref.close();
    },10000)
    */
  }
}

  // before donating - check pin
   confirmDonation() {
    //console.log('confirmDonation');
    if (this.isSending) {
      return;
    }
    this.donationSaved = this.donation;
    this.checkIfNeedToEnterPin(this.readyToProcess, function() {});
  }


  // pn has been entered successfully, or doesn't need to enter pin
  readyToProcess(that) {
    //console.log('readyToProcess');
    //that.showLoadingDefault();
    var donation = Object.assign({}, that.donationSaved);
    that.closeKeyboard();
    that.donation.buttonText = '';  //Sending...';
    that.isSending = true;
    //that.clickCloseDonate();
    var params = {
      user: { id: that.user.id },
      payment: { amount: donation.amount, recurring: donation.recurring },
      group: { id: that.group.id, name: that.group.name }
    }

    var service =  that.userService.makePayment(params);
    if (donation.recurring) {
      service =  that.userService.addRecurringPayment(params);
    }
       


    service.subscribe(
      function(data) {

        var body = JSON.parse(data._body);
        var status = body.status;
        if (status == 'success') {
          that.isSent = true;
          //that.donation.buttonText = 'DONE!';
          that.donation.confirmationMessage = "Your donation of " + that.currencySymbol + donation.amount + " has been sent to " + that.group.name + '.';
          that.donation.confirmationMessage2 = '';
          var message = '';
          if (donation.recurring) {
            that.donation.confirmationMessage = "A monthly donation of " + that.currencySymbol +  donation.amount + " has been set up to go to " + that.group.name + '.';
            that.donation.confirmationMessage2 = 'You can pause, cancel or change this at any time from the "My regular donations" page on the main menu.';
          }
           that.utilService.setStatusMessage(that.statusMessage, 'status', that.donation.confirmationMessage);

          //that.donation.confirmationMessage = that.donation.confirmationMessage + donation.amount + " has been sent to " + that.group.name + '<br><br>Thank you!';
          // check if in faves xx
          /*
          var buttons;
          if(that.imInThisGroup) {
            buttons = [{ text: 'close' }];
          } else {
            if (donation.recurring) {
              message = message + "<br><br>";
            }
            message = message + "Would you like to add this charity to your home screen?";
            buttons = [
              { text: 'No',
                handler: () => {
                  that.donation.buttonText = that.donateButtonText;
                }
              },
              { text: 'Yes',
                handler: () => {
                  that.donation.buttonText = that.donateButtonText;
                  that.clickJoinGroup();
                }
              }
            ];
          }
          
          let successAlert = Alert.create({
            title: "You're amazing!",
            cssClass: 'donated-alert-box',
            enableBackdropDismiss: true,
            subTitle: subTitle,
            message: message,
            buttons: buttons
          });

          that.nav.present(successAlert);
          */
          that.getAmountDonatedThisMonthToGroup();

        } else {
          that.donation.buttonText = that.donateButtonText;
          //console.log(body);
          var err = body.error.code;
          if(body.error.message) {
            err = body.error.message;
            // if use test card on prod, then get this
          }
          that.utilService.setStatusMessage(that.statusMessage, 'error', err);//  eg   //  eg ER_NO_SUCH_TABLE
        }
      },
      function(err) {
        that.donation.buttonText = that.donateButtonText;
        //console.log('error)', err);
      },
      function(complete) {
        that.clickCloseDonate();
        //console.log('complete donate');
        that.isSending = false;
        
        //that.hideLoadingDefault();
        that.donation.buttonText = 'DONATE';
      }
    );
                
   
  }

 showLoadingDefault(options) {
  let loading;
  loading = Loading.create({
    content: 'Please wait...',
    dismissOnPageChange: true,
    spinner: 'dots',
    duration: 100000
  });
  this.nav.present(loading);
  this.loading = loading;
}
hideLoadingDefault() {
  this.loading.dismiss();
}


  checkIfNeedToEnterPin(callbackSuccess, callbackFailure) {
    var that = this;
    var oldDate = new Date(that.user.pinLastEntered).valueOf();
    if (that.user.pinLastEntered) {
      let timeNow = new Date();
      var diff = timeNow.valueOf() - oldDate;
      var diffInHours = diff/1000/60/60;
      var diffInMinutes = diff/1000/60;
      //console.log('diff', diff, diffInHours, diffInMinutes);
      if(diffInHours < 1) {
        callbackSuccess(that);
        return;
      }
    }

    let enterPin = Alert.create({
      title: 'Authentication',
      message: 'Please enter the second and fifth digits of your password',
      cssClass: 'big-alert',
      inputs: [
        {
          name: 'pin',
          placeholder: '??',
          type: 'password'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
          }
        },
        {
          text: 'Submit',
          handler: data => {
            var params = { pin: data.pin, id: that.user.id };
            that.userService.checkPin(params).subscribe(
              function(data) {
                var body = JSON.parse(data._body);
                if(body.status == 'success') {
                  //console.log('succccc');
                  that.user.pinLastEntered = new Date;
                  that.localService.saveUserLocal(that.user);
                  callbackSuccess(that);
                  return;
                } else {
                   that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.message);
                   callbackFailure(that);
                }
              }
             )
          }
        }
      ]
    });  // let
    that.nav.present(enterPin);
  }







/*
      let confirmPrompt = Alert.create({
        title: 'Please confirm',
        message: message,
        buttons: [
          {
            text: 'No',
            handler: () => {
              //console.log('Disagree clicked');
            }
          },
          {
            text: 'Yes',
            handler: () => {
              //console.log('Agree clicked');
              that.donateButtonText = 'Sending...';
              that.isSending = true;
              that.clickCloseDonateAmount();
              that.clickCloseDonate();
              that.donateButtonClass = '';
              //var amountInPence = amount * 100;// turn pounds into pence
              var params = {
                user: { id: that.user.id },
                payment: { amount: amount, recurring: that.recurring },
                group: { id: that.group.id }
              }
            
              //console.log(params);

              if (that.recurring) {
                
                that.userService.addRecurringPayment(params).subscribe(
                  function(data) {
                    var body = JSON.parse(data._body);
                    var status = body.status;
                    if (status == 'success') {
                      let recurringSuccess = Alert.create({
                        title: "You're amazing!------",
                        message: "to " + that.group.name,
                        cssClass: 'donated-alert-box',
                        enableBackdropDismiss: true,
                        subTitle: "You've set up a monthly donation of £" + amount,
                        buttons: [{ text: 'OK' }]
                      });
                      //console.log('recurringSuccess');
                      nav.present(recurringSuccess);
                      //console.log(recurringSuccess);
                      //console.log(confirmPrompt);

                    } else {
                      that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code);//  eg   //  eg ER_NO_SUCH_TABLE
                    }
                  },
                  function(err) {
                    //console.log('error)', err);
                  },
                  function(complete) {
                    //console.log('complete donate');
                    that.donateButtonText = 'WOWGIVE';
                    that.isSending = false;
                  }
                );
                
              } else {
                that.userService.makePayment(params).subscribe(
                  function(data) {
                    var body = JSON.parse(data._body);
                    var status = body.status;
                    if (status == 'success') {
                      let donationSuccess = Alert.create({
                        title: "You're amazing!",
                        message: "is now winging its way to " + that.group.name,
                        cssClass: 'donated-alert-box',
                        enableBackdropDismiss: true,
                        subTitle: "Your donation of £" + amount,
                        buttons: [{ text: 'OK' }]
                      });
                      nav.present(donationSuccess);
                    } else {
                      if (body.error.type && body.error.type == 'StripeInvalidRequestError') {
                        that.utilService.setStatusMessage(that.statusMessage, 'error', 'Sorry, there was a problem processing your payment. Please check your card details are correct and try again.');
                      } else {
                        that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code);//  eg   //  eg ER_NO_SUCH_TABLE
                      }
                    }
                  },
                  function(err) {
                    //console.log('error)', err);
                  },
                  function(complete) {
                    //console.log('complete donate');
                    that.donateButtonText = 'WOWGIVE';
                    that.isSending = false;
                  }
                );
              }




            }
          }
        ]
      });
      that.nav.present(confirmPrompt);
      */
   // }
 // }









  clickJoinGroup() {
    if (this.user && this.user.id) {
      var group = this.group;
      var groupUser = {
        groupId: group.id,
        userId: this.user.id,
        avatar: this.user.avatar,
        username: this.user.username,
        permission: 1,
        action: 'join'
      };
      //console.log(group);
      //console.log(groupUser);
      var that = this;
      // var that = this;

      this.groupService.joinGroup(groupUser).subscribe(
        function(data) {
          //console.log('data', data);
          //console.log(data.status);
          //console.log(data._body);
          var body = JSON.parse(data._body);
          //console.log(body);
          //console.log(body.data);
          var status = body.status;
          if (status == 'success') {
            that.utilService.setStatusMessage(that.statusMessage, 'status', "You're a supporter!");
            that.getTotalUsersForGroup();
            that.imInThisGroup = true;
            //delete groupUser.action;
            //that.groupsJoined.push(groupUser);
            //that.storeService.setGroupsJoined(that.groupsJoined);
            that.userService.getGroupsJoined({ userId: that.user.id }).subscribe(
              function(data) {
                //console.log(data);
                var body = JSON.parse(data._body);
                that.storeService.setGroupsJoined(body.data.groups);
                that.storeService.getGroupsJoined();
              }, function(err) {
                //console.log(err);
              });
            that.promptShare();
          } else {
            that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code);//  eg   //  eg ER_NO_SUCH_TABLE
          }
        },
        function(err) {
          //console.log('error)', err);
        },
        function(complete) {

        }
      );
    } else {
      let message = 'You need to be registered to support them. <br>Do you want to register now - it only takes 30 seconds?'
      let gotoAccount = Alert.create({
        title: 'Sorry',
        message: message,
        buttons: [
          {
            text: 'No',
            handler: () => {
            }
          },
          {
            text: 'Yes',
            handler: () => {
              var that = this;
                let navTransition = gotoAccount.dismiss();
                navTransition.then(() => {
                //  setTimeout(function() {
                  that.nav.push(AccountPage, {
                    group: that.group
                  });
                //}, 500);
                });

            }
          }]
      });
      this.nav.present(gotoAccount);
    }
  }

  clickLeaveGroup() {
    if (this.user && this.user.id) {
      var group = this.group;
      var groupUser = {
        groupId: group.id,
        userId: this.user.id,
        permission: 1,
        action: 'leave'
      };
      var that = this;
      this.groupService.leaveGroup(groupUser).subscribe(
        function(data) {
          //console.log('data', data);
          //console.log(data.status);
          //console.log(data._body);
          var body = JSON.parse(data._body);
          //console.log(body);
          //console.log(body.data);
          var status = body.status;
          if (status == 'success') {
            that.utilService.setStatusMessage(that.statusMessage, 'status', 'Unsupported!');
            that.imInThisGroup = false;
            that.getTotalUsersForGroup();
            that.userService.getGroupsJoined({ userId: that.user.id }).subscribe(
              function(data) {
                //console.log(data);
                //console.log(data._body);
                var body = JSON.parse(data._body);
                //console.log(body.data);
                //if (body.quantity == 0) body.groupsJoined = null;
                that.storeService.setGroupsJoined(body.data.groupsJoined);
              },
              function(err) {
                //console.log(err);
              })
            /*
                      //console.log(that.groupsJoined);
                      //console.log(that.group);
                      for (var i = 0; i < that.groupsJoined.length; i++) {
                        //console.log(i, that.groupsJoined[i].groupId, that.group.id);
                        if (that.groupsJoined[i].groupId == that.group.id) {
                          
                          //console.log(that.groupsJoined[i]);
                          that.groupsJoined.splice(i, 1);
                          //console.log(that.groupsJoined);
                        }
                      }
                      that.storeService.setGroupsJoined(that.groupsJoined);
                      */
          } else {
            that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code);//  eg   //  eg ER_NO_SUCH_TABLE
          }
        },
        function(err) {
          //console.log('error)', err);
        },
        function(complete) {
          //console.log('complete)', complete);
        }
      );
    } else {
      let message = 'You need to be registered to support them. <br>Do you want to register now - it only takes 30 seconds?'
      let gotoAccount = Alert.create({
        title: 'Sorry',
        message: message,
        buttons: [
          {
            text: 'No',
            handler: () => {
            }
          },
          {
            text: 'Yes',
            handler: () => {
              // user has clicked the alert button
              // begin the alert's dimiss transition
              let navTransition = gotoAccount.dismiss();

              // start some async method
              //someAsyncOperation().then(() => {
              // once the async operation has completed
              // then run the next nav transition after the
              // first transition has finished animating out

              navTransition.then(() => {
                this.nav.push(AccountPage);
              });
              //});
              return false;
              
            }
          }]
      });
      this.nav.present(gotoAccount);
    }
  }



promptShare() {
  var that = this;
  var alertShare = Alert.create({
          title: 'Share with friends',
          message: "Tell your friends about " + that.group.name + "?",
          buttons: [
            {
              text: 'No',
              handler: () => {
                let navTransition = alertShare.dismiss();
                return false;
              }
            },
            {
              text: 'Yes',
              handler: () => {
                let navTransition = alertShare.dismiss();
                navTransition.then(() => {
                  that.clickShare();
                });
                return false;
              }
            }]
        });// alert
        that.nav.present(alertShare);
}




 clickAllowNotifications() {
    if (this.user && this.user.id) {
      var group = this.group;
      var groupUser = {
        groupId: group.id,
        userId: this.user.id,
        action: 'notifications',
        allowPushNotifications: true
      };
      var that = this;
      this.groupService.setNotifications(groupUser).subscribe(
        function(data) {
          var body = JSON.parse(data._body);
          var status = body.status;
          if (status == 'success') {
            that.utilService.setStatusMessage(that.statusMessage, 'status', 'Notifications set to allow');
          } else {
            that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code);
          }
        },
        function(err) {
          //console.log('error)', err);
        },
        function(complete) {

        }
      );
    } else {
      let message = 'You need to be registered to receive notifications. <br>Do you want to register now - it only takes 30 seconds?'
      let gotoAccount = Alert.create({
        title: 'Sorry',
        message: message,
        buttons: [
          {
            text: 'No',
            handler: () => {
            }
          },
          {
            text: 'Yes',
            handler: () => {
              this.nav.push(AccountPage);
            }
          }]
      });
      this.nav.present(gotoAccount);
    }
  }

clickDenyNotifications() {
    if (this.user && this.user.id) {

      if(this.imInThisGroup) {
      
            var group = this.group;
            var groupUser = {
              groupId: group.id,
              userId: this.user.id,
              action: 'notifications',
              allowPushNotifications: false
            };
            var that = this;
            this.groupService.setNotifications(groupUser).subscribe(
              function(data) {
                var body = JSON.parse(data._body);
                var status = body.status;
                if (status == 'success') {
                  that.utilService.setStatusMessage(that.statusMessage, 'status', 'Notifications blocked');
                } else {
                  that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code);
                }
              },
              function(err) {
                console.log('error)', err);
              },
              function(complete) {
              }
            );
      } else {  // not in group
          let message = 'You need to be a supporter to change notification settings. To support them, tap the heart button on the previous screen.'
          let supportAlert = Alert.create({
            title: 'Sorry',
            message: message,
            buttons: [
              {
                text: 'OK',
                handler: () => {
                }
              }]
          });
          this.nav.present(supportAlert);
      } //   end not in group

    } else {  //   if not user
      let message = 'You need to be registered to change notification settings. <br>Do you want to register now - it only takes 30 seconds?'
      let gotoAccount = Alert.create({
        title: 'Sorry',
        message: message,
        buttons: [
          {
            text: 'No',
            handler: () => {
            }
          },
          {
            text: 'Yes',
            handler: () => {
              this.nav.push(AccountPage);
            }
          }]
      });
      this.nav.present(gotoAccount);
    } //   if user
  }



formatDate(date) {
  /* if today then say today * time in 24hr
    else day Tue 12:17
    if older than week say short date

  */
//console.log(date);
  if(date) {
    let now = new Date();
    let now2 = now;
    let date2 = date;
    let today0 = now.getFullYear() + now.getMonth() + now.getDate();
    let date0 = date.getFullYear() + date.getMonth() + date.getDate();
    let today = date0 == today0;
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan'];
    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
    let daysAgo = Math.round((+now - +date) / (60 * 60 * 24 * 1000));
    //console.log(today, daysAgo, now - date, date.getHours(), date.getMinutes());
    let day = days[date.getDay()];
    let time = date.getHours() + ':' + date.getMinutes();
    let output = day + ' ' + time;
    if (today) {
      output = 'Today ' + time;
    } else if(daysAgo > 6) {
      //console.log('more than 6 ');
      //output = date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear() + ' ' + time;
      output = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ' ' + time;
    }
    return output;
  }
}

  monthName(dateTime) {
    return this.utilService.getMonthName(dateTime.getMonth());
  }


  openPage(menu, page) {
    // close the menu when clicking a link from the menu
    //menu.close();

    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    //let nav = this.app.getComponent('nav');
    this.nav.setRoot(page.component);
  }


toggleDescription() {
  this.descriptionShow = !this.descriptionShow;
  //console.log(this.descriptionShow);
}









gotFile(event) {
    var files = event.srcElement.files;
    //console.log(files);
    this.imageToSend = files[0];
    this.imageToUpload = this.imageToSend.name;
  }

deleteImageToSend() {
  this.imageToSend = null;
  this.imageToUpload = '';
}

uploadMainImage() {
  var that = this;
   if(!this.imageToSend) {
      return;
    }
      var params = {
       groupId: this.group.id,
       imageUrl: null,
       file: null
    };

    if(this.imageToSend) {
        params.imageUrl = this.imageToSend.name;
        params.file = this.imageToSend;
    }

    function onSuccessImage(data) {
      //console.log('success', data);

      that.isSending = false;

        //var body = JSON.parse(data.response);
        var status = data.status;
        var body = data.data;
        if (status == 'success' && body) {
          //console.log(body);
      that.imageToSend = null;
      //console.log('url',body.imageUrl);
          that.zone.run(() => {
            that.group.mainImageUrl = that.group.mainImageUrl + '?_ts=' + new Date().getTime();
            that.deleteImageToSend();
            /*
               that.http.get(that.group.mainImageUrl, {cache: false})
               //.map(res => res.text())
                .subscribe(
                  data => that.group.mainImageUrl = that.group.mainImageUrl + '?_ts=' + new Date().getTime(),
                  err => this.logError(err),
                  () => console.log('Random Quote Complete')
                );
            */

          });

        } else {
          
          //console.log(body);
          that.zone.run(() => {
            var err = body.error.err;
            if(body.error.message) {
              err = body.error.message;
            }
            that.utilService.setStatusMessage(that.statusMessage, 'error', err);
        });
        }
    }
    function onFailureImage(err) {
      //var that = that.storeService.getScope();
      console.log('failure', err);
      that.isSending = false;
      that.utilService.setStatusMessage(that.statusMessage, 'error', err);
    }



   that.isSending = true;
    //console.log(that.imageToSend, that.isDashboard);

        that.groupService.uploadMainImage(params, onSuccessImage, onFailureImage, that.onProgress, that).then((result) => {
          //console.log(result);
          that.isSending = false;
          result._body = JSON.stringify({ data: { post: result.data.post }, status: 'success'});
          onSuccessImage(result);
        })
       
}

    onProgress (progressEvent: ProgressEvent, that: any) {
      //console.log('onprog');
      //console.log(that.progress);
        if (progressEvent.lengthComputable) {
          

            that.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            //this.setProgress(this.progress); 
            that.zone.run(() => {
              that.progress = that.progress + 1;
              //console.log('progress is', that.progress, that);
            });
            //console.log(this.progress); 
            if(that.progress < 0 ){
              that.progress = 0;
            }
            if(that.progress > 100) {
                that.progress = 100;
            }         
        }
        
    }
















gotLogo(event) {
    var files = event.srcElement.files;
    //console.log(files);
    this.logoToSend = files[0];
    this.logoToUpload = this.logoToSend.name;
  }

deleteLogoToSend() {
  this.logoToSend = null;
  this.logoToUpload = '';
}


uploadLogo() {
  var that = this;
   if(!this.logoToSend) {
      return;
    }
      var params = {
       groupId: this.group.id,
       imageUrl: null,
       file: null
    };

    if(this.logoToSend) {
        params.imageUrl = this.logoToSend.name;
        params.file = this.logoToSend;
    }

    function onSuccessImage(data) {
      //console.log('success', data);

      that.isSending = false;

        var body = JSON.parse(data._body);
        var status = data.status;
        //var body = data.data;
        if (status == 'success' && body) {
          //console.log(body);
      that.logoToSend = null;
      //console.log('url',body.imageUrl);
          that.zone.run(() => {
            that.group.logoUrl = that.group.logoUrl + '?_ts=' + new Date().getTime();
            that.deleteLogoToSend();

          });

        } else {
          
          //console.log(body);
          that.zone.run(() => {
            var err = body.error.err;
            if(body.error.message) {
              err = body.error.message;
            }
            that.utilService.setStatusMessage(that.statusMessage, 'error', err);
        });
        }
    }
    function onFailureImage(err) {
      //var that = that.storeService.getScope();
      console.log('failure', err);
      that.isSending = false;
      that.utilService.setStatusMessage(that.statusMessage, 'error', err);
    }



   that.isSending = true;
    //console.log(that.logoToSend, that.isDashboard);

        that.groupService.uploadLogo(params, onSuccessImage, onFailureImage, that.onProgress, that).then((result) => {
          //console.log(result);
          if(result.status == 'success') {
            result._body = JSON.stringify({ data: { post: result.data.post }, status: 'success'});
          } else {
            result._body = JSON.stringify( result );
          }
          that.isSending = false;
          
          // if err---  errno.err
          // status: 'failure'
          onSuccessImage(result);
        })
       
}


toggleFeatured() {
            var that = this;
            this.groupService.toggleFeatured({ id: this.group.id, featured: this.group.featured }).subscribe(
              function(data) {
                //console.log('data', data);
                var body = JSON.parse(data._body);
                console.log(body);
                //console.log(body.data);
                var status = body.status;

                if (status == 'success') {
                  that.group.featured = body.data.group.featured;
                  
                  that.utilService.setStatusMessage(that.statusMessage, 'status', "Featured set to " + that.group.featured);
                } else {
                  that.utilService.setStatusMessage(that.statusMessage, 'error', body.error.code);//  eg   //  eg ER_NO_SUCH_TABLE
                }
              },
              function(err) {
                console.log('error)', err);
              },
              function(complete) {
                //console.log('complete)', complete);
              }
            );
}








}













 