import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {SERVER_URL} from '../config';
import {Http, Headers, RequestOptions} from '@angular/http';
import {LocalService} from './local-service';
import {StoreService} from './store-service';



class User {
  id: number;
  username: string;
  name: string;
  password: string;
  namePublic: boolean;
  email: string;

    constructor(username: string, name: string, email: string, password: string, namePublic: boolean) {
        this.id = null;
        this.username = username;
        this.name = name;
        this.password = password;
        this.namePublic = namePublic;
        this.email = email;
    }

    sayHello() {
        //console.log('Hello, I am', this.name);
    }
}

 



@Injectable()

export class UserService {
  user: any;
  localService: any;
  http: any;
  storeService: any;


  constructor( localService:LocalService, http:Http, storeService: StoreService ) {
    this.localService = localService;
    this.storeService = storeService;
    this.http = http;
    //this.user = {};

    //this.user = this.get();
    ////console.log(this.user);
    //new User('Ashteya');
    //user.sayHello(); 

    //this.newUser = { id: '', username: '', password: '', passwordAgain: '', passwordOld: '', namePublic: false };
    //this.user = { id: '', username: '', password: '', passwordAgain: '', passwordOld: '', namePublic: false };
  }

  //    localStorage may get erased in IOS, so sqlStorage is preferred


  new() {
    return new User('', '', '', '', false);
  }

  //get() {
    //this.storeService.getUser();
/*
     .then((user) => {
      //console.log('get');
      if (!user) {
        //console.log('no user');
        user = new User('', '', '', false);
      }
      return user;
    });
    */
  //}
  getLocal() {
    //console.log('getlocal');
    //return this.localService.getUser();
  }

    /*
    this.localService.getUser().then((user) => {
        this.user = JSON.parse(user) || { name: 'who knows' };
    });
    */
  /*
    //let storage = new Storage(SqlStorage, options);
    storage.set('name', 'Max');
    storage.get('name').then((name) => {
      //console.log(name);
      return Observable.create(observer => {
          observer.next(url);
          observer.complete();
      });
      });
      */

  requestPasswordReset(email) {
    //console.log(SERVER_URL, email);
    let url = SERVER_URL + 'users';
    //if(user.id) {
      //url = url + '/' + user.id;
    //}
    url = url + '?action=requestpwreset&email=' + email;
    //console.log(url);
    let body = JSON.stringify({ email: email });
    let options = new RequestOptions({ headers: this.storeService.getHeaders() });  // haven't got access token yet!
    return this.http.post(url, body, options);
  }

  checkPin(user) {
    //console.log(SERVER_URL, user);
    let url = SERVER_URL + 'users/' + user.id;
    //if(user.id) {
      //url = url + '/' + user.id;
    //}
    url = url + '?action=checkpin';
    //console.log(url);
    let body = JSON.stringify({ pin: user.pin });
    let options = new RequestOptions({ headers: this.storeService.getHeaders() });  // haven't got access token yet!
    return this.http.post(url, body, options);
  }

  save(user) {
    //console.log(SERVER_URL, user);
    let url = SERVER_URL + 'users';
    if(user.id) {
      url = url + '/' + user.id;
    }
    //console.log(url);
    //let body = JSON.stringify({ stripeToken: "tok_17jCIlFt7eFMAPq1cssNa95v"});
    let body = JSON.stringify({ user: user });
    //let headers = new Headers({ 'Content-Type': 'application/json' });
    console.log(body);
    ////console.log(this.storeService.getHeaders());
    let options = new RequestOptions({ headers: this.storeService.getHeaders() });  // haven't got access token yet!
    //let options = new RequestOptions({ headers: headers });
    ////console.log(this.http);
    return this.http.post(url, body, options);
    //return this.http.get(url, { headers: this.storeService.getHeaders() });
    //return this.localService.saveUserLocalCustomerId(stripeCustomerId);
  }

  saveUserLocalCustomerId(stripeCustomerId) {
    return this.localService.saveUserLocalCustomerId(stripeCustomerId);
  }

  authenticate(form) {
    //console.log(SERVER_URL, form);
    let url = SERVER_URL + 'authenticate';
    //console.log(url);
    //let body = JSON.stringify({ stripeToken: "tok_17jCIlFt7eFMAPq1cssNa95v"});
    let body = JSON.stringify({ email: form.email, password: form.password });
    //let headers = new Headers({ 'Content-Type': 'application/json'});
    let options = new RequestOptions({ headers: this.storeService.getHeaders() });
    //console.log(this.http);
    return this.http.post(url, body, options);
    //return this.localService.saveUserLocalCustomerId(stripeCustomerId);
  }

  logout() {
    this.storeService.setUser(null);
    this.storeService.setGroupsJoined(null);
  }

  authenticateViaFacebook(form) {
    //console.log(SERVER_URL, form);
    let url = SERVER_URL + 'authenticate';
    console.log(url, 'authenticateViaFacebook');
    //let body = JSON.stringify({ stripeToken: "tok_17jCIlFt7eFMAPq1cssNa95v"});
    let body = JSON.stringify({ facebookUserId: form.facebookUserId });
    //let headers = new Headers({ 'Content-Type': 'application/json'});
    let options = new RequestOptions({ headers: this.storeService.getHeaders() });
    //console.log(this.http);
    return this.http.post(url, body, options);
    //return this.localService.saveUserLocalCustomerId(stripeCustomerId);
  }

  makePayment(params) {
    //console.log(SERVER_URL, params.user.id);
    let url = SERVER_URL + 'users/' + params.user.id + '/payments'; //change to users/:id/payments
    //console.log(url);
    let body = JSON.stringify(params);
    //let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: this.storeService.getHeaders() });
    return this.http.post(url, body, options);
  }

  addRecurringPayment(params) {
    //console.log(SERVER_URL, params.user.id);
    let url = SERVER_URL + 'users/' + params.user.id + '/recurringpayments';
    //console.log(url);
    let body = JSON.stringify(params);
    //let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: this.storeService.getHeaders() });
    return this.http.post(url, body, options);
  }

/*
  setRecurringPayment(params) {
    //console.log(SERVER_URL, params.user.id);
    let url = SERVER_URL + 'users' + '/' + params.user.id + '/recurringpayments';
    //console.log(url);
    let body = JSON.stringify(params);
    //let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: this.storeService.getHeaders() });
    return this.http.post(url, body, options);
  }
*/
  setRecurringPayment(params) {
    //console.log(SERVER_URL);
    //let url = SERVER_URL + 'recurringpayments';
    if (params.user && params.user.id) {
      var url = SERVER_URL + 'users/' + params.user.id + '/recurringpayments/' + params.recurringPayment.id + '?action=pause';
    }

    //console.log(url);
    let body = JSON.stringify(params);
    //let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: this.storeService.getHeaders() });
    return this.http.post(url, body, options);
  }


  getGroupsJoined(params) {
    let url = SERVER_URL + 'users/' + params.userId + '/groups';
    //this.access_token = this.storeService.getUser().access_token;
    ////console.log(this.access_token);
    //console.log(url);
    let body = JSON.stringify({});
    //let headers = new Headers({ 'Content-Type': 'application/json', 'x-access-token': this.access_token });
    //let headers = new Headers();
    //headers.append('Content-Type', 'application/json');
    //headers.append('x-access-token': this.access_token);
    //let options = new RequestOptions({ headers: headers });
    return this.http.get(url, { headers: this.storeService.getHeaders() });
  }

/*
    findAll() {
        //console.log(SERVER_URL);
        let url = SERVER_URL + 'services/mock-users.json';
        //console.log(url);
        return Observable.create(observer => {
            observer.next(url);
            observer.complete();
        });
    }

    handleError(error) {
        console.error(error);
        return Observable.throw(error.json().error || 'Server error');
    }
*/

}// export
/*
this.storage.query("SELECT * FROM people").then((data) => {
                this.people = [];
                if(data.res.rows.length > 0) {
                    for(var i = 0; i < data.res.rows.length; i++) {
                        this.people.push({firstname: data.res.rows.item(i).firstname, lastname: data.res.rows.item(i).lastname});
                    }
                }
            }, (error) => {
                //console.log("ERROR -> " + JSON.stringify(error.err));
            });
            */